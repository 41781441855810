import ReconnectingWebSocket from "reconnecting-websocket";
import store from "../store";
import SnackbarUtils from "../modules/notifications";
import { add as addTriggers, remove, update } from "../modules/triggers";
import {
	add as addRule,
	remove as removeRule,
	update as updateRule,
	activate,
	deactivate,
	ready,
} from "../modules/rules";
import { updateList, updateOrder } from "../modules/sentOrders";
import { updateLevels } from "../modules/levels";
import { updateLevelDetails } from "../modules/levelDetails";
import books from "../data";
import { update as updateRahsans } from "../modules/rahsans";
import {
	activeProfileId,
	hasAccessToRule,
	allowedAccounts,
	hasAccessToSentOrder,
} from "../modules/profiles";
import { updateLevelsWithTriggers } from "../modules/levelsWithTriggers";
import { updateMorningOrders } from "../modules/morningOrders";
import { updateFixOrders } from "../modules/fixOrders";

var bignumJSON = require("json-bignum");

export let updatedHere = [];

class Api {
	constructor() {
		this.ws = this.setupWebsocket();

		this.disconnectedBefore = false;
	}

	setupWebsocket() {
		const urlHost = new URLSearchParams(window.location.search).get("host");
		const url =
			!urlHost && activeProfileId === "ahlatci"
				? "212.156.243.114:20011"
				: !urlHost && activeProfileId === "yfas"
					? "185.57.245.43:20011"
					: (urlHost || process.env["REACT_APP_HOST"]) + ":20011";
		const ws = new ReconnectingWebSocket("ws://" + url);

		// Handlers
		ws.onopen = () => {
			if (this.disconnectedBefore) {
				if (new Date().getHours() >= 10) {
					SnackbarUtils.warning(`Yeniden başlatılıyor...`);
					setTimeout(() => window.location.reload(), 1000);
				} else {
					SnackbarUtils.warning(`Yeniden bağlanılıyor...`);
					setTimeout(() => {
						store.dispatch({ type: "RECONNECT" });
						setTimeout(() => {
							this.send("Status", allowedAccounts);
						}, 500);
					}, 1000);
				}
			} else {
				this.send("Status", allowedAccounts);
			}
		};

		ws.onclose = () => {
			SnackbarUtils.error("Bağlantı koptu");
			this.disconnectedBefore = true;
		};

		ws.onmessage = this.handleMessage;

		window.api = ws;

		return ws;
	}

	send(type, payload) {
		this.ws.send(bignumJSON.stringify({ type, payload }));
	}

	handleMessage = (event) => {
		const data = bignumJSON.parse(event.data);

		switch (data.type) {
			case "Status":
				store.dispatch(addTriggers(data.payload.active_close_triggers));
				store.dispatch(updateList(data.payload.sent_orders));
				store.dispatch(addRule(data.payload.rules));
				store.dispatch(activate(data.payload.active_rule_ids));
				store.dispatch(
					updateMorningOrders(data.payload.morning_orders)
				);
				store.dispatch(
					updateFixOrders(data.payload.fix_orders)
				);
				store.dispatch(ready());
				break;
			case "MorningOrdersUpdated":
				store.dispatch(updateMorningOrders(data.payload));
				break;
			case "FixOrdersUpdated":
				store.dispatch(updateFixOrders(data.payload));
				break;
			case "TriggerRemoved":
				store.dispatch(remove(data.payload));
				break;
			case "Triggered":
				const [trigger_id, trigger_type, rule_id, quantity] =
					data.payload;

				console.log("Triggered", trigger_type);

				const rule = store.getState().rules.byId[rule_id];

				if (rule && hasAccessToRule(rule)) {
					const formattedMsg = `Koşul tetiklendi: ${books[rule.condition.order_book[0]].label
						} [${quantity} lot kala] -> ${rule.action.order_book[1]} ${books[rule.action.order_book[0]].label
						} @${rule.action.price}`;
					SnackbarUtils.info(formattedMsg);

					store.dispatch(remove(trigger_id));
					store.dispatch(deactivate([rule.id]));
				}
				break;
			case "TriggerCreated":
				store.dispatch(addTriggers(data.payload));
				break;
			case "TriggerUpdated":
				store.dispatch(update(...data.payload));
				break;
			case "SentOrderUpdated":
				if (hasAccessToSentOrder(data.payload)) {
					store.dispatch(updateOrder(data.payload));
				}
				break;
			case "RuleRemoved":
				store.dispatch(removeRule(data.payload));
				break;
			case "RuleUpdated":
				const affected = data.payload.filter(hasAccessToRule);

				if (affected.length > 0) {
					store.dispatch(updateRule(data.payload));

					if (
						data.payload.length === 1 &&
						updatedHere.indexOf(data.payload[0].id) > -1 &&
						data.payload[0].action.price > 0
					) {
						this.send(
							"RuleActivate",
							data.payload.map((rule) => rule.id)
						);
					}
				}

				break;
			case "RuleActivated":
				const byId = store.getState().rules.byId;
				const related = data.payload.filter(
					(id) => byId[id] && hasAccessToRule(byId[id])
				);

				if (related.length > 0) {
					store.dispatch(activate(related));
				}
				break;
			case "RuleDeactivated":
				const byIdForDeactivate = store.getState().rules.byId;
				const relatedForDeactivate = data.payload.filter(
					(id) =>
						byIdForDeactivate[id] &&
						hasAccessToRule(byIdForDeactivate[id])
				);

				if (relatedForDeactivate.length > 0) {
					store.dispatch(deactivate(relatedForDeactivate));
				}
				break;
			case "OrderBookLevelsWithTriggers":
				store.dispatch(updateLevelsWithTriggers(...data.payload));
				break;
			case "OrderBookLevels":
				store.dispatch(updateLevels(...data.payload));
				break;
			case "OrderBookLevelDetails":
				store.dispatch(updateLevelDetails(...data.payload));
				break;
			case "RahsanList":
				store.dispatch(
					updateRahsans(
						data.payload.map((row) => ({
							ruchan_book_id: row[0],
							hisse_book_id: row[1],
							oran: parseFloat(row[2]),
							bedel: parseFloat(row[3]),
							spread: parseInt(row[4]),
						}))
					)
				);
				break;
			case "Alert":
				const [type, message] = data.payload;
				SnackbarUtils[type](message);
				break;
			default:
				console.log("Unhandled API message:", data);
		}
	};
}

export default new Api();