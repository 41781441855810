export default [
	{ label: "BANVT.E", value: 71976, partition: 0 },
	{ label: "BMSCS.V", value: 924161, partition: 0 },
	{ label: "ABSGG.V", value: 3282064, partition: 0 },
	{ label: "ASBDD.V", value: 4462455, partition: 0 },
	{ label: "BABQK.V", value: 3676488, partition: 0 },
	{ label: "AS1PP.V", value: 4462456, partition: 0 },
	{ label: "AGBSA.V", value: 5772407, partition: 0 },
	{ label: "BASCM.E", value: 71996, partition: 0 },
	{ label: "AZBQT.V", value: 4986449, partition: 0 },
	{ label: "AGBSV.V", value: 17962103, partition: 0 },
	{ label: "BASGZ.E", value: 72001, partition: 0 },
	{ label: "ABBDL.V", value: 5772423, partition: 0 },
	{ label: "ATIBL.V", value: 4462480, partition: 0 },
	{ label: "AT1PP.V", value: 4462482, partition: 0 },
	{ label: "BR1AD.V", value: 662202, partition: 0 },
	{ label: "ATAAR.V", value: 4462483, partition: 0 },
	{ label: "AB1PU.V", value: 5772433, partition: 0 },
	{ label: "BERA.E", value: 72016, partition: 0 },
	{ label: "BIIQF.V", value: 1186191, partition: 0 },
	{ label: "AGSQX.V", value: 8262774, partition: 0 },
	{ label: "AGBSH.V", value: 8262775, partition: 0 },
	{ label: "BISFC.V", value: 1186193, partition: 0 },
	{ label: "MDSAW.V", value: 1053767, partition: 0 },
	{ label: "BEGYO.E", value: 72031, partition: 0 },
	{ label: "BEYAZ.E", value: 72036, partition: 0 },
	{ label: "BR1AN.V", value: 3152570, partition: 0 },
	{ label: "LRIAU.V", value: 267820, partition: 0 },
	{ label: "BIATX.V", value: 3676560, partition: 0 },
	{ label: "BISRG.V", value: 3676561, partition: 0 },
	{ label: "BIBDC.V", value: 3676562, partition: 0 },
	{ label: "DAAUW.V", value: 2366618, partition: 0 },
	{ label: "BFREN.E", value: 72056, partition: 0 },
	{ label: "DASFE.V", value: 2366619, partition: 0 },
	{ label: "BI1PM.V", value: 3676565, partition: 0 },
	{ label: "BIENY.E", value: 72061, partition: 0 },
	{ label: "DBBBR.V", value: 4594971, partition: 0 },
	{ label: "BOBQK.V", value: 3676572, partition: 0 },
	{ label: "BIGCH.E", value: 72066, partition: 0 },
	{ label: "AOIQQ.V", value: 1053812, partition: 0 },
	{ label: "AGSCU.V", value: 1053814, partition: 0 },
	{ label: "BIMAS.E", value: 72076, partition: 0 },
	{ label: "LRAQD.V", value: 2758187, partition: 0 },
	{ label: "LRIQD.V", value: 2758188, partition: 0 },
	{ label: "LR1AL.V", value: 2758189, partition: 0 },
	{ label: "AZBBR.V", value: 267857, partition: 0 },
	{ label: "BIOEN.E", value: 72086, partition: 0 },
	{ label: "DASRO.V", value: 4856987, partition: 0 },
	{ label: "ABBDD.V", value: 1053831, partition: 0 },
	{ label: "AEIRI.V", value: 2234228, partition: 0 },
	{ label: "ANIRF.V", value: 1053839, partition: 0 },
	{ label: "ASAVT.V", value: 2234229, partition: 0 },
	{ label: "ABSFV.V", value: 1053840, partition: 0 },
	{ label: "AB1PW.V", value: 1053841, partition: 0 },
	{ label: "AGAUO.V", value: 3544181, partition: 0 },
	{ label: "AGSDB.V", value: 3544182, partition: 0 },
	{ label: "AGBCV.V", value: 3544183, partition: 0 },
	{ label: "APBQT.V", value: 4986568, partition: 0 },
	{ label: "LR1AV.V", value: 5248557, partition: 0 },
	{ label: "BIZIM.E", value: 72116, partition: 0 },
	{ label: "DASRM.V", value: 7347355, partition: 0 },
	{ label: "DABDJ.V", value: 7347358, partition: 0 },
	{ label: "ABARD.V", value: 3544200, partition: 0 },
	{ label: "ATSBN.V", value: 2234257, partition: 0 },
	{ label: "BMSCU.V", value: 1186305, partition: 0 },
	{ label: "ABSGI.V", value: 3544208, partition: 0 },
	{ label: "BABQM.V", value: 3938632, partition: 0 },
	{ label: "ASBDF.V", value: 4724599, partition: 0 },
	{ label: "AB1AN.V", value: 3544209, partition: 0 },
	{ label: "BJKAS.E", value: 72136, partition: 0 },
	{ label: "AS1AR.V", value: 4724600, partition: 0 },
	{ label: "BJKAS.R", value: 72137, partition: 0 },
	{ label: "AGBSC.V", value: 6034551, partition: 0 },
	{ label: "AZBQV.V", value: 5248593, partition: 0 },
	{ label: "AGBSX.V", value: 18224247, partition: 0 },
	{ label: "BLCYT.E", value: 72156, partition: 0 },
	{ label: "ABBDN.V", value: 6034567, partition: 0 },
	{ label: "ATIBN.V", value: 4724624, partition: 0 },
	{ label: "AT1AR.V", value: 4724626, partition: 0 },
	{ label: "BR1PA.V", value: 924346, partition: 0 },
	{ label: "ATAAT.V", value: 4724627, partition: 0 },
	{ label: "BMSCW.V", value: 3676673, partition: 0 },
	{ label: "BIIQH.V", value: 1448335, partition: 0 },
	{ label: "BIAES.V", value: 1448336, partition: 0 },
	{ label: "AGBSJ.V", value: 8524919, partition: 0 },
	{ label: "BI1AF.V", value: 1448341, partition: 0 },
	{ label: "MDSAY.V", value: 1315911, partition: 0 },
	{ label: "BR1PJ.V", value: 3414714, partition: 0 },
	{ label: "ALIAU.V", value: 267970, partition: 0 },
	{ label: "LRIAW.V", value: 529964, partition: 0 },
	{ label: "APBBR.V", value: 267976, partition: 0 },
	{ label: "BNTAS.E", value: 72206, partition: 0 },
	{ label: "BIATZ.V", value: 3938704, partition: 0 },
	{ label: "BISRI.V", value: 3938705, partition: 0 },
	{ label: "BIBDE.V", value: 3938706, partition: 0 },
	{ label: "DAAUY.V", value: 2628762, partition: 0 },
	{ label: "DASFG.V", value: 2628763, partition: 0 },
	{ label: "BI1AP.V", value: 3938709, partition: 0 },
	{ label: "DBBQO.V", value: 4857115, partition: 0 },
	{ label: "BBSBP.V", value: 1577934, partition: 0 },
	{ label: "BOBQM.V", value: 3938716, partition: 0 },
	{ label: "ADGYO.E", value: 201778, partition: 0 },
	{ label: "BOBET.E", value: 72226, partition: 0 },
	{ label: "AOIBS.V", value: 1315956, partition: 0 },
	{ label: "AGAFJ.V", value: 1315957, partition: 0 },
	{ label: "AGSCW.V", value: 1315958, partition: 0 },
	{ label: "BORLS.E", value: 72231, partition: 0 },
	{ label: "BORSK.E", value: 72233, partition: 0 },
	{ label: "LRABF.V", value: 3020331, partition: 0 },
	{ label: "BOSSA.E", value: 72236, partition: 0 },
	{ label: "LR1PI.V", value: 3020333, partition: 0 },
	{ label: "AZBBT.V", value: 530001, partition: 0 },
	{ label: "SCSAS.V", value: 268015, partition: 0 },
	{ label: "DASRQ.V", value: 5119131, partition: 0 },
	{ label: "ABBDF.V", value: 1315975, partition: 0 },
	{ label: "BBSPS.V", value: 4068302, partition: 0 },
	{ label: "RMSAR.V", value: 268025, partition: 0 },
	{ label: "AEIRK.V", value: 2496372, partition: 0 },
	{ label: "ANIRH.V", value: 1315983, partition: 0 },
	{ label: "ASAVV.V", value: 2496373, partition: 0 },
	{ label: "CUIAN.V", value: 400462, partition: 0 },
	{ label: "AGAUQ.V", value: 3806325, partition: 0 },
	{ label: "AGSQL.V", value: 3806326, partition: 0 },
	{ label: "AGBCX.V", value: 3806327, partition: 0 },
	{ label: "APBQV.V", value: 5248712, partition: 0 },
	{ label: "LR1PR.V", value: 5510701, partition: 0 },
	{ label: "AZSQK.V", value: 3020368, partition: 0 },
	{ label: "AGBDS.V", value: 15996023, partition: 0 },
	{ label: "DABDL.V", value: 7609502, partition: 0 },
	{ label: "ABARF.V", value: 3806344, partition: 0 },
	{ label: "ATSPO.V", value: 2496401, partition: 0 },
	{ label: "BMSCX.V", value: 1448449, partition: 0 },
	{ label: "ABSSE.V", value: 3806352, partition: 0 },
	{ label: "AB1AP.V", value: 3806353, partition: 0 },
	{ label: "BABBO.V", value: 4200776, partition: 0 },
	{ label: "ASBDH.V", value: 4986743, partition: 0 },
	{ label: "AS1AT.V", value: 4986744, partition: 0 },
	{ label: "BRISA.E", value: 72296, partition: 0 },
	{ label: "AGBSE.V", value: 6296695, partition: 0 },
	{ label: "AGBSZ.V", value: 18486391, partition: 0 },
	{ label: "ABBDP.V", value: 6296711, partition: 0 },
	{ label: "ATIQK.V", value: 4986768, partition: 0 },
	{ label: "BRKO.E", value: 72316, partition: 0 },
	{ label: "ANICD.V", value: 201871, partition: 0 },
	{ label: "AT1AT.V", value: 4986770, partition: 0 },
	{ label: "BR1PC.V", value: 1186490, partition: 0 },
	{ label: "ATAPQ.V", value: 4986771, partition: 0 },
	{ label: "BMSQH.V", value: 3938817, partition: 0 },
	{ label: "BIIMN.V", value: 1710479, partition: 0 },
	{ label: "BIAEU.V", value: 1710480, partition: 0 },
	{ label: "AGBSL.V", value: 8787063, partition: 0 },
	{ label: "BI1AH.V", value: 1710485, partition: 0 },
	{ label: "DKIBL.V", value: 400541, partition: 0 },
	{ label: "BRKSN.E", value: 72336, partition: 0 },
	{ label: "DABCU.V", value: 400542, partition: 0 },
	{ label: "MDSBA.V", value: 1578055, partition: 0 },
	{ label: "BRKVY.E", value: 72341, partition: 0 },
	{ label: "BRLSM.E", value: 72346, partition: 0 },
	{ label: "BR1PL.V", value: 3676858, partition: 0 },
	{ label: "ALIAW.V", value: 530114, partition: 0 },
	{ label: "BRMEN.E", value: 72356, partition: 0 },
	{ label: "LRIAY.V", value: 792108, partition: 0 },
	{ label: "APBBT.V", value: 530120, partition: 0 },
	{ label: "BIBDG.V", value: 4200850, partition: 0 },
	{ label: "DAAGA.V", value: 2890906, partition: 0 },
	{ label: "DASFI.V", value: 2890907, partition: 0 },
	{ label: "BI1AR.V", value: 4200853, partition: 0 },
	{ label: "MDSPN.V", value: 4068423, partition: 0 },
	{ label: "DBBQQ.V", value: 5119259, partition: 0 },
	{ label: "BBSBR.V", value: 1840078, partition: 0 },
	{ label: "BOBBO.V", value: 4200860, partition: 0 },
	{ label: "BRSAN.E", value: 72376, partition: 0 },
	{ label: "AEICD.V", value: 268148, partition: 0 },
	{ label: "ASBCR.V", value: 268151, partition: 0 },
	{ label: "AS1AW.V", value: 268152, partition: 0 },
	{ label: "AOIBU.V", value: 1578100, partition: 0 },
	{ label: "AGAFL.V", value: 1578101, partition: 0 },
	{ label: "AGSDC.V", value: 1578102, partition: 0 },
	{ label: "APSQK.V", value: 3020485, partition: 0 },
	{ label: "LRABH.V", value: 3282475, partition: 0 },
	{ label: "LR1PK.V", value: 3282477, partition: 0 },
	{ label: "AZBBV.V", value: 792145, partition: 0 },
	{ label: "BRYAT.E", value: 72396, partition: 0 },
	{ label: "SCSAU.V", value: 530159, partition: 0 },
	{ label: "DASRS.V", value: 5381275, partition: 0 },
	{ label: "ABBDH.V", value: 1578119, partition: 0 },
	{ label: "ABABY.V", value: 1578120, partition: 0 },
	{ label: "ATIBO.V", value: 268176, partition: 0 },
	{ label: "BBSPW.V", value: 4330446, partition: 0 },
	{ label: "AT1AW.V", value: 268178, partition: 0 },
	{ label: "RMSAT.V", value: 530169, partition: 0 },
	{ label: "AEIRM.V", value: 2758516, partition: 0 },
	{ label: "ANICI.V", value: 1578127, partition: 0 },
	{ label: "ASAVX.V", value: 2758517, partition: 0 },
	{ label: "CUIAP.V", value: 662606, partition: 0 },
	{ label: "ASBCV.V", value: 2758519, partition: 0 },
	{ label: "BSOKE.E", value: 72416, partition: 0 },
	{ label: "AGAUS.V", value: 4068469, partition: 0 },
	{ label: "AGSQN.V", value: 4068470, partition: 0 },
	{ label: "AGBCZ.V", value: 4068471, partition: 0 },
	{ label: "LR1PT.V", value: 5772845, partition: 0 },
	{ label: "AGBDU.V", value: 16258167, partition: 0 },
	{ label: "DABSD.V", value: 7871646, partition: 0 },
	{ label: "ABARH.V", value: 4068488, partition: 0 },
	{ label: "BTCIM.E", value: 72436, partition: 0 },
	{ label: "ATSBQ.V", value: 2758545, partition: 0 },
	{ label: "BMSCZ.V", value: 1710593, partition: 0 },
	{ label: "ABSSG.V", value: 4068496, partition: 0 },
	{ label: "ASSRK.V", value: 5248886, partition: 0 },
	{ label: "AB1PM.V", value: 4068497, partition: 0 },
	{ label: "BABBQ.V", value: 4462920, partition: 0 },
	{ label: "ASBDJ.V", value: 5248887, partition: 0 },
	{ label: "AS1AV.V", value: 5248888, partition: 0 },
	{ label: "AGBDG.V", value: 6558839, partition: 0 },
	{ label: "BUCIM.E", value: 72456, partition: 0 },
	{ label: "AGBTB.V", value: 18748535, partition: 0 },
	{ label: "ABBDR.V", value: 6558855, partition: 0 },
	{ label: "ATIQM.V", value: 5248912, partition: 0 },
	{ label: "ATSPP.V", value: 5248913, partition: 0 },
	{ label: "AT1AV.V", value: 5248914, partition: 0 },
	{ label: "BR1PE.V", value: 1448634, partition: 0 },
	{ label: "ATAPS.V", value: 5248915, partition: 0 },
	{ label: "BMSQL.V", value: 4200961, partition: 0 },
	{ label: "BURCE.E", value: 72476, partition: 0 },
	{ label: "BIINA.V", value: 1972623, partition: 0 },
	{ label: "BIATR.V", value: 1972624, partition: 0 },
	{ label: "AGBSN.V", value: 9049207, partition: 0 },
	{ label: "BISFD.V", value: 1972625, partition: 0 },
	{ label: "BI1PE.V", value: 1972629, partition: 0 },
	{ label: "DKIBN.V", value: 662685, partition: 0 },
	{ label: "DABCW.V", value: 662686, partition: 0 },
	{ label: "MDSPP.V", value: 1840199, partition: 0 },
	{ label: "DBBBJ.V", value: 2891035, partition: 0 },
	{ label: "BURVA.E", value: 72496, partition: 0 },
	{ label: "ALIWX.V", value: 792258, partition: 0 },
	{ label: "LRIPV.V", value: 1054252, partition: 0 },
	{ label: "APBBV.V", value: 792264, partition: 0 },
	{ label: "BVSAN.E", value: 72516, partition: 0 },
	{ label: "BIBDI.V", value: 4462994, partition: 0 },
	{ label: "DAAGC.V", value: 3153050, partition: 0 },
	{ label: "DASFK.V", value: 3153051, partition: 0 },
	{ label: "BI1PN.V", value: 4462997, partition: 0 },
	{ label: "DBBQS.V", value: 5381403, partition: 0 },
	{ label: "BBSBT.V", value: 2102222, partition: 0 },
	{ label: "BOBBQ.V", value: 4463004, partition: 0 },
	{ label: "AEICF.V", value: 530292, partition: 0 },
	{ label: "ASBCT.V", value: 530295, partition: 0 },
	{ label: "AS1AY.V", value: 530296, partition: 0 },
	{ label: "CANTE.E", value: 72536, partition: 0 },
	{ label: "AOIQS.V", value: 1840244, partition: 0 },
	{ label: "AGAFN.V", value: 1840245, partition: 0 },
	{ label: "AGSDE.V", value: 1840246, partition: 0 },
	{ label: "LRAQE.V", value: 3544619, partition: 0 },
	{ label: "CASA.E", value: 72546, partition: 0 },
	{ label: "LR1PM.V", value: 3544621, partition: 0 },
	{ label: "AZBQO.V", value: 1054289, partition: 0 },
	{ label: "CATES.E", value: 72551, partition: 0 },
	{ label: "SCSAW.V", value: 792303, partition: 0 },
	{ label: "CCOLA.E", value: 72556, partition: 0 },
	{ label: "ABACA.V", value: 1840264, partition: 0 },
	{ label: "ATIBQ.V", value: 530320, partition: 0 },
	{ label: "AT1AY.V", value: 530322, partition: 0 },
	{ label: "RMSAV.V", value: 792313, partition: 0 },
	{ label: "ASAGZ.V", value: 3020661, partition: 0 },
	{ label: "ANICK.V", value: 1840271, partition: 0 },
	{ label: "ASSFK.V", value: 3020662, partition: 0 },
	{ label: "CUIPN.V", value: 924750, partition: 0 },
	{ label: "ASBCX.V", value: 3020663, partition: 0 },
	{ label: "AGSDH.V", value: 4330614, partition: 0 },
	{ label: "AGBDB.V", value: 4330615, partition: 0 },
	{ label: "CELHA.E", value: 72576, partition: 0 },
	{ label: "AGBDW.V", value: 16520311, partition: 0 },
	{ label: "DABSF.V", value: 8133790, partition: 0 },
	{ label: "ATSBS.V", value: 3020689, partition: 0 },
	{ label: "ATAAL.V", value: 3020691, partition: 0 },
	{ label: "CEMAS.E", value: 72596, partition: 0 },
	{ label: "BMSDB.V", value: 1972737, partition: 0 },
	{ label: "ABSGK.V", value: 4330640, partition: 0 },
	{ label: "AB1PO.V", value: 4330641, partition: 0 },
	{ label: "BABBS.V", value: 4725064, partition: 0 },
	{ label: "AS1PS.V", value: 5511032, partition: 0 },
	{ label: "AGBDI.V", value: 6820983, partition: 0 },
	{ label: "CEMTS.E", value: 72616, partition: 0 },
	{ label: "AGBTD.V", value: 19010679, partition: 0 },
	{ label: "ABBQQ.V", value: 6820999, partition: 0 },
	{ label: "AT1PS.V", value: 5511058, partition: 0 },
	{ label: "BR1AG.V", value: 1710778, partition: 0 },
	{ label: "CIMSA.E", value: 72636, partition: 0 },
	{ label: "BIIQI.V", value: 2234767, partition: 0 },
	{ label: "BIATT.V", value: 2234768, partition: 0 },
	{ label: "AGBSP.V", value: 9311351, partition: 0 },
	{ label: "BISFH.V", value: 2234769, partition: 0 },
	{ label: "BI1PG.V", value: 2234773, partition: 0 },
	{ label: "DKIQL.V", value: 924829, partition: 0 },
	{ label: "DABCY.V", value: 924830, partition: 0 },
	{ label: "DBBBL.V", value: 3153179, partition: 0 },
	{ label: "CLEBI.E", value: 72656, partition: 0 },
	{ label: "ALIAY.V", value: 1054402, partition: 0 },
	{ label: "LRIPX.V", value: 1316396, partition: 0 },
	{ label: "APBQO.V", value: 1054408, partition: 0 },
	{ label: "BIBDK.V", value: 4725138, partition: 0 },
	{ label: "DAAGE.V", value: 3415194, partition: 0 },
	{ label: "DASFM.V", value: 3415195, partition: 0 },
	{ label: "BI1PP.V", value: 4725141, partition: 0 },
	{ label: "BBSBV.V", value: 2364366, partition: 0 },
	{ label: "BOBBS.V", value: 4725148, partition: 0 },
	{ label: "AEICH.V", value: 792436, partition: 0 },
	{ label: "AS1BA.V", value: 792440, partition: 0 },
	{ label: "AOIQU.V", value: 2102388, partition: 0 },
	{ label: "AGAUJ.V", value: 2102389, partition: 0 },
	{ label: "AGSDG.V", value: 2102390, partition: 0 },
	{ label: "CMBTN.E", value: 72696, partition: 0 },
	{ label: "ALKLC.E", value: 202253, partition: 0 },
	{ label: "LRAQG.V", value: 3806763, partition: 0 },
	{ label: "LR1AO.V", value: 3806765, partition: 0 },
	{ label: "AZSCW.V", value: 1316432, partition: 0 },
	{ label: "AZBQQ.V", value: 1316433, partition: 0 },
	{ label: "SCSAX.V", value: 1054447, partition: 0 },
	{ label: "DABRY.V", value: 5905566, partition: 0 },
	{ label: "ABACC.V", value: 2102408, partition: 0 },
	{ label: "ATIBS.V", value: 792464, partition: 0 },
	{ label: "CMENT.E", value: 72716, partition: 0 },
	{ label: "AT1BA.V", value: 792466, partition: 0 },
	{ label: "RMSAW.V", value: 1054457, partition: 0 },
	{ label: "ANICM.V", value: 2102415, partition: 0 },
	{ label: "ASAHB.V", value: 3282805, partition: 0 },
	{ label: "ABSFX.V", value: 2102416, partition: 0 },
	{ label: "ASSFQ.V", value: 3282806, partition: 0 },
	{ label: "CUIPP.V", value: 1186894, partition: 0 },
	{ label: "ASBCZ.V", value: 3282807, partition: 0 },
	{ label: "AGSDJ.V", value: 4592758, partition: 0 },
	{ label: "AGBDD.V", value: 4592759, partition: 0 },
	{ label: "AGBDY.V", value: 16782455, partition: 0 },
	{ label: "DABSH.V", value: 8395934, partition: 0 },
	{ label: "ATSBU.V", value: 3282833, partition: 0 },
	{ label: "ATAAN.V", value: 3282835, partition: 0 },
	{ label: "ABSSK.V", value: 4592784, partition: 0 },
	{ label: "AB1PQ.V", value: 4592785, partition: 0 },
	{ label: "BABQP.V", value: 4987208, partition: 0 },
	{ label: "COSMO.E", value: 72756, partition: 0 },
	{ label: "AS1PU.V", value: 5773176, partition: 0 },
	{ label: "AGBDK.V", value: 7083127, partition: 0 },
	{ label: "CRDFA.E", value: 72776, partition: 0 },
	{ label: "ABBQS.V", value: 7083143, partition: 0 },
	{ label: "BR1AI.V", value: 1972922, partition: 0 },
	{ label: "AT1PU.V", value: 5773202, partition: 0 },
	{ label: "CRFSA.E", value: 72786, partition: 0 },
	{ label: "ASBPP.V", value: 8263543, partition: 0 },
	{ label: "CUSAN.E", value: 72791, partition: 0 },
	{ label: "BIIQK.V", value: 2496911, partition: 0 },
	{ label: "AGBSR.V", value: 9573495, partition: 0 },
	{ label: "BISFJ.V", value: 2496913, partition: 0 },
	{ label: "DAGHL.E", value: 72796, partition: 0 },
	{ label: "BI1PI.V", value: 2496917, partition: 0 },
	{ label: "DKIQN.V", value: 1186973, partition: 0 },
	{ label: "DABDA.V", value: 1186974, partition: 0 },
	{ label: "DBBBN.V", value: 3415323, partition: 0 },
	{ label: "CVKMD.E", value: 72806, partition: 0 },
	{ label: "CWENE.E", value: 72811, partition: 0 },
	{ label: "DAGI.E", value: 72816, partition: 0 },
	{ label: "ALIWZ.V", value: 1316546, partition: 0 },
	{ label: "APSCW.V", value: 1316549, partition: 0 },
	{ label: "LRAAZ.V", value: 1578539, partition: 0 },
	{ label: "LRIAZ.V", value: 1578540, partition: 0 },
	{ label: "APBQQ.V", value: 1316552, partition: 0 },
	{ label: "DAPGM.E", value: 72826, partition: 0 },
	{ label: "DAAVB.V", value: 3677338, partition: 0 },
	{ label: "DARDL.E", value: 72836, partition: 0 },
	{ label: "BBSBX.V", value: 2626510, partition: 0 },
	{ label: "BOBQP.V", value: 4987292, partition: 0 },
	{ label: "AEIRE.V", value: 1054580, partition: 0 },
	{ label: "ASSFF.V", value: 1054582, partition: 0 },
	{ label: "AS1PW.V", value: 1054584, partition: 0 },
	{ label: "LRAQI.V", value: 4068907, partition: 0 },
	{ label: "LR1AQ.V", value: 4068909, partition: 0 },
	{ label: "AZSCY.V", value: 1578576, partition: 0 },
	{ label: "AZBBW.V", value: 1578577, partition: 0 },
	{ label: "CTIEO.V", value: 1449023, partition: 0 },
	{ label: "SCSAZ.V", value: 1316591, partition: 0 },
	{ label: "DABSA.V", value: 6167710, partition: 0 },
	{ label: "ABAQZ.V", value: 2364552, partition: 0 },
	{ label: "ATIQP.V", value: 1054608, partition: 0 },
	{ label: "AT1PW.V", value: 1054610, partition: 0 },
	{ label: "RMSAY.V", value: 1316601, partition: 0 },
	{ label: "ANIRK.V", value: 2364559, partition: 0 },
	{ label: "ASAVY.V", value: 3544949, partition: 0 },
	{ label: "ABSFZ.V", value: 2364560, partition: 0 },
	{ label: "ASSRM.V", value: 3544950, partition: 0 },
	{ label: "ASBPK.V", value: 3544951, partition: 0 },
	{ label: "AS1AN.V", value: 3544952, partition: 0 },
	{ label: "AGSQR.V", value: 4854902, partition: 0 },
	{ label: "AGBDF.V", value: 4854903, partition: 0 },
	{ label: "AZSQI.V", value: 4068944, partition: 0 },
	{ label: "AGBEA.V", value: 17044599, partition: 0 },
	{ label: "DENGE.E", value: 72896, partition: 0 },
	{ label: "DABSJ.V", value: 8658078, partition: 0 },
	{ label: "ABBQL.V", value: 4854919, partition: 0 },
	{ label: "ATSPR.V", value: 3544977, partition: 0 },
	{ label: "ARDYZ.E", value: 202458, partition: 0 },
	{ label: "AT1AN.V", value: 3544978, partition: 0 },
	{ label: "ATAPK.V", value: 3544979, partition: 0 },
	{ label: "ABSSM.V", value: 4854928, partition: 0 },
	{ label: "AB1AS.V", value: 4854929, partition: 0 },
	{ label: "BABQR.V", value: 5249352, partition: 0 },
	{ label: "BIIMH.V", value: 268687, partition: 0 },
	{ label: "AGBDM.V", value: 7345271, partition: 0 },
	{ label: "BIBCR.V", value: 268690, partition: 0 },
	{ label: "DERHL.E", value: 72931, partition: 0 },
	{ label: "ABBQU.V", value: 7345287, partition: 0 },
	{ label: "DERIM.E", value: 72936, partition: 0 },
	{ label: "BR1PF.V", value: 2235066, partition: 0 },
	{ label: "ABSSH.V", value: 7345296, partition: 0 },
	{ label: "ASBPR.V", value: 8525687, partition: 0 },
	{ label: "BIIQM.V", value: 2759055, partition: 0 },
	{ label: "BIATV.V", value: 2759056, partition: 0 },
	{ label: "BISFL.V", value: 2759057, partition: 0 },
	{ label: "BIBCV.V", value: 2759058, partition: 0 },
	{ label: "BI1AK.V", value: 2759061, partition: 0 },
	{ label: "DKIBP.V", value: 1449117, partition: 0 },
	{ label: "DABDC.V", value: 1449118, partition: 0 },
	{ label: "DESA.E", value: 72956, partition: 0 },
	{ label: "DBBQK.V", value: 3677467, partition: 0 },
	{ label: "ALIBP.V", value: 1578690, partition: 0 },
	{ label: "APSCY.V", value: 1578693, partition: 0 },
	{ label: "DESPC.E", value: 72976, partition: 0 },
	{ label: "LRABB.V", value: 1840683, partition: 0 },
	{ label: "LRIBB.V", value: 1840684, partition: 0 },
	{ label: "APBBW.V", value: 1578696, partition: 0 },
	{ label: "ARTMS.E", value: 202538, partition: 0 },
	{ label: "DAAVD.V", value: 3939482, partition: 0 },
	{ label: "BBSPU.V", value: 2888654, partition: 0 },
	{ label: "BOBQR.V", value: 5249436, partition: 0 },
	{ label: "AEIRG.V", value: 1316724, partition: 0 },
	{ label: "DEVA.E", value: 72996, partition: 0 },
	{ label: "ASSFH.V", value: 1316726, partition: 0 },
	{ label: "AGAUL.V", value: 2626677, partition: 0 },
	{ label: "APSQI.V", value: 4069061, partition: 0 },
	{ label: "LR1PN.V", value: 4331053, partition: 0 },
	{ label: "AZSDA.V", value: 1840720, partition: 0 },
	{ label: "CTIFB.V", value: 1711167, partition: 0 },
	{ label: "DGATE.E", value: 73016, partition: 0 },
	{ label: "SCSBB.V", value: 1578735, partition: 0 },
	{ label: "DABSC.V", value: 6429854, partition: 0 },
	{ label: "ABARB.V", value: 2626696, partition: 0 },
	{ label: "ATIQR.V", value: 1316752, partition: 0 },
	{ label: "RMSBA.V", value: 1578745, partition: 0 },
	{ label: "ANIRM.V", value: 2626703, partition: 0 },
	{ label: "ASAWA.V", value: 3807093, partition: 0 },
	{ label: "ABSGB.V", value: 2626704, partition: 0 },
	{ label: "ASSRO.V", value: 3807094, partition: 0 },
	{ label: "BABBK.V", value: 3021128, partition: 0 },
	{ label: "ASBPM.V", value: 3807095, partition: 0 },
	{ label: "AS1AP.V", value: 3807096, partition: 0 },
	{ label: "AGSQT.V", value: 5117046, partition: 0 },
	{ label: "AGBRV.V", value: 5117047, partition: 0 },
	{ label: "AZSQM.V", value: 4331088, partition: 0 },
	{ label: "AGBEC.V", value: 17306743, partition: 0 },
	{ label: "SCSPM.V", value: 4069103, partition: 0 },
	{ label: "DABSL.V", value: 8920222, partition: 0 },
	{ label: "ABBQN.V", value: 5117063, partition: 0 },
	{ label: "ATSPT.V", value: 3807121, partition: 0 },
	{ label: "AT1AP.V", value: 3807122, partition: 0 },
	{ label: "ATAPM.V", value: 3807123, partition: 0 },
	{ label: "RMSPN.V", value: 4069113, partition: 0 },
	{ label: "ABSSO.V", value: 5117072, partition: 0 },
	{ label: "AB1AU.V", value: 5117073, partition: 0 },
	{ label: "BIIMJ.V", value: 530831, partition: 0 },
	{ label: "AGBDO.V", value: 7607415, partition: 0 },
	{ label: "BIBCT.V", value: 530834, partition: 0 },
	{ label: "MDSAS.V", value: 398407, partition: 0 },
	{ label: "BR1PH.V", value: 2497210, partition: 0 },
	{ label: "ASBPT.V", value: 8787831, partition: 0 },
	{ label: "BIAEX.V", value: 3021200, partition: 0 },
	{ label: "BISFG.V", value: 3021201, partition: 0 },
	{ label: "BIBCX.V", value: 3021202, partition: 0 },
	{ label: "DAAFW.V", value: 1711258, partition: 0 },
	{ label: "DASEZ.V", value: 1711259, partition: 0 },
	{ label: "BI1AM.V", value: 3021205, partition: 0 },
	{ label: "DKIBR.V", value: 1711261, partition: 0 },
	{ label: "DABRU.V", value: 1711262, partition: 0 },
	{ label: "DBBQM.V", value: 3939611, partition: 0 },
	{ label: "BOBBK.V", value: 3021212, partition: 0 },
	{ label: "AOIBP.V", value: 398452, partition: 0 },
	{ label: "ALIXB.V", value: 1840834, partition: 0 },
	{ label: "APSDA.V", value: 1840837, partition: 0 },
	{ label: "LRABD.V", value: 2102827, partition: 0 },
	{ label: "LRIBD.V", value: 2102828, partition: 0 },
	{ label: "ABBDA.V", value: 398471, partition: 0 },
	{ label: "AEICI.V", value: 1578868, partition: 0 },
	{ label: "ASAGT.V", value: 1578869, partition: 0 },
	{ label: "ANICE.V", value: 398479, partition: 0 },
	{ label: "ASSFL.V", value: 1578870, partition: 0 },
	{ label: "AB1AX.V", value: 398481, partition: 0 },
	{ label: "AGAUN.V", value: 2888821, partition: 0 },
	{ label: "APSQM.V", value: 4331205, partition: 0 },
	{ label: "LR1PP.V", value: 4593197, partition: 0 },
	{ label: "AZSDC.V", value: 2102864, partition: 0 },
	{ label: "CTIPK.V", value: 1973311, partition: 0 },
	{ label: "SCSPP.V", value: 1840879, partition: 0 },
	{ label: "DABDE.V", value: 6691998, partition: 0 },
	{ label: "ABACD.V", value: 2888840, partition: 0 },
	{ label: "ATSBI.V", value: 1578897, partition: 0 },
	{ label: "RMSPP.V", value: 1840889, partition: 0 },
	{ label: "ASAWC.V", value: 4069237, partition: 0 },
	{ label: "ABSGD.V", value: 2888848, partition: 0 },
	{ label: "BABBM.V", value: 3283272, partition: 0 },
	{ label: "ASBPO.V", value: 4069239, partition: 0 },
	{ label: "AS1PM.V", value: 4069240, partition: 0 },
	{ label: "AGSQV.V", value: 5379190, partition: 0 },
	{ label: "AGBRX.V", value: 5379191, partition: 0 },
	{ label: "AZBBZ.V", value: 4593233, partition: 0 },
	{ label: "AGBSS.V", value: 17568887, partition: 0 },
	{ label: "SCSPQ.V", value: 4331247, partition: 0 },
	{ label: "ABBQP.V", value: 5379207, partition: 0 },
	{ label: "AT1PM.V", value: 4069266, partition: 0 },
	{ label: "BR1AA.V", value: 268986, partition: 0 },
	{ label: "ATAPO.V", value: 4069267, partition: 0 },
	{ label: "AB1PR.V", value: 5379217, partition: 0 },
	{ label: "BIIML.V", value: 792975, partition: 0 },
	{ label: "AGSQP.V", value: 7869558, partition: 0 },
	{ label: "AGBDQ.V", value: 7869559, partition: 0 },
	{ label: "MDSAU.V", value: 660551, partition: 0 },
	{ label: "BR1AK.V", value: 2759354, partition: 0 },
	{ label: "BIAEZ.V", value: 3283344, partition: 0 },
	{ label: "BISRE.V", value: 3283345, partition: 0 },
	{ label: "BIBCZ.V", value: 3283346, partition: 0 },
	{ label: "DAAFY.V", value: 1973402, partition: 0 },
	{ label: "DASFB.V", value: 1973403, partition: 0 },
	{ label: "BI1PJ.V", value: 3283349, partition: 0 },
	{ label: "DKIQP.V", value: 1973405, partition: 0 },
	{ label: "DABRW.V", value: 1973406, partition: 0 },
	{ label: "DBBBO.V", value: 4201755, partition: 0 },
	{ label: "BOBBM.V", value: 3283356, partition: 0 },
	{ label: "AOIBR.V", value: 660596, partition: 0 },
	{ label: "ALIXD.V", value: 2102978, partition: 0 },
	{ label: "APSDC.V", value: 2102981, partition: 0 },
	{ label: "LRAQA.V", value: 2364971, partition: 0 },
	{ label: "LRIQA.V", value: 2364972, partition: 0 },
	{ label: "LR1AI.V", value: 2364973, partition: 0 },
	{ label: "BISRF.V", value: 5773713, partition: 0 },
	{ label: "DASRI.V", value: 4463771, partition: 0 },
	{ label: "AEICK.V", value: 1841012, partition: 0 },
	{ label: "ANICG.V", value: 660623, partition: 0 },
	{ label: "ASAGV.V", value: 1841013, partition: 0 },
	{ label: "ASSFN.V", value: 1841014, partition: 0 },
	{ label: "AB1AZ.V", value: 660625, partition: 0 },
	{ label: "AGAFQ.V", value: 3150965, partition: 0 },
	{ label: "AGSCY.V", value: 3150966, partition: 0 },
	{ label: "APBBZ.V", value: 4593352, partition: 0 },
	{ label: "LR1AS.V", value: 4855341, partition: 0 },
	{ label: "AZSDE.V", value: 2365008, partition: 0 },
	{ label: "CTIPM.V", value: 2235455, partition: 0 },
	{ label: "DABDG.V", value: 6954142, partition: 0 },
	{ label: "ABACF.V", value: 3150984, partition: 0 },
	{ label: "ATSBK.V", value: 1841041, partition: 0 },
	{ label: "ABSGF.V", value: 3150992, partition: 0 },
	{ label: "BABQJ.V", value: 3545416, partition: 0 },
	{ label: "ASBDC.V", value: 4331383, partition: 0 },
	{ label: "AS1PO.V", value: 4331384, partition: 0 },
	{ label: "AGBRZ.V", value: 5641335, partition: 0 },
	{ label: "AZBQS.V", value: 4855377, partition: 0 },
	{ label: "AGBSU.V", value: 17831031, partition: 0 },
	{ label: "ABBDK.V", value: 5641351, partition: 0 },
	{ label: "ATIBK.V", value: 4331408, partition: 0 },
	{ label: "AT1PO.V", value: 4331410, partition: 0 },
	{ label: "BR1AC.V", value: 531130, partition: 0 },
	{ label: "ATAAQ.V", value: 4331411, partition: 0 },
	{ label: "AB1PT.V", value: 5641361, partition: 0 },
	{ label: "BIIQE.V", value: 1055119, partition: 0 },
	{ label: "AGSQW.V", value: 8131702, partition: 0 },
	{ label: "AGBSG.V", value: 8131703, partition: 0 },
	{ label: "BISFB.V", value: 1055121, partition: 0 },
	{ label: "MDSPL.V", value: 922695, partition: 0 },
	{ label: "BR1AM.V", value: 3021498, partition: 0 },
	{ label: "BIATW.V", value: 3545488, partition: 0 },
	{ label: "BISFN.V", value: 3545489, partition: 0 },
	{ label: "BIBDB.V", value: 3545490, partition: 0 },
	{ label: "DAAUV.V", value: 2235546, partition: 0 },
	{ label: "DASFD.V", value: 2235547, partition: 0 },
	{ label: "BI1PL.V", value: 3545493, partition: 0 },
	{ label: "DKIQR.V", value: 2235549, partition: 0 },
	{ label: "DBBBQ.V", value: 4463899, partition: 0 },
	{ label: "BOBQJ.V", value: 3545500, partition: 0 },
	{ label: "AOIQP.V", value: 922740, partition: 0 },
	{ label: "AGSCT.V", value: 922742, partition: 0 },
	{ label: "APSDE.V", value: 2365125, partition: 0 },
	{ label: "LRAQC.V", value: 2627115, partition: 0 },
	{ label: "LRIQC.V", value: 2627116, partition: 0 },
	{ label: "LR1AK.V", value: 2627117, partition: 0 },
	{ label: "DASRK.V", value: 4725915, partition: 0 },
	{ label: "ABBDC.V", value: 922759, partition: 0 },
	{ label: "AEICM.V", value: 2103156, partition: 0 },
	{ label: "ANIRE.V", value: 922767, partition: 0 },
	{ label: "ASAGX.V", value: 2103157, partition: 0 },
	{ label: "ASSFP.V", value: 2103158, partition: 0 },
	{ label: "AB1PV.V", value: 922769, partition: 0 },
	{ label: "A1CAP.E", value: 70586, partition: 0 },
	{ label: "AGAFS.V", value: 3413109, partition: 0 },
	{ label: "AGSDA.V", value: 3413110, partition: 0 },
	{ label: "AGBCU.V", value: 3413111, partition: 0 },
	{ label: "APBQS.V", value: 4855496, partition: 0 },
	{ label: "LR1AU.V", value: 5117485, partition: 0 },
	{ label: "DASRL.V", value: 7216283, partition: 0 },
	{ label: "DABDI.V", value: 7216286, partition: 0 },
	{ label: "ABACH.V", value: 3413128, partition: 0 },
	{ label: "ATSBM.V", value: 2103185, partition: 0 },
	{ label: "BMSCT.V", value: 1055233, partition: 0 },
	{ label: "ACSEL.E", value: 70616, partition: 0 },
	{ label: "ABSGH.V", value: 3413136, partition: 0 },
	{ label: "ASBDE.V", value: 4593527, partition: 0 },
	{ label: "AB1AM.V", value: 3413137, partition: 0 },
	{ label: "BABQL.V", value: 3807560, partition: 0 },
	{ label: "AS1PQ.V", value: 4593528, partition: 0 },
	{ label: "BARMA.E", value: 203058, partition: 0 },
	{ label: "AGBSB.V", value: 5903479, partition: 0 },
	{ label: "AZBQU.V", value: 5117521, partition: 0 },
	{ label: "AGBSW.V", value: 18093175, partition: 0 },
	{ label: "ABBDM.V", value: 5903495, partition: 0 },
	{ label: "ATIBM.V", value: 4593552, partition: 0 },
	{ label: "AT1PQ.V", value: 4593554, partition: 0 },
	{ label: "BR1AE.V", value: 793274, partition: 0 },
	{ label: "ATAAS.V", value: 4593555, partition: 0 },
	{ label: "AB1PY.V", value: 5903505, partition: 0 },
	{ label: "BIIQG.V", value: 1317263, partition: 0 },
	{ label: "BIAER.V", value: 1317264, partition: 0 },
	{ label: "AGBSI.V", value: 8393847, partition: 0 },
	{ label: "BI1AE.V", value: 1317269, partition: 0 },
	{ label: "BAYRK.E", value: 203098, partition: 0 },
	{ label: "MDSAX.V", value: 1184839, partition: 0 },
	{ label: "ADEL.E", value: 70676, partition: 0 },
	{ label: "BR1AO.V", value: 3283642, partition: 0 },
	{ label: "LRIAV.V", value: 398892, partition: 0 },
	{ label: "BIATY.V", value: 3807632, partition: 0 },
	{ label: "BISRH.V", value: 3807633, partition: 0 },
	{ label: "BIBDD.V", value: 3807634, partition: 0 },
	{ label: "DAAUX.V", value: 2497690, partition: 0 },
	{ label: "DASFF.V", value: 2497691, partition: 0 },
	{ label: "BI1AO.V", value: 3807637, partition: 0 },
	{ label: "ADESE.E", value: 70696, partition: 0 },
	{ label: "DBBBS.V", value: 4726043, partition: 0 },
	{ label: "BOBQL.V", value: 3807644, partition: 0 },
	{ label: "AOIQR.V", value: 1184884, partition: 0 },
	{ label: "AGSCV.V", value: 1184886, partition: 0 },
	{ label: "LRABE.V", value: 2889259, partition: 0 },
	{ label: "LR1AM.V", value: 2889261, partition: 0 },
	{ label: "BINHO.E", value: 203155, partition: 0 },
	{ label: "AZBBS.V", value: 398929, partition: 0 },
	{ label: "DASRP.V", value: 4988059, partition: 0 },
	{ label: "ABBDE.V", value: 1184903, partition: 0 },
	{ label: "BBSPR.V", value: 3937230, partition: 0 },
	{ label: "AEFES.E", value: 70736, partition: 0 },
	{ label: "AEIRJ.V", value: 2365300, partition: 0 },
	{ label: "ANIRG.V", value: 1184911, partition: 0 },
	{ label: "ASAVU.V", value: 2365301, partition: 0 },
	{ label: "CUIAM.V", value: 269390, partition: 0 },
	{ label: "ABSFW.V", value: 1184912, partition: 0 },
	{ label: "AB1PX.V", value: 1184913, partition: 0 },
	{ label: "AGAUP.V", value: 3675253, partition: 0 },
	{ label: "AGSQK.V", value: 3675254, partition: 0 },
	{ label: "AGBCW.V", value: 3675255, partition: 0 },
	{ label: "APBQU.V", value: 5117640, partition: 0 },
	{ label: "LR1AW.V", value: 5379629, partition: 0 },
	{ label: "AZSQH.V", value: 2889296, partition: 0 },
	{ label: "AFYON.E", value: 70756, partition: 0 },
	{ label: "AGESA.E", value: 70758, partition: 0 },
	{ label: "DASRN.V", value: 7478427, partition: 0 },
	{ label: "AGHOL.E", value: 70761, partition: 0 },
	{ label: "DABDK.V", value: 7478430, partition: 0 },
	{ label: "ABARE.V", value: 3675272, partition: 0 },
	{ label: "ATSBO.V", value: 2365329, partition: 0 },
	{ label: "BMSCV.V", value: 1317377, partition: 0 },
	{ label: "ABSSD.V", value: 3675280, partition: 0 },
	{ label: "AB1AO.V", value: 3675281, partition: 0 },
	{ label: "BABQN.V", value: 4069704, partition: 0 },
	{ label: "ASBDG.V", value: 4855671, partition: 0 },
	{ label: "AS1AS.V", value: 4855672, partition: 0 },
	{ label: "AGYO.E", value: 70776, partition: 0 },
	{ label: "AGBSD.V", value: 6165623, partition: 0 },
	{ label: "AGROT.E", value: 70781, partition: 0 },
	{ label: "AHGAZ.E", value: 70786, partition: 0 },
	{ label: "AZBQW.V", value: 5379665, partition: 0 },
	{ label: "AGBSY.V", value: 18355319, partition: 0 },
	{ label: "ABBDO.V", value: 6165639, partition: 0 },
	{ label: "AKBNK.E", value: 70796, partition: 0 },
	{ label: "ATIQJ.V", value: 4855696, partition: 0 },
	{ label: "AT1AS.V", value: 4855698, partition: 0 },
	{ label: "BR1PB.V", value: 1055418, partition: 0 },
	{ label: "ATAPP.V", value: 4855699, partition: 0 },
	{ label: "BMSQG.V", value: 3807745, partition: 0 },
	{ label: "BIIMM.V", value: 1579407, partition: 0 },
	{ label: "BIAET.V", value: 1579408, partition: 0 },
	{ label: "AGBSK.V", value: 8655991, partition: 0 },
	{ label: "AKCNS.E", value: 70816, partition: 0 },
	{ label: "BI1AG.V", value: 1579413, partition: 0 },
	{ label: "DKIBK.V", value: 269469, partition: 0 },
	{ label: "DABCT.V", value: 269470, partition: 0 },
	{ label: "MDSAZ.V", value: 1446983, partition: 0 },
	{ label: "BMSCH.E", value: 203258, partition: 0 },
	{ label: "BMSTL.E", value: 203260, partition: 0 },
	{ label: "BR1PK.V", value: 3545786, partition: 0 },
	{ label: "AKENR.E", value: 70836, partition: 0 },
	{ label: "ALIAV.V", value: 399042, partition: 0 },
	{ label: "LRIAX.V", value: 661036, partition: 0 },
	{ label: "APBBS.V", value: 399048, partition: 0 },
	{ label: "BIAUA.V", value: 4069776, partition: 0 },
	{ label: "BIBDF.V", value: 4069778, partition: 0 },
	{ label: "DAAUZ.V", value: 2759834, partition: 0 },
	{ label: "DASFH.V", value: 2759835, partition: 0 },
	{ label: "BI1AQ.V", value: 4069781, partition: 0 },
	{ label: "MDSPM.V", value: 3937351, partition: 0 },
	{ label: "DBBQP.V", value: 4988187, partition: 0 },
	{ label: "BBSBQ.V", value: 1709006, partition: 0 },
	{ label: "BOBQN.V", value: 4069788, partition: 0 },
	{ label: "AOIBT.V", value: 1447028, partition: 0 },
	{ label: "AGAFK.V", value: 1447029, partition: 0 },
	{ label: "AGSCX.V", value: 1447030, partition: 0 },
	{ label: "APSQH.V", value: 2889413, partition: 0 },
	{ label: "LRABG.V", value: 3151403, partition: 0 },
	{ label: "LR1PJ.V", value: 3151405, partition: 0 },
	{ label: "AKFGY.E", value: 70876, partition: 0 },
	{ label: "AZBBU.V", value: 661073, partition: 0 },
	{ label: "SCSAT.V", value: 399087, partition: 0 },
	{ label: "DASRR.V", value: 5250203, partition: 0 },
	{ label: "ABBDG.V", value: 1447047, partition: 0 },
	{ label: "AKFYE.E", value: 70886, partition: 0 },
	{ label: "BBSPT.V", value: 4199374, partition: 0 },
	{ label: "RMSAS.V", value: 399097, partition: 0 },
	{ label: "AEIRL.V", value: 2627444, partition: 0 },
	{ label: "ANIRI.V", value: 1447055, partition: 0 },
	{ label: "ASAVW.V", value: 2627445, partition: 0 },
	{ label: "CUIAO.V", value: 531534, partition: 0 },
	{ label: "AKGRT.E", value: 70896, partition: 0 },
	{ label: "AGAUR.V", value: 3937397, partition: 0 },
	{ label: "AGSQM.V", value: 3937398, partition: 0 },
	{ label: "AGBCY.V", value: 3937399, partition: 0 },
	{ label: "APBQW.V", value: 5379784, partition: 0 },
	{ label: "LR1PS.V", value: 5641773, partition: 0 },
	{ label: "AZSQL.V", value: 3151440, partition: 0 },
	{ label: "AGBDT.V", value: 16127095, partition: 0 },
	{ label: "DABDM.V", value: 7740574, partition: 0 },
	{ label: "ABARG.V", value: 3937416, partition: 0 },
	{ label: "ATSBP.V", value: 2627473, partition: 0 },
	{ label: "BMSCY.V", value: 1579521, partition: 0 },
	{ label: "ABSSF.V", value: 3937424, partition: 0 },
	{ label: "AB1AQ.V", value: 3937425, partition: 0 },
	{ label: "BABBP.V", value: 4331848, partition: 0 },
	{ label: "ASBDI.V", value: 5117815, partition: 0 },
	{ label: "AS1AU.V", value: 5117816, partition: 0 },
	{ label: "AGBSF.V", value: 6427767, partition: 0 },
	{ label: "AKMGY.E", value: 70936, partition: 0 },
	{ label: "AGBTA.V", value: 18617463, partition: 0 },
	{ label: "ABBDQ.V", value: 6427783, partition: 0 },
	{ label: "ATIQL.V", value: 5117840, partition: 0 },
	{ label: "AT1AU.V", value: 5117842, partition: 0 },
	{ label: "BR1PD.V", value: 1317562, partition: 0 },
	{ label: "ATAPR.V", value: 5117843, partition: 0 },
	{ label: "BMSQI.V", value: 4069889, partition: 0 },
	{ label: "BIIMO.V", value: 1841551, partition: 0 },
	{ label: "BIAEV.V", value: 1841552, partition: 0 },
	{ label: "AGBSM.V", value: 8918135, partition: 0 },
	{ label: "BI1AI.V", value: 1841557, partition: 0 },
	{ label: "DKIBM.V", value: 531613, partition: 0 },
	{ label: "DABCV.V", value: 531614, partition: 0 },
	{ label: "AKSA.E", value: 70976, partition: 0 },
	{ label: "MDSPO.V", value: 1709127, partition: 0 },
	{ label: "BR1PM.V", value: 3807930, partition: 0 },
	{ label: "ALIAX.V", value: 661186, partition: 0 },
	{ label: "LRIPU.V", value: 923180, partition: 0 },
	{ label: "APBBU.V", value: 661192, partition: 0 },
	{ label: "BIBDH.V", value: 4331922, partition: 0 },
	{ label: "DAAGB.V", value: 3021978, partition: 0 },
	{ label: "DASFJ.V", value: 3021979, partition: 0 },
	{ label: "BI1AS.V", value: 4331925, partition: 0 },
	{ label: "MDSPQ.V", value: 4199495, partition: 0 },
	{ label: "DBBQR.V", value: 5250331, partition: 0 },
	{ label: "BBSBS.V", value: 1971150, partition: 0 },
	{ label: "BOBBP.V", value: 4331932, partition: 0 },
	{ label: "AEICE.V", value: 399220, partition: 0 },
	{ label: "AKSEN.E", value: 71016, partition: 0 },
	{ label: "ASBCS.V", value: 399223, partition: 0 },
	{ label: "AS1AX.V", value: 399224, partition: 0 },
	{ label: "AOIBV.V", value: 1709172, partition: 0 },
	{ label: "AGAFM.V", value: 1709173, partition: 0 },
	{ label: "AGSDD.V", value: 1709174, partition: 0 },
	{ label: "APSQL.V", value: 3151557, partition: 0 },
	{ label: "LRABI.V", value: 3413547, partition: 0 },
	{ label: "LR1PL.V", value: 3413549, partition: 0 },
	{ label: "AZBQN.V", value: 923217, partition: 0 },
	{ label: "AKSGY.E", value: 71036, partition: 0 },
	{ label: "SCSAV.V", value: 661231, partition: 0 },
	{ label: "DASRT.V", value: 5512347, partition: 0 },
	{ label: "ABBDI.V", value: 1709191, partition: 0 },
	{ label: "ABABZ.V", value: 1709192, partition: 0 },
	{ label: "ATIBP.V", value: 399248, partition: 0 },
	{ label: "AT1AX.V", value: 399250, partition: 0 },
	{ label: "RMSAU.V", value: 661241, partition: 0 },
	{ label: "ASAGY.V", value: 2889589, partition: 0 },
	{ label: "ANICJ.V", value: 1709199, partition: 0 },
	{ label: "ASSFJ.V", value: 2889590, partition: 0 },
	{ label: "CUIPM.V", value: 793678, partition: 0 },
	{ label: "ASBCW.V", value: 2889591, partition: 0 },
	{ label: "AGSQO.V", value: 4199542, partition: 0 },
	{ label: "AKSUE.E", value: 71056, partition: 0 },
	{ label: "AGBDA.V", value: 4199543, partition: 0 },
	{ label: "LR1PU.V", value: 5903917, partition: 0 },
	{ label: "AKYHO.E", value: 71066, partition: 0 },
	{ label: "AGBDV.V", value: 16389239, partition: 0 },
	{ label: "DABSE.V", value: 8002718, partition: 0 },
	{ label: "ATSBR.V", value: 2889617, partition: 0 },
	{ label: "ALARK.E", value: 71076, partition: 0 },
	{ label: "ATAAK.V", value: 2889619, partition: 0 },
	{ label: "BMSDA.V", value: 1841665, partition: 0 },
	{ label: "ABSGJ.V", value: 4199568, partition: 0 },
	{ label: "ASSRL.V", value: 5379958, partition: 0 },
	{ label: "AB1PN.V", value: 4199569, partition: 0 },
	{ label: "BABBR.V", value: 4593992, partition: 0 },
	{ label: "AS1PR.V", value: 5379960, partition: 0 },
	{ label: "AGBDH.V", value: 6689911, partition: 0 },
	{ label: "ALBRK.E", value: 71096, partition: 0 },
	{ label: "AGBTC.V", value: 18879607, partition: 0 },
	{ label: "ABBDS.V", value: 6689927, partition: 0 },
	{ label: "ALBTS.F1", value: 71106, partition: 0 },
	{ label: "ATIQN.V", value: 5379984, partition: 0 },
	{ label: "ATSPQ.V", value: 5379985, partition: 0 },
	{ label: "AT1PR.V", value: 5379986, partition: 0 },
	{ label: "BR1AF.V", value: 1579706, partition: 0 },
	{ label: "ATAPT.V", value: 5379987, partition: 0 },
	{ label: "ALCAR.E", value: 71116, partition: 0 },
	{ label: "BIINB.V", value: 2103695, partition: 0 },
	{ label: "BIATS.V", value: 2103696, partition: 0 },
	{ label: "AGBSO.V", value: 9180279, partition: 0 },
	{ label: "BISFE.V", value: 2103697, partition: 0 },
	{ label: "BI1PF.V", value: 2103701, partition: 0 },
	{ label: "DKIQK.V", value: 793757, partition: 0 },
	{ label: "DABCX.V", value: 793758, partition: 0 },
	{ label: "DBBBK.V", value: 3022107, partition: 0 },
	{ label: "ALCTL.E", value: 71136, partition: 0 },
	{ label: "ALDUK.F1", value: 71146, partition: 0 },
	{ label: "ALIWY.V", value: 923330, partition: 0 },
	{ label: "ALFAS.E", value: 71151, partition: 0 },
	{ label: "LRIPW.V", value: 1185324, partition: 0 },
	{ label: "APBQN.V", value: 923336, partition: 0 },
	{ label: "ALGYO.E", value: 71156, partition: 0 },
	{ label: "BIBDJ.V", value: 4594066, partition: 0 },
	{ label: "DAAGD.V", value: 3284122, partition: 0 },
	{ label: "DASFL.V", value: 3284123, partition: 0 },
	{ label: "BI1PO.V", value: 4594069, partition: 0 },
	{ label: "BYDNR.E", value: 203598, partition: 0 },
	{ label: "BBSBU.V", value: 2233294, partition: 0 },
	{ label: "BOBBR.V", value: 4594076, partition: 0 },
	{ label: "AEICG.V", value: 661364, partition: 0 },
	{ label: "ASBCU.V", value: 661367, partition: 0 },
	{ label: "AS1AZ.V", value: 661368, partition: 0 },
	{ label: "ALKA.E", value: 71176, partition: 0 },
	{ label: "AOIQT.V", value: 1971316, partition: 0 },
	{ label: "AGAFO.V", value: 1971317, partition: 0 },
	{ label: "AGSDF.V", value: 1971318, partition: 0 },
	{ label: "LRAQF.V", value: 3675691, partition: 0 },
	{ label: "LR1AN.V", value: 3675693, partition: 0 },
	{ label: "ALTNY.E", value: 71186, partition: 0 },
	{ label: "AZSCV.V", value: 1185360, partition: 0 },
	{ label: "AZBQP.V", value: 1185361, partition: 0 },
	{ label: "SCSPL.V", value: 923375, partition: 0 },
	{ label: "DABRX.V", value: 5774494, partition: 0 },
	{ label: "ALKIM.E", value: 71196, partition: 0 },
	{ label: "ABACB.V", value: 1971336, partition: 0 },
	{ label: "ATIBR.V", value: 661392, partition: 0 },
	{ label: "AT1AZ.V", value: 661394, partition: 0 },
	{ label: "ALMAD.E", value: 71201, partition: 0 },
	{ label: "RMSPL.V", value: 923385, partition: 0 },
	{ label: "ASAHA.V", value: 3151733, partition: 0 },
	{ label: "ANICL.V", value: 1971343, partition: 0 },
	{ label: "ASSRJ.V", value: 3151734, partition: 0 },
	{ label: "CUIPO.V", value: 1055822, partition: 0 },
	{ label: "ASBCY.V", value: 3151735, partition: 0 },
	{ label: "ALONE.F1", value: 71206, partition: 0 },
	{ label: "AGSDI.V", value: 4461686, partition: 0 },
	{ label: "AGBDC.V", value: 4461687, partition: 0 },
	{ label: "ALTIN.S1", value: 71216, partition: 0 },
	{ label: "AGBDX.V", value: 16651383, partition: 0 },
	{ label: "ALVES.E", value: 71226, partition: 0 },
	{ label: "DABSG.V", value: 8264862, partition: 0 },
	{ label: "ATSBT.V", value: 3151761, partition: 0 },
	{ label: "ATAAM.V", value: 3151763, partition: 0 },
	{ label: "BMSQJ.V", value: 2103809, partition: 0 },
	{ label: "ABSSJ.V", value: 4461712, partition: 0 },
	{ label: "AB1PP.V", value: 4461713, partition: 0 },
	{ label: "BABQO.V", value: 4856136, partition: 0 },
	{ label: "AS1PT.V", value: 5642104, partition: 0 },
	{ label: "AGBDJ.V", value: 6952055, partition: 0 },
	{ label: "CEOEM.E", value: 203692, partition: 0 },
	{ label: "ABBQR.V", value: 6952071, partition: 0 },
	{ label: "AT1PT.V", value: 5642130, partition: 0 },
	{ label: "BR1AH.V", value: 1841850, partition: 0 },
	{ label: "ASBDK.V", value: 8132471, partition: 0 },
	{ label: "BIIQJ.V", value: 2365839, partition: 0 },
	{ label: "ANELE.E", value: 71276, partition: 0 },
	{ label: "AGBSQ.V", value: 9442423, partition: 0 },
	{ label: "BISFI.V", value: 2365841, partition: 0 },
	{ label: "BI1PH.V", value: 2365845, partition: 0 },
	{ label: "DKIQM.V", value: 1055901, partition: 0 },
	{ label: "DABCZ.V", value: 1055902, partition: 0 },
	{ label: "DBBBM.V", value: 3284251, partition: 0 },
	{ label: "ALIAZ.V", value: 1185474, partition: 0 },
	{ label: "APSCV.V", value: 1185477, partition: 0 },
	{ label: "ANGEN.E", value: 71306, partition: 0 },
	{ label: "LRIPY.V", value: 1447468, partition: 0 },
	{ label: "APBQP.V", value: 1185480, partition: 0 },
	{ label: "DAAVA.V", value: 3546266, partition: 0 },
	{ label: "DASFN.V", value: 3546267, partition: 0 },
	{ label: "BI1PQ.V", value: 4856213, partition: 0 },
	{ label: "ANHYT.E", value: 71316, partition: 0 },
	{ label: "BBSBW.V", value: 2495438, partition: 0 },
	{ label: "BOBQO.V", value: 4856220, partition: 0 },
	{ label: "AEIRD.V", value: 923508, partition: 0 },
	{ label: "ASSFE.V", value: 923510, partition: 0 },
	{ label: "AS1PV.V", value: 923512, partition: 0 },
	{ label: "AOIQV.V", value: 2233460, partition: 0 },
	{ label: "AGAUK.V", value: 2233461, partition: 0 },
	{ label: "LRAQH.V", value: 3937835, partition: 0 },
	{ label: "LR1AP.V", value: 3937837, partition: 0 },
	{ label: "AZSCX.V", value: 1447504, partition: 0 },
	{ label: "AZBQR.V", value: 1447505, partition: 0 },
	{ label: "CTIEN.V", value: 1317951, partition: 0 },
	{ label: "ARASE.E", value: 71346, partition: 0 },
	{ label: "SCSAY.V", value: 1185519, partition: 0 },
	{ label: "DABRZ.V", value: 6036638, partition: 0 },
	{ label: "ABAQY.V", value: 2233480, partition: 0 },
	{ label: "ATIQO.V", value: 923536, partition: 0 },
	{ label: "AT1PV.V", value: 923538, partition: 0 },
	{ label: "ANSGR.E", value: 71356, partition: 0 },
	{ label: "RMSAX.V", value: 1185529, partition: 0 },
	{ label: "ANIRJ.V", value: 2233487, partition: 0 },
	{ label: "ASAHC.V", value: 3413877, partition: 0 },
	{ label: "ABSFY.V", value: 2233488, partition: 0 },
	{ label: "ASSFR.V", value: 3413878, partition: 0 },
	{ label: "ASBDA.V", value: 3413879, partition: 0 },
	{ label: "AS1AM.V", value: 3413880, partition: 0 },
	{ label: "AGSDK.V", value: 4723830, partition: 0 },
	{ label: "AGBDE.V", value: 4723831, partition: 0 },
	{ label: "APYKS.F2", value: 71369, partition: 0 },
	{ label: "APYKF.F2", value: 71370, partition: 0 },
	{ label: "APYVC.F2", value: 71371, partition: 0 },
	{ label: "APBDL.F", value: 71372, partition: 0 },
	{ label: "APLIB.F", value: 71373, partition: 0 },
	{ label: "APX30.F", value: 71374, partition: 0 },
	{ label: "APYMD.F2", value: 71375, partition: 0 },
	{ label: "ARCLK.E", value: 71376, partition: 0 },
	{ label: "AGBDZ.V", value: 16913527, partition: 0 },
	{ label: "CONSE.E", value: 203813, partition: 0 },
	{ label: "DABSI.V", value: 8527006, partition: 0 },
	{ label: "ATSBV.V", value: 3413905, partition: 0 },
	{ label: "AT1AM.V", value: 3413906, partition: 0 },
	{ label: "ATAAO.V", value: 3413907, partition: 0 },
	{ label: "ABSSL.V", value: 4723856, partition: 0 },
	{ label: "AB1AR.V", value: 4723857, partition: 0 },
	{ label: "BABQQ.V", value: 5118280, partition: 0 },
	{ label: "AS1PY.V", value: 5904248, partition: 0 },
	{ label: "ARENA.E", value: 71396, partition: 0 },
	{ label: "AGBDL.V", value: 7214199, partition: 0 },
	{ label: "ABBQT.V", value: 7214215, partition: 0 },
	{ label: "AT1PY.V", value: 5904274, partition: 0 },
	{ label: "BR1AJ.V", value: 2103994, partition: 0 },
	{ label: "ASBPQ.V", value: 8394615, partition: 0 },
	{ label: "BIIQL.V", value: 2627983, partition: 0 },
	{ label: "BIATU.V", value: 2627984, partition: 0 },
	{ label: "BISFK.V", value: 2627985, partition: 0 },
	{ label: "BI1AJ.V", value: 2627989, partition: 0 },
	{ label: "DKIBO.V", value: 1318045, partition: 0 },
	{ label: "DABDB.V", value: 1318046, partition: 0 },
	{ label: "DBBQJ.V", value: 3546395, partition: 0 },
	{ label: "ARSAN.E", value: 71456, partition: 0 },
	{ label: "ALIXA.V", value: 1447618, partition: 0 },
	{ label: "APSCX.V", value: 1447621, partition: 0 },
	{ label: "LRABA.V", value: 1709611, partition: 0 },
	{ label: "LRIBA.V", value: 1709612, partition: 0 },
	{ label: "APBQR.V", value: 1447624, partition: 0 },
	{ label: "DAAVC.V", value: 3808410, partition: 0 },
	{ label: "BBSBY.V", value: 2757582, partition: 0 },
	{ label: "BOBQQ.V", value: 5118364, partition: 0 },
	{ label: "AEIRF.V", value: 1185652, partition: 0 },
	{ label: "ASSFG.V", value: 1185654, partition: 0 },
	{ label: "AS1PX.V", value: 1185656, partition: 0 },
	{ label: "LR1AR.V", value: 4199981, partition: 0 },
	{ label: "AZSCZ.V", value: 1709648, partition: 0 },
	{ label: "AZBBX.V", value: 1709649, partition: 0 },
	{ label: "CTIFA.V", value: 1580095, partition: 0 },
	{ label: "SCSBA.V", value: 1447663, partition: 0 },
	{ label: "DABSB.V", value: 6298782, partition: 0 },
	{ label: "ABARA.V", value: 2495624, partition: 0 },
	{ label: "ARZUM.E", value: 71506, partition: 0 },
	{ label: "ATIQQ.V", value: 1185680, partition: 0 },
	{ label: "AT1PX.V", value: 1185682, partition: 0 },
	{ label: "RMSAZ.V", value: 1447673, partition: 0 },
	{ label: "ANIRL.V", value: 2495631, partition: 0 },
	{ label: "ASAVZ.V", value: 3676021, partition: 0 },
	{ label: "ABSGA.V", value: 2495632, partition: 0 },
	{ label: "ASSRN.V", value: 3676022, partition: 0 },
	{ label: "BABBJ.V", value: 2890056, partition: 0 },
	{ label: "ASBPL.V", value: 3676023, partition: 0 },
	{ label: "AS1AO.V", value: 3676024, partition: 0 },
	{ label: "AGSQS.V", value: 4985974, partition: 0 },
	{ label: "AGBRU.V", value: 4985975, partition: 0 },
	{ label: "AZSQJ.V", value: 4200016, partition: 0 },
	{ label: "AGBEB.V", value: 17175671, partition: 0 },
	{ label: "ASELS.E", value: 71536, partition: 0 },
	{ label: "DABSK.V", value: 8789150, partition: 0 },
	{ label: "ABBQM.V", value: 4985991, partition: 0 },
	{ label: "ATSPS.V", value: 3676049, partition: 0 },
	{ label: "AT1AO.V", value: 3676050, partition: 0 },
	{ label: "ATAPL.V", value: 3676051, partition: 0 },
	{ label: "RMSPM.V", value: 3938041, partition: 0 },
	{ label: "ASGYO.E", value: 71546, partition: 0 },
	{ label: "ABSSN.V", value: 4986000, partition: 0 },
	{ label: "BABQS.V", value: 5380424, partition: 0 },
	{ label: "AB1AT.V", value: 4986001, partition: 0 },
	{ label: "BIIMI.V", value: 399759, partition: 0 },
	{ label: "AGBDN.V", value: 7476343, partition: 0 },
	{ label: "BIBCS.V", value: 399762, partition: 0 },
	{ label: "MDSAR.V", value: 267335, partition: 0 },
	{ label: "ASTOR.E", value: 71566, partition: 0 },
	{ label: "BR1PG.V", value: 2366138, partition: 0 },
	{ label: "ASUZU.E", value: 71576, partition: 0 },
	{ label: "ABSSI.V", value: 7476368, partition: 0 },
	{ label: "ASBPS.V", value: 8656759, partition: 0 },
	{ label: "BIAEW.V", value: 2890128, partition: 0 },
	{ label: "BISFF.V", value: 2890129, partition: 0 },
	{ label: "BIBCW.V", value: 2890130, partition: 0 },
	{ label: "DAAFV.V", value: 1580186, partition: 0 },
	{ label: "DASEY.V", value: 1580187, partition: 0 },
	{ label: "BI1AL.V", value: 2890133, partition: 0 },
	{ label: "DKIBQ.V", value: 1580189, partition: 0 },
	{ label: "DABRT.V", value: 1580190, partition: 0 },
	{ label: "DBBQL.V", value: 3808539, partition: 0 },
	{ label: "BOBBJ.V", value: 2890140, partition: 0 },
	{ label: "AOIBO.V", value: 267380, partition: 0 },
	{ label: "ALIBQ.V", value: 1709762, partition: 0 },
	{ label: "APSCZ.V", value: 1709765, partition: 0 },
	{ label: "LRABC.V", value: 1971755, partition: 0 },
	{ label: "LRIBC.V", value: 1971756, partition: 0 },
	{ label: "APBBX.V", value: 1709768, partition: 0 },
	{ label: "DAAVE.V", value: 4070554, partition: 0 },
	{ label: "ABBCZ.V", value: 267399, partition: 0 },
	{ label: "BBSPV.V", value: 3019726, partition: 0 },
	{ label: "BOBQS.V", value: 5380508, partition: 0 },
	{ label: "AEIRH.V", value: 1447796, partition: 0 },
	{ label: "ASSFI.V", value: 1447798, partition: 0 },
	{ label: "ATAGY.E", value: 71636, partition: 0 },
	{ label: "AB1AW.V", value: 267409, partition: 0 },
	{ label: "ATAKP.E", value: 71641, partition: 0 },
	{ label: "AGAUM.V", value: 2757749, partition: 0 },
	{ label: "ATATP.E", value: 71646, partition: 0 },
	{ label: "APSQJ.V", value: 4200133, partition: 0 },
	{ label: "LR1PO.V", value: 4462125, partition: 0 },
	{ label: "AZSDB.V", value: 1971792, partition: 0 },
	{ label: "CTIPJ.V", value: 1842239, partition: 0 },
	{ label: "SCSPO.V", value: 1709807, partition: 0 },
	{ label: "ATEKS.E", value: 71656, partition: 0 },
	{ label: "DABDD.V", value: 6560926, partition: 0 },
	{ label: "ABARC.V", value: 2757768, partition: 0 },
	{ label: "ATIQS.V", value: 1447824, partition: 0 },
	{ label: "RMSPO.V", value: 1709817, partition: 0 },
	{ label: "ANIRN.V", value: 2757775, partition: 0 },
	{ label: "ASAWB.V", value: 3938165, partition: 0 },
	{ label: "ABSGC.V", value: 2757776, partition: 0 },
	{ label: "BABBL.V", value: 3152200, partition: 0 },
	{ label: "ASBPN.V", value: 3938167, partition: 0 },
	{ label: "AS1AQ.V", value: 3938168, partition: 0 },
	{ label: "AGSQU.V", value: 5248118, partition: 0 },
	{ label: "ATLAS.E", value: 71676, partition: 0 },
	{ label: "AGBRW.V", value: 5248119, partition: 0 },
	{ label: "AZBBY.V", value: 4462161, partition: 0 },
	{ label: "AGBED.V", value: 17437815, partition: 0 },
	{ label: "SCSPN.V", value: 4200175, partition: 0 },
	{ label: "DABSM.V", value: 9051294, partition: 0 },
	{ label: "ABBQO.V", value: 5248135, partition: 0 },
	{ label: "AT1AQ.V", value: 3938194, partition: 0 },
	{ label: "ATAPN.V", value: 3938195, partition: 0 },
	{ label: "RMSPQ.V", value: 4200185, partition: 0 },
	{ label: "BMSQK.V", value: 2890241, partition: 0 },
	{ label: "AB1AV.V", value: 5248145, partition: 0 },
	{ label: "BIIMK.V", value: 661903, partition: 0 },
	{ label: "AGBDP.V", value: 7738487, partition: 0 },
	{ label: "BIBCU.V", value: 661906, partition: 0 },
	{ label: "ATSYH.E", value: 71716, partition: 0 },
	{ label: "MDSAT.V", value: 529479, partition: 0 },
	{ label: "AUODK.F1", value: 71722, partition: 0 },
	{ label: "BR1PI.V", value: 2628282, partition: 0 },
	{ label: "AVGYO.E", value: 71736, partition: 0 },
	{ label: "BIAEY.V", value: 3152272, partition: 0 },
	{ label: "BISRD.V", value: 3152273, partition: 0 },
	{ label: "BIBCY.V", value: 3152274, partition: 0 },
	{ label: "DAAFX.V", value: 1842330, partition: 0 },
	{ label: "DASFA.V", value: 1842331, partition: 0 },
	{ label: "AVHOL.E", value: 71746, partition: 0 },
	{ label: "BI1AN.V", value: 3152277, partition: 0 },
	{ label: "DKIQO.V", value: 1842333, partition: 0 },
	{ label: "DABRV.V", value: 1842334, partition: 0 },
	{ label: "DBBQN.V", value: 4070683, partition: 0 },
	{ label: "BOBBL.V", value: 3152284, partition: 0 },
	{ label: "ADAAA.C", value: 267529, partition: 0 },
	{ label: "AIZAA.C", value: 267530, partition: 0 },
	{ label: "AOIBQ.V", value: 529524, partition: 0 },
	{ label: "ALIXC.V", value: 1971906, partition: 0 },
	{ label: "APSDB.V", value: 1971909, partition: 0 },
	{ label: "LRAPZ.V", value: 2233899, partition: 0 },
	{ label: "LRIPZ.V", value: 2233900, partition: 0 },
	{ label: "AVOD.E", value: 71776, partition: 0 },
	{ label: "AEICJ.V", value: 1709940, partition: 0 },
	{ label: "ASAGU.V", value: 1709941, partition: 0 },
	{ label: "ANICF.V", value: 529551, partition: 0 },
	{ label: "ASSFM.V", value: 1709942, partition: 0 },
	{ label: "AVPGY.E", value: 71791, partition: 0 },
	{ label: "AB1AY.V", value: 529553, partition: 0 },
	{ label: "AVTUR.E", value: 71796, partition: 0 },
	{ label: "AGAFP.V", value: 3019893, partition: 0 },
	{ label: "LR1PQ.V", value: 4724269, partition: 0 },
	{ label: "APBBY.V", value: 4462280, partition: 0 },
	{ label: "AZSDD.V", value: 2233936, partition: 0 },
	{ label: "CTIPL.V", value: 2104383, partition: 0 },
	{ label: "DABDF.V", value: 6823070, partition: 0 },
	{ label: "ABACE.V", value: 3019912, partition: 0 },
	{ label: "AYCES.E", value: 71816, partition: 0 },
	{ label: "ATSBJ.V", value: 1709969, partition: 0 },
	{ label: "ABSGE.V", value: 3019920, partition: 0 },
	{ label: "BABBN.V", value: 3414344, partition: 0 },
	{ label: "ASBDB.V", value: 4200311, partition: 0 },
	{ label: "AS1PN.V", value: 4200312, partition: 0 },
	{ label: "AYDEM.E", value: 71826, partition: 0 },
	{ label: "AGBRY.V", value: 5510263, partition: 0 },
	{ label: "AYEN.E", value: 71836, partition: 0 },
	{ label: "AZBCA.V", value: 4724305, partition: 0 },
	{ label: "AGBST.V", value: 17699959, partition: 0 },
	{ label: "ABBDJ.V", value: 5510279, partition: 0 },
	{ label: "ATIBJ.V", value: 4200336, partition: 0 },
	{ label: "BR1AB.V", value: 400058, partition: 0 },
	{ label: "AT1PN.V", value: 4200338, partition: 0 },
	{ label: "ATAAP.V", value: 4200339, partition: 0 },
	{ label: "AYES.E", value: 71856, partition: 0 },
	{ label: "AB1PS.V", value: 5510289, partition: 0 },
	{ label: "BIIQD.V", value: 924047, partition: 0 },
	{ label: "AGSQQ.V", value: 8000630, partition: 0 },
	{ label: "AGBDR.V", value: 8000631, partition: 0 },
	{ label: "BISFA.V", value: 924049, partition: 0 },
	{ label: "MDSAV.V", value: 791623, partition: 0 },
	{ label: "AYGAZ.E", value: 71876, partition: 0 },
	{ label: "BR1AL.V", value: 2890426, partition: 0 },
	{ label: "AZTEK.E", value: 71886, partition: 0 },
	{ label: "BAGFS.E", value: 71896, partition: 0 },
	{ label: "BIAFA.V", value: 3414416, partition: 0 },
	{ label: "BISFM.V", value: 3414417, partition: 0 },
	{ label: "BIBDA.V", value: 3414418, partition: 0 },
	{ label: "DAAFZ.V", value: 2104474, partition: 0 },
	{ label: "DASFC.V", value: 2104475, partition: 0 },
	{ label: "BI1PK.V", value: 3414421, partition: 0 },
	{ label: "DKIQQ.V", value: 2104477, partition: 0 },
	{ label: "DBBBP.V", value: 4332827, partition: 0 },
	{ label: "BOBBN.V", value: 3414428, partition: 0 },
	{ label: "BAKAB.E", value: 71916, partition: 0 },
	{ label: "AOIQO.V", value: 791668, partition: 0 },
	{ label: "ALIXE.V", value: 2234050, partition: 0 },
	{ label: "APSDD.V", value: 2234053, partition: 0 },
	{ label: "LRAQB.V", value: 2496043, partition: 0 },
	{ label: "LRIQB.V", value: 2496044, partition: 0 },
	{ label: "LR1AJ.V", value: 2496045, partition: 0 },
	{ label: "DASRJ.V", value: 4594843, partition: 0 },
	{ label: "ABBDB.V", value: 791687, partition: 0 },
	{ label: "AEICL.V", value: 1972084, partition: 0 },
	{ label: "ANICH.V", value: 791695, partition: 0 },
	{ label: "ASAGW.V", value: 1972085, partition: 0 },
	{ label: "ASSFO.V", value: 1972086, partition: 0 },
	{ label: "AB1BA.V", value: 791697, partition: 0 },
	{ label: "AGAFR.V", value: 3282037, partition: 0 },
	{ label: "AGSCZ.V", value: 3282038, partition: 0 },
	{ label: "BALAT.E", value: 71956, partition: 0 },
	{ label: "APBCA.V", value: 4724424, partition: 0 },
	{ label: "LR1AT.V", value: 4986413, partition: 0 },
	{ label: "DABDH.V", value: 7085214, partition: 0 },
	{ label: "ABACG.V", value: 3282056, partition: 0 },
	{ label: "ATSBL.V", value: 1972113, partition: 0 },
	{ label: "GUSBC.V", value: 4727325, partition: 0 },
	{ label: "GOSDA.V", value: 1713013, partition: 0 },
	{ label: "HKTM.E", value: 74866, partition: 0 },
	{ label: "GFASX.V", value: 3941363, partition: 0 },
	{ label: "ICIER.V", value: 665062, partition: 0 },
	{ label: "GFBDE.V", value: 3941364, partition: 0 },
	{ label: "EIIBX.V", value: 794618, partition: 0 },
	{ label: "GF1AP.V", value: 3941365, partition: 0 },
	{ label: "GFIPN.V", value: 3941366, partition: 0 },
	{ label: "EXBDJ.V", value: 7741647, partition: 0 },
	{ label: "EISBG.V", value: 794620, partition: 0 },
	{ label: "HOROZ.E", value: 74871, partition: 0 },
	{ label: "ICBCZ.V", value: 665066, partition: 0 },
	{ label: "FRATL.V", value: 2498990, partition: 0 },
	{ label: "FRSFE.V", value: 2498991, partition: 0 },
	{ label: "FRITL.V", value: 2498992, partition: 0 },
	{ label: "HLGYO.E", value: 74876, partition: 0 },
	{ label: "FR1PI.V", value: 2498994, partition: 0 },
	{ label: "HRKET.E", value: 74879, partition: 0 },
	{ label: "HTTBT.E", value: 74882, partition: 0 },
	{ label: "HUBVC.E", value: 74886, partition: 0 },
	{ label: "GOSQI.V", value: 4203381, partition: 0 },
	{ label: "HURGZ.E", value: 74896, partition: 0 },
	{ label: "GCBBO.V", value: 4203383, partition: 0 },
	{ label: "EIACL.V", value: 3284985, partition: 0 },
	{ label: "IBAID.V", value: 3155432, partition: 0 },
	{ label: "EIBCZ.V", value: 3284987, partition: 0 },
	{ label: "EISAY.V", value: 3284988, partition: 0 },
	{ label: "EI1PJ.V", value: 3284989, partition: 0 },
	{ label: "IS1PJ.V", value: 3155435, partition: 0 },
	{ label: "GRAXE.V", value: 2499035, partition: 0 },
	{ label: "GASFX.V", value: 2499036, partition: 0 },
	{ label: "EYIRF.V", value: 1056658, partition: 0 },
	{ label: "DJIJL.V", value: 1580637, partition: 0 },
	{ label: "GA1AJ.V", value: 2499038, partition: 0 },
	{ label: "DJSEL.V", value: 1580638, partition: 0 },
	{ label: "EKSCX.V", value: 1056661, partition: 0 },
	{ label: "EYBDB.V", value: 1056662, partition: 0 },
	{ label: "EK1PW.V", value: 1056663, partition: 0 },
	{ label: "XOSAT.V", value: 532686, partition: 0 },
	{ label: "ELICA.V", value: 794675, partition: 0 },
	{ label: "ICBCT.E", value: 74926, partition: 0 },
	{ label: "EXBCS.V", value: 532687, partition: 0 },
	{ label: "EXICW.V", value: 532689, partition: 0 },
	{ label: "EDBCZ.V", value: 794679, partition: 0 },
	{ label: "ICUGS.E", value: 74931, partition: 0 },
	{ label: "HEISV.V", value: 1451095, partition: 0 },
	{ label: "EUBBK.V", value: 532697, partition: 0 },
	{ label: "ICBDN.V", value: 5645802, partition: 0 },
	{ label: "IS1PS.V", value: 5645803, partition: 0 },
	{ label: "EXSBD.V", value: 532702, partition: 0 },
	{ label: "EXGPA.V", value: 532707, partition: 0 },
	{ label: "GABDJ.V", value: 4989405, partition: 0 },
	{ label: "GA1AT.V", value: 4989406, partition: 0 },
	{ label: "EKAWQ.V", value: 3547028, partition: 0 },
	{ label: "DJBBS.V", value: 4071007, partition: 0 },
	{ label: "EKSQC.V", value: 3547029, partition: 0 },
	{ label: "IDGYO.E", value: 74956, partition: 0 },
	{ label: "EK1AN.V", value: 3547031, partition: 0 },
	{ label: "EXBCL.V", value: 3023055, partition: 0 },
	{ label: "EDAHJ.V", value: 3285044, partition: 0 },
	{ label: "EDSGE.V", value: 3285046, partition: 0 },
	{ label: "EDATA.E", value: 204518, partition: 0 },
	{ label: "ER1PK.V", value: 3285048, partition: 0 },
	{ label: "HEATM.V", value: 3941462, partition: 0 },
	{ label: "HEBDE.V", value: 3941464, partition: 0 },
	{ label: "EUSQT.V", value: 3023064, partition: 0 },
	{ label: "HE1AQ.V", value: 3941466, partition: 0 },
	{ label: "GNIYL.V", value: 1384919, partition: 0 },
	{ label: "IEYHO.E", value: 74976, partition: 0 },
	{ label: "GUBBD.V", value: 2499100, partition: 0 },
	{ label: "GFADQ.V", value: 1713139, partition: 0 },
	{ label: "EYBQI.V", value: 6037398, partition: 0 },
	{ label: "GFIAG.V", value: 1713142, partition: 0 },
	{ label: "EXBRS.V", value: 5513423, partition: 0 },
	{ label: "EDBDP.V", value: 5775415, partition: 0 },
	{ label: "ER1PT.V", value: 5775416, partition: 0 },
	{ label: "IHEVA.E", value: 74996, partition: 0 },
	{ label: "FRIEN.V", value: 270768, partition: 0 },
	{ label: "FRBCV.V", value: 270769, partition: 0 },
	{ label: "GUSPM.V", value: 4989469, partition: 0 },
	{ label: "IHGZT.E", value: 75016, partition: 0 },
	{ label: "GOSDC.V", value: 1975157, partition: 0 },
	{ label: "GFBDG.V", value: 4203508, partition: 0 },
	{ label: "ICITO.V", value: 927206, partition: 0 },
	{ label: "GF1AR.V", value: 4203509, partition: 0 },
	{ label: "EIIQU.V", value: 1056762, partition: 0 },
	{ label: "EXBSB.V", value: 8003791, partition: 0 },
	{ label: "EISPU.V", value: 1056764, partition: 0 },
	{ label: "ICBDB.V", value: 927210, partition: 0 },
	{ label: "FRATN.V", value: 2761134, partition: 0 },
	{ label: "FRSFG.V", value: 2761135, partition: 0 },
	{ label: "FRITN.V", value: 2761136, partition: 0 },
	{ label: "FR1AK.V", value: 2761138, partition: 0 },
	{ label: "IHLAS.E", value: 75036, partition: 0 },
	{ label: "EGEPO.E", value: 204593, partition: 0 },
	{ label: "GABCT.V", value: 270813, partition: 0 },
	{ label: "IHLGM.E", value: 75046, partition: 0 },
	{ label: "GCBBQ.V", value: 4465527, partition: 0 },
	{ label: "EIARI.V", value: 3547129, partition: 0 },
	{ label: "IBAIF.V", value: 3417576, partition: 0 },
	{ label: "EIBDB.V", value: 3547131, partition: 0 },
	{ label: "ISSDN.V", value: 3417577, partition: 0 },
	{ label: "EISBA.V", value: 3547132, partition: 0 },
	{ label: "EI1PL.V", value: 3547133, partition: 0 },
	{ label: "IS1PL.V", value: 3417579, partition: 0 },
	{ label: "GRAXG.V", value: 2761179, partition: 0 },
	{ label: "GASRX.V", value: 2761180, partition: 0 },
	{ label: "EYIRH.V", value: 1318802, partition: 0 },
	{ label: "GABCX.V", value: 2761181, partition: 0 },
	{ label: "DJIVG.V", value: 1842781, partition: 0 },
	{ label: "GA1AL.V", value: 2761182, partition: 0 },
	{ label: "IHYAY.E", value: 75076, partition: 0 },
	{ label: "DJSQY.V", value: 1842782, partition: 0 },
	{ label: "EKSCZ.V", value: 1318805, partition: 0 },
	{ label: "XOSAV.V", value: 794830, partition: 0 },
	{ label: "ELICD.V", value: 1056819, partition: 0 },
	{ label: "EXBCU.V", value: 794831, partition: 0 },
	{ label: "EXIXT.V", value: 794833, partition: 0 },
	{ label: "EDSFX.V", value: 1056822, partition: 0 },
	{ label: "EDBDB.V", value: 1056823, partition: 0 },
	{ label: "IMASM.E", value: 75086, partition: 0 },
	{ label: "HEAEF.V", value: 1713238, partition: 0 },
	{ label: "HEIDO.V", value: 1713239, partition: 0 },
	{ label: "HESBB.V", value: 1713241, partition: 0 },
	{ label: "EUBQE.V", value: 794841, partition: 0 },
	{ label: "ICBDP.V", value: 5907946, partition: 0 },
	{ label: "IS1PU.V", value: 5907947, partition: 0 },
	{ label: "INDES.E", value: 75096, partition: 0 },
	{ label: "EXSBF.V", value: 794846, partition: 0 },
	{ label: "GUSAW.V", value: 270877, partition: 0 },
	{ label: "GUGAA.V", value: 270878, partition: 0 },
	{ label: "GABDL.V", value: 5251549, partition: 0 },
	{ label: "GA1AV.V", value: 5251550, partition: 0 },
	{ label: "EKAWS.V", value: 3809172, partition: 0 },
	{ label: "DJSQZ.V", value: 4333150, partition: 0 },
	{ label: "EKSQE.V", value: 3809173, partition: 0 },
	{ label: "DJBBU.V", value: 4333151, partition: 0 },
	{ label: "EYBDD.V", value: 3809174, partition: 0 },
	{ label: "EK1AP.V", value: 3809175, partition: 0 },
	{ label: "EDAWH.V", value: 3547188, partition: 0 },
	{ label: "EXBCN.V", value: 3285199, partition: 0 },
	{ label: "EDSGK.V", value: 3547190, partition: 0 },
	{ label: "INFO.E", value: 75116, partition: 0 },
	{ label: "ER1PM.V", value: 3547192, partition: 0 },
	{ label: "HEBDG.V", value: 4203608, partition: 0 },
	{ label: "HESPP.V", value: 4203609, partition: 0 },
	{ label: "EUSQW.V", value: 3285208, partition: 0 },
	{ label: "HE1PN.V", value: 4203610, partition: 0 },
	{ label: "INGRM.E", value: 75126, partition: 0 },
	{ label: "GNIKK.V", value: 1647063, partition: 0 },
	{ label: "EYICD.V", value: 204690, partition: 0 },
	{ label: "INTEM.E", value: 75136, partition: 0 },
	{ label: "GUBBF.V", value: 2761244, partition: 0 },
	{ label: "GFADS.V", value: 1975283, partition: 0 },
	{ label: "EYBQK.V", value: 6299542, partition: 0 },
	{ label: "INVEO.E", value: 75146, partition: 0 },
	{ label: "GFIAI.V", value: 1975286, partition: 0 },
	{ label: "EXBRU.V", value: 5775567, partition: 0 },
	{ label: "EKOS.E", value: 204703, partition: 0 },
	{ label: "EDBQI.V", value: 6037559, partition: 0 },
	{ label: "FRIEP.V", value: 532912, partition: 0 },
	{ label: "FRBCX.V", value: 532913, partition: 0 },
	{ label: "IPEKE.E", value: 75156, partition: 0 },
	{ label: "GUSPP.V", value: 5251613, partition: 0 },
	{ label: "ISATR.E", value: 75176, partition: 0 },
	{ label: "GFBDI.V", value: 4465652, partition: 0 },
	{ label: "ICITQ.V", value: 1189350, partition: 0 },
	{ label: "GF1PO.V", value: 4465653, partition: 0 },
	{ label: "EIIQW.V", value: 1318906, partition: 0 },
	{ label: "EXBSD.V", value: 8265935, partition: 0 },
	{ label: "ISSDI.V", value: 1189353, partition: 0 },
	{ label: "ICBDD.V", value: 1189354, partition: 0 },
	{ label: "EI1AE.V", value: 1318909, partition: 0 },
	{ label: "FRAEP.V", value: 3023278, partition: 0 },
	{ label: "FRSFB.V", value: 3023279, partition: 0 },
	{ label: "FR1AM.V", value: 3023282, partition: 0 },
	{ label: "ISBIR.E", value: 75196, partition: 0 },
	{ label: "GABCV.V", value: 532957, partition: 0 },
	{ label: "GCBBS.V", value: 4727671, partition: 0 },
	{ label: "EIARK.V", value: 3809273, partition: 0 },
	{ label: "IBAWX.V", value: 3679720, partition: 0 },
	{ label: "EIBDD.V", value: 3809275, partition: 0 },
	{ label: "ISSQK.V", value: 3679721, partition: 0 },
	{ label: "EISPQ.V", value: 3809276, partition: 0 },
	{ label: "EI1AO.V", value: 3809277, partition: 0 },
	{ label: "IS1AN.V", value: 3679723, partition: 0 },
	{ label: "ISBTR.E", value: 75216, partition: 0 },
	{ label: "ENSRI.E", value: 204773, partition: 0 },
	{ label: "GRAII.V", value: 3023323, partition: 0 },
	{ label: "GASGA.V", value: 3023324, partition: 0 },
	{ label: "EYICI.V", value: 1580946, partition: 0 },
	{ label: "GABCZ.V", value: 3023325, partition: 0 },
	{ label: "DJIVI.V", value: 2104925, partition: 0 },
	{ label: "GA1PI.V", value: 3023326, partition: 0 },
	{ label: "EKAHL.V", value: 1580948, partition: 0 },
	{ label: "DJSEN.V", value: 2104926, partition: 0 },
	{ label: "EKSDD.V", value: 1580949, partition: 0 },
	{ label: "XOSAW.V", value: 1056974, partition: 0 },
	{ label: "ELICF.V", value: 1318963, partition: 0 },
	{ label: "ISCTR.E", value: 75236, partition: 0 },
	{ label: "EXBCW.V", value: 1056975, partition: 0 },
	{ label: "EXIXV.V", value: 1056977, partition: 0 },
	{ label: "EDSFZ.V", value: 1318966, partition: 0 },
	{ label: "EDBDD.V", value: 1318967, partition: 0 },
	{ label: "HEAEH.V", value: 1975382, partition: 0 },
	{ label: "HEIDQ.V", value: 1975383, partition: 0 },
	{ label: "HESBD.V", value: 1975385, partition: 0 },
	{ label: "ISDMR.E", value: 75246, partition: 0 },
	{ label: "EUBQG.V", value: 1056985, partition: 0 },
	{ label: "ICBQJ.V", value: 6170090, partition: 0 },
	{ label: "EXSBH.V", value: 1056990, partition: 0 },
	{ label: "ISFIN.E", value: 75256, partition: 0 },
	{ label: "GUSAY.V", value: 533021, partition: 0 },
	{ label: "GUGPA.V", value: 533022, partition: 0 },
	{ label: "GA1PR.V", value: 5513694, partition: 0 },
	{ label: "DJBQR.V", value: 4595295, partition: 0 },
	{ label: "EYBQD.V", value: 4071318, partition: 0 },
	{ label: "EK1PM.V", value: 4071319, partition: 0 },
	{ label: "EXBCP.V", value: 3547343, partition: 0 },
	{ label: "EDAWJ.V", value: 3809332, partition: 0 },
	{ label: "EDSSH.V", value: 3809334, partition: 0 },
	{ label: "EDBDF.V", value: 3809335, partition: 0 },
	{ label: "ER1AO.V", value: 3809336, partition: 0 },
	{ label: "ISGSY.E", value: 75276, partition: 0 },
	{ label: "HEBDI.V", value: 4465752, partition: 0 },
	{ label: "HE1PP.V", value: 4465754, partition: 0 },
	{ label: "EUBBP.V", value: 3547353, partition: 0 },
	{ label: "GNIKM.V", value: 1909207, partition: 0 },
	{ label: "GUBQD.V", value: 3023388, partition: 0 },
	{ label: "ISGYO.E", value: 75296, partition: 0 },
	{ label: "GFASP.V", value: 2237427, partition: 0 },
	{ label: "EYBQM.V", value: 6561686, partition: 0 },
	{ label: "GFIPF.V", value: 2237430, partition: 0 },
	{ label: "EXBRW.V", value: 6037711, partition: 0 },
	{ label: "EDBQK.V", value: 6299703, partition: 0 },
	{ label: "FRIER.V", value: 795056, partition: 0 },
	{ label: "FRBCZ.V", value: 795057, partition: 0 },
	{ label: "ISKUR.E", value: 75316, partition: 0 },
	{ label: "GFBDK.V", value: 4727796, partition: 0 },
	{ label: "ICITS.V", value: 1451494, partition: 0 },
	{ label: "EIACD.V", value: 1581049, partition: 0 },
	{ label: "GF1PQ.V", value: 4727797, partition: 0 },
	{ label: "EIIBY.V", value: 1581050, partition: 0 },
	{ label: "EXBSF.V", value: 8528079, partition: 0 },
	{ label: "ISSDK.V", value: 1451497, partition: 0 },
	{ label: "ISMEN.E", value: 75336, partition: 0 },
	{ label: "EI1AG.V", value: 1581053, partition: 0 },
	{ label: "FRAER.V", value: 3285422, partition: 0 },
	{ label: "FRSQZ.V", value: 3285423, partition: 0 },
	{ label: "FRBDD.V", value: 3285425, partition: 0 },
	{ label: "FR1PJ.V", value: 3285426, partition: 0 },
	{ label: "ESCAR.E", value: 204898, partition: 0 },
	{ label: "ISSEN.E", value: 75346, partition: 0 },
	{ label: "GCBQP.V", value: 4989815, partition: 0 },
	{ label: "EIARM.V", value: 4071417, partition: 0 },
	{ label: "IBAWZ.V", value: 3941864, partition: 0 },
	{ label: "EIBDF.V", value: 4071419, partition: 0 },
	{ label: "ISSDU.V", value: 3941865, partition: 0 },
	{ label: "EISPS.V", value: 4071420, partition: 0 },
	{ label: "ICBDF.V", value: 3941866, partition: 0 },
	{ label: "EI1AQ.V", value: 4071421, partition: 0 },
	{ label: "IS1AP.V", value: 3941867, partition: 0 },
	{ label: "ESEN.E", value: 204936, partition: 0 },
	{ label: "GRAIK.V", value: 3285467, partition: 0 },
	{ label: "GASGC.V", value: 3285468, partition: 0 },
	{ label: "GABDB.V", value: 3285469, partition: 0 },
	{ label: "EYICK.V", value: 1843090, partition: 0 },
	{ label: "GA1PK.V", value: 3285470, partition: 0 },
	{ label: "EKAHN.V", value: 1843092, partition: 0 },
	{ label: "DJSEP.V", value: 2367070, partition: 0 },
	{ label: "EKSDF.V", value: 1843093, partition: 0 },
	{ label: "XOSAY.V", value: 1319118, partition: 0 },
	{ label: "ELICH.V", value: 1581107, partition: 0 },
	{ label: "EDAHB.V", value: 1581108, partition: 0 },
	{ label: "EXICY.V", value: 1319121, partition: 0 },
	{ label: "EDSGB.V", value: 1581110, partition: 0 },
	{ label: "HEATE.V", value: 2237526, partition: 0 },
	{ label: "HEISM.V", value: 2237527, partition: 0 },
	{ label: "EUSDY.V", value: 1319128, partition: 0 },
	{ label: "HESPN.V", value: 2237529, partition: 0 },
	{ label: "EUBBM.V", value: 1319129, partition: 0 },
	{ label: "ICBQL.V", value: 6432234, partition: 0 },
	{ label: "EXSPO.V", value: 1319134, partition: 0 },
	{ label: "GUSBA.V", value: 795165, partition: 0 },
	{ label: "ISYAT.E", value: 75416, partition: 0 },
	{ label: "GA1PT.V", value: 5775838, partition: 0 },
	{ label: "DJBQT.V", value: 4857439, partition: 0 },
	{ label: "EYBQF.V", value: 4333462, partition: 0 },
	{ label: "EK1PO.V", value: 4333463, partition: 0 },
	{ label: "EXBRH.V", value: 3809487, partition: 0 },
	{ label: "EDAWL.V", value: 4071476, partition: 0 },
	{ label: "EDSGL.V", value: 4071478, partition: 0 },
	{ label: "EDBQD.V", value: 4071479, partition: 0 },
	{ label: "ER1AQ.V", value: 4071480, partition: 0 },
	{ label: "HEBDK.V", value: 4727896, partition: 0 },
	{ label: "HE1AR.V", value: 4727898, partition: 0 },
	{ label: "EUBQJ.V", value: 3809497, partition: 0 },
	{ label: "EUREN.E", value: 204993, partition: 0 },
	{ label: "GNIKO.V", value: 2171351, partition: 0 },
	{ label: "GUBQF.V", value: 3285532, partition: 0 },
	{ label: "EYGYO.E", value: 205003, partition: 0 },
	{ label: "GABPP.V", value: 8266205, partition: 0 },
	{ label: "GFASR.V", value: 2499571, partition: 0 },
	{ label: "EXICU.V", value: 205009, partition: 0 },
	{ label: "GF1AJ.V", value: 2499573, partition: 0 },
	{ label: "GFIPH.V", value: 2499574, partition: 0 },
	{ label: "EXBRY.V", value: 6299855, partition: 0 },
	{ label: "EDBQM.V", value: 6561847, partition: 0 },
	{ label: "IZENR.E", value: 75461, partition: 0 },
	{ label: "FRITP.V", value: 1057200, partition: 0 },
	{ label: "FRBDB.V", value: 1057201, partition: 0 },
	{ label: "IZFAS.E", value: 75466, partition: 0 },
	{ label: "FADE.E", value: 205025, partition: 0 },
	{ label: "IZMDC.E", value: 75476, partition: 0 },
	{ label: "IZINV.E", value: 75486, partition: 0 },
	{ label: "ICIEU.V", value: 1713638, partition: 0 },
	{ label: "EIACF.V", value: 1843193, partition: 0 },
	{ label: "GF1AT.V", value: 4989941, partition: 0 },
	{ label: "EIICA.V", value: 1843194, partition: 0 },
	{ label: "EXBSH.V", value: 8790223, partition: 0 },
	{ label: "ISSDM.V", value: 1713641, partition: 0 },
	{ label: "EI1AI.V", value: 1843197, partition: 0 },
	{ label: "FRATO.V", value: 3547566, partition: 0 },
	{ label: "FRSFH.V", value: 3547567, partition: 0 },
	{ label: "FRBPA.V", value: 3547569, partition: 0 },
	{ label: "FR1PL.V", value: 3547570, partition: 0 },
	{ label: "GRAIC.V", value: 1057243, partition: 0 },
	{ label: "GCBQR.V", value: 5251959, partition: 0 },
	{ label: "EIBDH.V", value: 4333563, partition: 0 },
	{ label: "ISSQN.V", value: 4204009, partition: 0 },
	{ label: "ICBQE.V", value: 4204010, partition: 0 },
	{ label: "EI1AS.V", value: 4333565, partition: 0 },
	{ label: "IS1AR.V", value: 4204011, partition: 0 },
	{ label: "GRAXH.V", value: 3547611, partition: 0 },
	{ label: "GASGE.V", value: 3547612, partition: 0 },
	{ label: "GABPK.V", value: 3547613, partition: 0 },
	{ label: "EYICM.V", value: 2105234, partition: 0 },
	{ label: "GA1PM.V", value: 3547614, partition: 0 },
	{ label: "DJSRA.V", value: 2629214, partition: 0 },
	{ label: "EKAHP.V", value: 2105236, partition: 0 },
	{ label: "EKSDH.V", value: 2105237, partition: 0 },
	{ label: "XOSBA.V", value: 1581262, partition: 0 },
	{ label: "ELIRE.V", value: 1843251, partition: 0 },
	{ label: "EDAHD.V", value: 1843252, partition: 0 },
	{ label: "EXIOW.V", value: 1581265, partition: 0 },
	{ label: "EDSGF.V", value: 1843254, partition: 0 },
	{ label: "HEATG.V", value: 2499670, partition: 0 },
	{ label: "HEISO.V", value: 2499671, partition: 0 },
	{ label: "HESBG.V", value: 2499673, partition: 0 },
	{ label: "EUSEA.V", value: 1581272, partition: 0 },
	{ label: "EXSBI.V", value: 1581278, partition: 0 },
	{ label: "GUSBE.V", value: 1057309, partition: 0 },
	{ label: "DJBQV.V", value: 5119583, partition: 0 },
	{ label: "EYBQH.V", value: 4595606, partition: 0 },
	{ label: "GFBCR.V", value: 271348, partition: 0 },
	{ label: "EK1PQ.V", value: 4595607, partition: 0 },
	{ label: "XOSPN.V", value: 4071630, partition: 0 },
	{ label: "EXBRJ.V", value: 4071631, partition: 0 },
	{ label: "EDSSM.V", value: 4333622, partition: 0 },
	{ label: "EDBQF.V", value: 4333623, partition: 0 },
	{ label: "ER1PN.V", value: 4333624, partition: 0 },
	{ label: "EUSQU.V", value: 4071640, partition: 0 },
	{ label: "HE1AT.V", value: 4990042, partition: 0 },
	{ label: "EUBQL.V", value: 4071641, partition: 0 },
	{ label: "GNIYO.V", value: 2433495, partition: 0 },
	{ label: "GUBBH.V", value: 3547676, partition: 0 },
	{ label: "GABPR.V", value: 8528349, partition: 0 },
	{ label: "GFAST.V", value: 2761715, partition: 0 },
	{ label: "GFBCV.V", value: 2761716, partition: 0 },
	{ label: "GF1AL.V", value: 2761717, partition: 0 },
	{ label: "GFIPJ.V", value: 2761718, partition: 0 },
	{ label: "EXBDA.V", value: 6561999, partition: 0 },
	{ label: "FONET.E", value: 205168, partition: 0 },
	{ label: "FRITR.V", value: 1319344, partition: 0 },
	{ label: "FR1AE.V", value: 1319346, partition: 0 },
	{ label: "GOSQJ.V", value: 3023733, partition: 0 },
	{ label: "GCBBK.V", value: 3023735, partition: 0 },
	{ label: "ICIEW.V", value: 1975782, partition: 0 },
	{ label: "EIACH.V", value: 2105337, partition: 0 },
	{ label: "GF1AV.V", value: 5252085, partition: 0 },
	{ label: "EIICC.V", value: 2105338, partition: 0 },
	{ label: "FORMT.E", value: 205198, partition: 0 },
	{ label: "IBAHZ.V", value: 1975784, partition: 0 },
	{ label: "EXBSJ.V", value: 9052367, partition: 0 },
	{ label: "ISSDP.V", value: 1975785, partition: 0 },
	{ label: "EI1PF.V", value: 2105341, partition: 0 },
	{ label: "FORTE.E", value: 205201, partition: 0 },
	{ label: "FRATQ.V", value: 3809710, partition: 0 },
	{ label: "FRSRB.V", value: 3809711, partition: 0 },
	{ label: "FRBDF.V", value: 3809713, partition: 0 },
	{ label: "FR1AO.V", value: 3809714, partition: 0 },
	{ label: "GRAIE.V", value: 1319387, partition: 0 },
	{ label: "DJIJG.V", value: 400989, partition: 0 },
	{ label: "DJBBN.V", value: 400991, partition: 0 },
	{ label: "HEIDS.V", value: 271447, partition: 0 },
	{ label: "HEBCR.V", value: 271448, partition: 0 },
	{ label: "EIBDJ.V", value: 4595707, partition: 0 },
	{ label: "HE1AW.V", value: 271450, partition: 0 },
	{ label: "EI1PO.V", value: 4595709, partition: 0 },
	{ label: "ICBQG.V", value: 4466154, partition: 0 },
	{ label: "IS1PO.V", value: 4466155, partition: 0 },
	{ label: "GRAXJ.V", value: 3809755, partition: 0 },
	{ label: "GASRZ.V", value: 3809756, partition: 0 },
	{ label: "EYIRK.V", value: 2367378, partition: 0 },
	{ label: "GABPM.V", value: 3809757, partition: 0 },
	{ label: "GA1AO.V", value: 3809758, partition: 0 },
	{ label: "EKAWM.V", value: 2367380, partition: 0 },
	{ label: "DJSRC.V", value: 2891358, partition: 0 },
	{ label: "XOSPP.V", value: 1843406, partition: 0 },
	{ label: "ELIRG.V", value: 2105395, partition: 0 },
	{ label: "EDAHF.V", value: 2105396, partition: 0 },
	{ label: "EXIXX.V", value: 1843409, partition: 0 },
	{ label: "EDSGH.V", value: 2105398, partition: 0 },
	{ label: "HEATI.V", value: 2761814, partition: 0 },
	{ label: "HEISQ.V", value: 2761815, partition: 0 },
	{ label: "HEBCV.V", value: 2761816, partition: 0 },
	{ label: "EUSEC.V", value: 1843416, partition: 0 },
	{ label: "HESBI.V", value: 2761817, partition: 0 },
	{ label: "EXSBK.V", value: 1843422, partition: 0 },
	{ label: "GUBAY.V", value: 1319452, partition: 0 },
	{ label: "GUSBG.V", value: 1319453, partition: 0 },
	{ label: "EYBDG.V", value: 4857750, partition: 0 },
	{ label: "GFBCT.V", value: 533492, partition: 0 },
	{ label: "EK1AS.V", value: 4857751, partition: 0 },
	{ label: "EXBRL.V", value: 4333775, partition: 0 },
	{ label: "EDSSO.V", value: 4595766, partition: 0 },
	{ label: "EDBQH.V", value: 4595767, partition: 0 },
	{ label: "ER1PP.V", value: 4595768, partition: 0 },
	{ label: "HE1AV.V", value: 5252186, partition: 0 },
	{ label: "GNIZB.V", value: 2695639, partition: 0 },
	{ label: "GUBBJ.V", value: 3809820, partition: 0 },
	{ label: "GABPT.V", value: 8790493, partition: 0 },
	{ label: "GFADV.V", value: 3023859, partition: 0 },
	{ label: "GFBCX.V", value: 3023860, partition: 0 },
	{ label: "GF1PI.V", value: 3023861, partition: 0 },
	{ label: "GFIAL.V", value: 3023862, partition: 0 },
	{ label: "EXBDC.V", value: 6824143, partition: 0 },
	{ label: "EDSSK.V", value: 7086134, partition: 0 },
	{ label: "FRAEJ.V", value: 1581486, partition: 0 },
	{ label: "FRSEV.V", value: 1581487, partition: 0 },
	{ label: "FRIEI.V", value: 1581488, partition: 0 },
	{ label: "FR1AG.V", value: 1581490, partition: 0 },
	{ label: "GCBBM.V", value: 3285879, partition: 0 },
	{ label: "ICITT.V", value: 2237926, partition: 0 },
	{ label: "EIARE.V", value: 2367481, partition: 0 },
	{ label: "GF1PR.V", value: 5514229, partition: 0 },
	{ label: "EIIQZ.V", value: 2367482, partition: 0 },
	{ label: "IBAWR.V", value: 2237928, partition: 0 },
	{ label: "ISSDR.V", value: 2237929, partition: 0 },
	{ label: "EI1PH.V", value: 2367485, partition: 0 },
	{ label: "FRATS.V", value: 4071854, partition: 0 },
	{ label: "FRSRD.V", value: 4071855, partition: 0 },
	{ label: "FRBDH.V", value: 4071857, partition: 0 },
	{ label: "FR1AQ.V", value: 4071858, partition: 0 },
	{ label: "DJIJI.V", value: 663133, partition: 0 },
	{ label: "DJBBP.V", value: 663135, partition: 0 },
	{ label: "HEIDU.V", value: 533591, partition: 0 },
	{ label: "HEBCT.V", value: 533592, partition: 0 },
	{ label: "HE1AY.V", value: 533594, partition: 0 },
	{ label: "EI1PQ.V", value: 4857853, partition: 0 },
	{ label: "ICBDG.V", value: 4728298, partition: 0 },
	{ label: "IS1PQ.V", value: 4728299, partition: 0 },
	{ label: "GRAXL.V", value: 4071899, partition: 0 },
	{ label: "GASSA.V", value: 4071900, partition: 0 },
	{ label: "EYIRM.V", value: 2629522, partition: 0 },
	{ label: "GABPO.V", value: 4071901, partition: 0 },
	{ label: "GA1AQ.V", value: 4071902, partition: 0 },
	{ label: "EKAWO.V", value: 2629524, partition: 0 },
	{ label: "ELICC.V", value: 2367539, partition: 0 },
	{ label: "EDAWD.V", value: 2367540, partition: 0 },
	{ label: "EXIXZ.V", value: 2105553, partition: 0 },
	{ label: "ER1AI.V", value: 2367544, partition: 0 },
	{ label: "HEAEK.V", value: 3023958, partition: 0 },
	{ label: "HEBCX.V", value: 3023960, partition: 0 },
	{ label: "EUSEE.V", value: 2105560, partition: 0 },
	{ label: "HESPQ.V", value: 3023961, partition: 0 },
	{ label: "EXSBM.V", value: 2105566, partition: 0 },
	{ label: "GNIKG.V", value: 467415, partition: 0 },
	{ label: "GUBBA.V", value: 1581596, partition: 0 },
	{ label: "GUSBI.V", value: 1581597, partition: 0 },
	{ label: "EYBDI.V", value: 5119894, partition: 0 },
	{ label: "EK1AU.V", value: 5119895, partition: 0 },
	{ label: "EXBRN.V", value: 4595919, partition: 0 },
	{ label: "EDSSQ.V", value: 4857910, partition: 0 },
	{ label: "EDBDI.V", value: 4857911, partition: 0 },
	{ label: "ER1AS.V", value: 4857912, partition: 0 },
	{ label: "HE1PS.V", value: 5514330, partition: 0 },
	{ label: "GUBQH.V", value: 4071964, partition: 0 },
	{ label: "GOSCV.V", value: 1057653, partition: 0 },
	{ label: "DGGYO.E", value: 73036, partition: 0 },
	{ label: "GFADX.V", value: 3286003, partition: 0 },
	{ label: "GFBCZ.V", value: 3286004, partition: 0 },
	{ label: "GF1PK.V", value: 3286005, partition: 0 },
	{ label: "GFIAN.V", value: 3286006, partition: 0 },
	{ label: "EXBDE.V", value: 7086287, partition: 0 },
	{ label: "FRAEL.V", value: 1843630, partition: 0 },
	{ label: "FRSEX.V", value: 1843631, partition: 0 },
	{ label: "FRIEK.V", value: 1843632, partition: 0 },
	{ label: "FR1AI.V", value: 1843634, partition: 0 },
	{ label: "DGNMO.E", value: 73061, partition: 0 },
	{ label: "EKAWT.V", value: 4005780, partition: 0 },
	{ label: "GCBQJ.V", value: 3548023, partition: 0 },
	{ label: "ICITV.V", value: 2500070, partition: 0 },
	{ label: "EIARG.V", value: 2629625, partition: 0 },
	{ label: "GF1PT.V", value: 5776373, partition: 0 },
	{ label: "EIIRB.V", value: 2629626, partition: 0 },
	{ label: "IBAWT.V", value: 2500072, partition: 0 },
	{ label: "ISSDT.V", value: 2500073, partition: 0 },
	{ label: "EI1AJ.V", value: 2629629, partition: 0 },
	{ label: "IS1AJ.V", value: 2500075, partition: 0 },
	{ label: "FRBDJ.V", value: 4334001, partition: 0 },
	{ label: "FR1AS.V", value: 4334002, partition: 0 },
	{ label: "GASFS.V", value: 1843676, partition: 0 },
	{ label: "EYICE.V", value: 401298, partition: 0 },
	{ label: "DJIVD.V", value: 925277, partition: 0 },
	{ label: "DJBQM.V", value: 925279, partition: 0 },
	{ label: "DIRIT.E", value: 73096, partition: 0 },
	{ label: "EYBCW.V", value: 401302, partition: 0 },
	{ label: "EK1AX.V", value: 401303, partition: 0 },
	{ label: "HEIDW.V", value: 795735, partition: 0 },
	{ label: "HE1BA.V", value: 795738, partition: 0 },
	{ label: "ICBDI.V", value: 4990442, partition: 0 },
	{ label: "IS1AT.V", value: 4990443, partition: 0 },
	{ label: "FRBDN.V", value: 6824369, partition: 0 },
	{ label: "DITAS.E", value: 73116, partition: 0 },
	{ label: "GASSC.V", value: 4334044, partition: 0 },
	{ label: "GABDE.V", value: 4334045, partition: 0 },
	{ label: "GA1PN.V", value: 4334046, partition: 0 },
	{ label: "EKAHQ.V", value: 2891668, partition: 0 },
	{ label: "EKSDB.V", value: 2891669, partition: 0 },
	{ label: "ELIQZ.V", value: 2629683, partition: 0 },
	{ label: "EXBCG.V", value: 2367695, partition: 0 },
	{ label: "EDAWF.V", value: 2629684, partition: 0 },
	{ label: "ER1AK.V", value: 2629688, partition: 0 },
	{ label: "HEAEM.V", value: 3286102, partition: 0 },
	{ label: "HEBCZ.V", value: 3286104, partition: 0 },
	{ label: "EUSEG.V", value: 2367704, partition: 0 },
	{ label: "EXSBO.V", value: 2367710, partition: 0 },
	{ label: "GNIKI.V", value: 729559, partition: 0 },
	{ label: "GUBPY.V", value: 1843740, partition: 0 },
	{ label: "GUSPO.V", value: 1843741, partition: 0 },
	{ label: "EKSQB.V", value: 5382037, partition: 0 },
	{ label: "EYBDK.V", value: 5382038, partition: 0 },
	{ label: "EK1PR.V", value: 5382039, partition: 0 },
	{ label: "EXBRP.V", value: 4858063, partition: 0 },
	{ label: "EDBDK.V", value: 5120055, partition: 0 },
	{ label: "ER1AU.V", value: 5120056, partition: 0 },
	{ label: "DMRGD.E", value: 73171, partition: 0 },
	{ label: "HE1PU.V", value: 5776474, partition: 0 },
	{ label: "DMSAS.E", value: 73176, partition: 0 },
	{ label: "GUBQJ.V", value: 4334108, partition: 0 },
	{ label: "GOSCX.V", value: 1319797, partition: 0 },
	{ label: "GFASU.V", value: 3548147, partition: 0 },
	{ label: "GFBDB.V", value: 3548148, partition: 0 },
	{ label: "GF1PM.V", value: 3548149, partition: 0 },
	{ label: "DOAS.E", value: 73196, partition: 0 },
	{ label: "EIIBU.V", value: 401402, partition: 0 },
	{ label: "GFIPK.V", value: 3548150, partition: 0 },
	{ label: "IBAHW.V", value: 271848, partition: 0 },
	{ label: "EIBCS.V", value: 401403, partition: 0 },
	{ label: "EXBDG.V", value: 7348431, partition: 0 },
	{ label: "EISBD.V", value: 401404, partition: 0 },
	{ label: "ICBCW.V", value: 271850, partition: 0 },
	{ label: "FRAEN.V", value: 2105774, partition: 0 },
	{ label: "FRSEZ.V", value: 2105775, partition: 0 },
	{ label: "FRIEM.V", value: 2105776, partition: 0 },
	{ label: "FR1PF.V", value: 2105778, partition: 0 },
	{ label: "DOBUR.E", value: 73216, partition: 0 },
	{ label: "GCBQL.V", value: 3810167, partition: 0 },
	{ label: "ICITX.V", value: 2762214, partition: 0 },
	{ label: "EIACI.V", value: 2891769, partition: 0 },
	{ label: "IBAWV.V", value: 2762216, partition: 0 },
	{ label: "EIBCW.V", value: 2891771, partition: 0 },
	{ label: "EI1AL.V", value: 2891773, partition: 0 },
	{ label: "IS1AL.V", value: 2762219, partition: 0 },
	{ label: "FRBDL.V", value: 4596145, partition: 0 },
	{ label: "DOCO.E", value: 73236, partition: 0 },
	{ label: "FR1PO.V", value: 4596146, partition: 0 },
	{ label: "GRAIG.V", value: 2105819, partition: 0 },
	{ label: "GASFU.V", value: 2105820, partition: 0 },
	{ label: "EYICG.V", value: 663442, partition: 0 },
	{ label: "DJIVF.V", value: 1187421, partition: 0 },
	{ label: "DJSEI.V", value: 1187422, partition: 0 },
	{ label: "DJBQO.V", value: 1187423, partition: 0 },
	{ label: "EYBCY.V", value: 663446, partition: 0 },
	{ label: "EK1AZ.V", value: 663447, partition: 0 },
	{ label: "DOGUB.E", value: 73256, partition: 0 },
	{ label: "HEISS.V", value: 1057879, partition: 0 },
	{ label: "HE1PW.V", value: 1057882, partition: 0 },
	{ label: "ICBDK.V", value: 5252586, partition: 0 },
	{ label: "IS1AV.V", value: 5252587, partition: 0 },
	{ label: "FRBPC.V", value: 7086513, partition: 0 },
	{ label: "DOHOL.E", value: 73276, partition: 0 },
	{ label: "GABDG.V", value: 4596189, partition: 0 },
	{ label: "GA1PP.V", value: 4596190, partition: 0 },
	{ label: "EKAHS.V", value: 3153812, partition: 0 },
	{ label: "EKSPZ.V", value: 3153813, partition: 0 },
	{ label: "DJBQP.V", value: 3677791, partition: 0 },
	{ label: "GRSEL.E", value: 205718, partition: 0 },
	{ label: "ELIRB.V", value: 2891827, partition: 0 },
	{ label: "DOKTA.E", value: 73286, partition: 0 },
	{ label: "EXBCI.V", value: 2629839, partition: 0 },
	{ label: "EDAHG.V", value: 2891828, partition: 0 },
	{ label: "GRTRK.E", value: 205723, partition: 0 },
	{ label: "ER1AM.V", value: 2891832, partition: 0 },
	{ label: "HEATJ.V", value: 3548246, partition: 0 },
	{ label: "HEBDB.V", value: 3548248, partition: 0 },
	{ label: "DURDO.E", value: 73296, partition: 0 },
	{ label: "HE1AN.V", value: 3548250, partition: 0 },
	{ label: "GNIYI.V", value: 991703, partition: 0 },
	{ label: "EXSPR.V", value: 2629854, partition: 0 },
	{ label: "GUBQA.V", value: 2105884, partition: 0 },
	{ label: "EYBDM.V", value: 5644182, partition: 0 },
	{ label: "EK1PT.V", value: 5644183, partition: 0 },
	{ label: "EXBCZ.V", value: 5120207, partition: 0 },
	{ label: "EDBDM.V", value: 5382199, partition: 0 },
	{ label: "ER1AW.V", value: 5382200, partition: 0 },
	{ label: "DYOBY.E", value: 73336, partition: 0 },
	{ label: "GUSBB.V", value: 4596253, partition: 0 },
	{ label: "GOSCZ.V", value: 1581941, partition: 0 },
	{ label: "GFASW.V", value: 3810291, partition: 0 },
	{ label: "ICIEQ.V", value: 533990, partition: 0 },
	{ label: "GFBDD.V", value: 3810292, partition: 0 },
	{ label: "EIIBW.V", value: 663546, partition: 0 },
	{ label: "GF1AO.V", value: 3810293, partition: 0 },
	{ label: "EIBCU.V", value: 663547, partition: 0 },
	{ label: "GFIPM.V", value: 3810294, partition: 0 },
	{ label: "EXBDI.V", value: 7610575, partition: 0 },
	{ label: "EISBF.V", value: 663548, partition: 0 },
	{ label: "ICBCY.V", value: 533994, partition: 0 },
	{ label: "FRATK.V", value: 2367918, partition: 0 },
	{ label: "FRSFD.V", value: 2367919, partition: 0 },
	{ label: "DZGYO.E", value: 73356, partition: 0 },
	{ label: "FRITK.V", value: 2367920, partition: 0 },
	{ label: "FR1PH.V", value: 2367922, partition: 0 },
	{ label: "EBEBK.E", value: 73371, partition: 0 },
	{ label: "GOSQH.V", value: 4072309, partition: 0 },
	{ label: "GCBQN.V", value: 4072311, partition: 0 },
	{ label: "EIACK.V", value: 3153913, partition: 0 },
	{ label: "IBAIC.V", value: 3024360, partition: 0 },
	{ label: "EIBCY.V", value: 3153915, partition: 0 },
	{ label: "EISAX.V", value: 3153916, partition: 0 },
	{ label: "EI1AN.V", value: 3153917, partition: 0 },
	{ label: "IS1PI.V", value: 3024363, partition: 0 },
	{ label: "FR1PQ.V", value: 4858290, partition: 0 },
	{ label: "ECILC.E", value: 73396, partition: 0 },
	{ label: "GRAXD.V", value: 2367963, partition: 0 },
	{ label: "GASFW.V", value: 2367964, partition: 0 },
	{ label: "EYIRD.V", value: 925586, partition: 0 },
	{ label: "DJIJK.V", value: 1449565, partition: 0 },
	{ label: "GA1AI.V", value: 2367966, partition: 0 },
	{ label: "DJSEK.V", value: 1449566, partition: 0 },
	{ label: "EKSCW.V", value: 925589, partition: 0 },
	{ label: "EYBDA.V", value: 925590, partition: 0 },
	{ label: "EK1PV.V", value: 925591, partition: 0 },
	{ label: "XOSAS.V", value: 401614, partition: 0 },
	{ label: "ELIBZ.V", value: 663603, partition: 0 },
	{ label: "EXBCR.V", value: 401615, partition: 0 },
	{ label: "EDBCY.V", value: 663607, partition: 0 },
	{ label: "HEISU.V", value: 1320023, partition: 0 },
	{ label: "EUBBJ.V", value: 401625, partition: 0 },
	{ label: "ICBDM.V", value: 5514730, partition: 0 },
	{ label: "IS1PR.V", value: 5514731, partition: 0 },
	{ label: "EXSBC.V", value: 401630, partition: 0 },
	{ label: "EXGAB.V", value: 401635, partition: 0 },
	{ label: "GABDI.V", value: 4858333, partition: 0 },
	{ label: "GA1AS.V", value: 4858334, partition: 0 },
	{ label: "ECZYT.E", value: 73436, partition: 0 },
	{ label: "EKAHU.V", value: 3415956, partition: 0 },
	{ label: "EKSDJ.V", value: 3415957, partition: 0 },
	{ label: "DJBBR.V", value: 3939935, partition: 0 },
	{ label: "EK1AM.V", value: 3415959, partition: 0 },
	{ label: "EXBCK.V", value: 2891983, partition: 0 },
	{ label: "EDAHI.V", value: 3153972, partition: 0 },
	{ label: "EDSGD.V", value: 3153974, partition: 0 },
	{ label: "ER1PJ.V", value: 3153976, partition: 0 },
	{ label: "HEATL.V", value: 3810390, partition: 0 },
	{ label: "HEBDD.V", value: 3810392, partition: 0 },
	{ label: "EUSQS.V", value: 2891992, partition: 0 },
	{ label: "HE1AP.V", value: 3810394, partition: 0 },
	{ label: "EDIP.E", value: 73456, partition: 0 },
	{ label: "GNIYK.V", value: 1253847, partition: 0 },
	{ label: "EFORC.E", value: 73461, partition: 0 },
	{ label: "GUBBC.V", value: 2368028, partition: 0 },
	{ label: "GFADP.V", value: 1582067, partition: 0 },
	{ label: "EYBDO.V", value: 5906326, partition: 0 },
	{ label: "EK1PY.V", value: 5906327, partition: 0 },
	{ label: "GFIAF.V", value: 1582070, partition: 0 },
	{ label: "EXBRR.V", value: 5382351, partition: 0 },
	{ label: "EDBDO.V", value: 5644343, partition: 0 },
	{ label: "ER1PS.V", value: 5644344, partition: 0 },
	{ label: "GUSPL.V", value: 4858397, partition: 0 },
	{ label: "GOSDB.V", value: 1844085, partition: 0 },
	{ label: "GFASY.V", value: 4072435, partition: 0 },
	{ label: "GFBDF.V", value: 4072436, partition: 0 },
	{ label: "ICIES.V", value: 796134, partition: 0 },
	{ label: "GF1AQ.V", value: 4072437, partition: 0 },
	{ label: "EIIQT.V", value: 925690, partition: 0 },
	{ label: "GFIPO.V", value: 4072438, partition: 0 },
	{ label: "EXBSA.V", value: 7872719, partition: 0 },
	{ label: "EISPT.V", value: 925692, partition: 0 },
	{ label: "ICBDA.V", value: 796138, partition: 0 },
	{ label: "FRATM.V", value: 2630062, partition: 0 },
	{ label: "FRSFF.V", value: 2630063, partition: 0 },
	{ label: "FRITM.V", value: 2630064, partition: 0 },
	{ label: "FR1AJ.V", value: 2630066, partition: 0 },
	{ label: "EGEEN.E", value: 73516, partition: 0 },
	{ label: "GOSQL.V", value: 4334453, partition: 0 },
	{ label: "GCBBP.V", value: 4334455, partition: 0 },
	{ label: "EGGUB.E", value: 73536, partition: 0 },
	{ label: "EIACM.V", value: 3416057, partition: 0 },
	{ label: "IBAIE.V", value: 3286504, partition: 0 },
	{ label: "EIBDA.V", value: 3416059, partition: 0 },
	{ label: "EISAZ.V", value: 3416060, partition: 0 },
	{ label: "EI1PK.V", value: 3416061, partition: 0 },
	{ label: "IS1PK.V", value: 3286507, partition: 0 },
	{ label: "HUNER.E", value: 205978, partition: 0 },
	{ label: "GRAXF.V", value: 2630107, partition: 0 },
	{ label: "GASFY.V", value: 2630108, partition: 0 },
	{ label: "EYIRG.V", value: 1187730, partition: 0 },
	{ label: "DJIJM.V", value: 1711709, partition: 0 },
	{ label: "GA1AK.V", value: 2630110, partition: 0 },
	{ label: "DJSQX.V", value: 1711710, partition: 0 },
	{ label: "EKSCY.V", value: 1187733, partition: 0 },
	{ label: "EYBDC.V", value: 1187734, partition: 0 },
	{ label: "EK1PX.V", value: 1187735, partition: 0 },
	{ label: "XOSAU.V", value: 663758, partition: 0 },
	{ label: "ELICB.V", value: 925747, partition: 0 },
	{ label: "EXBCT.V", value: 663759, partition: 0 },
	{ label: "EXICX.V", value: 663761, partition: 0 },
	{ label: "EDBDA.V", value: 925751, partition: 0 },
	{ label: "HEAEE.V", value: 1582166, partition: 0 },
	{ label: "HEIDN.V", value: 1582167, partition: 0 },
	{ label: "HESBA.V", value: 1582169, partition: 0 },
	{ label: "EUBBL.V", value: 663769, partition: 0 },
	{ label: "ICBDO.V", value: 5776874, partition: 0 },
	{ label: "IS1PT.V", value: 5776875, partition: 0 },
	{ label: "EGPRO.E", value: 73576, partition: 0 },
	{ label: "EXSBE.V", value: 663774, partition: 0 },
	{ label: "EXGPB.V", value: 663779, partition: 0 },
	{ label: "GABDK.V", value: 5120477, partition: 0 },
	{ label: "GA1AU.V", value: 5120478, partition: 0 },
	{ label: "EKAWR.V", value: 3678100, partition: 0 },
	{ label: "EKSQD.V", value: 3678101, partition: 0 },
	{ label: "DJBBT.V", value: 4202079, partition: 0 },
	{ label: "EK1AO.V", value: 3678103, partition: 0 },
	{ label: "EGSER.E", value: 73596, partition: 0 },
	{ label: "EXBCM.V", value: 3154127, partition: 0 },
	{ label: "EDAHK.V", value: 3416116, partition: 0 },
	{ label: "EDSGJ.V", value: 3416118, partition: 0 },
	{ label: "ER1PL.V", value: 3416120, partition: 0 },
	{ label: "HEATN.V", value: 4072534, partition: 0 },
	{ label: "HEBDF.V", value: 4072536, partition: 0 },
	{ label: "HESPO.V", value: 4072537, partition: 0 },
	{ label: "EUSQV.V", value: 3154136, partition: 0 },
	{ label: "HE1PM.V", value: 4072538, partition: 0 },
	{ label: "GNIYM.V", value: 1515991, partition: 0 },
	{ label: "EKGYO.E", value: 73616, partition: 0 },
	{ label: "GUBBE.V", value: 2630172, partition: 0 },
	{ label: "IHAAS.E", value: 206058, partition: 0 },
	{ label: "GFADR.V", value: 1844211, partition: 0 },
	{ label: "EYBQJ.V", value: 6168470, partition: 0 },
	{ label: "GFIAH.V", value: 1844214, partition: 0 },
	{ label: "EXBRT.V", value: 5644495, partition: 0 },
	{ label: "EDBDQ.V", value: 5906487, partition: 0 },
	{ label: "ER1PU.V", value: 5906488, partition: 0 },
	{ label: "FRIEO.V", value: 401840, partition: 0 },
	{ label: "FRBCW.V", value: 401841, partition: 0 },
	{ label: "EKIZ.E", value: 73636, partition: 0 },
	{ label: "EKSUN.E", value: 73641, partition: 0 },
	{ label: "ELITE.E", value: 73646, partition: 0 },
	{ label: "GUSPN.V", value: 5120541, partition: 0 },
	{ label: "GOSDD.V", value: 2106229, partition: 0 },
	{ label: "GFBDH.V", value: 4334580, partition: 0 },
	{ label: "ICITP.V", value: 1058278, partition: 0 },
	{ label: "GF1PN.V", value: 4334581, partition: 0 },
	{ label: "EIIQV.V", value: 1187834, partition: 0 },
	{ label: "EXBSC.V", value: 8134863, partition: 0 },
	{ label: "ISSDH.V", value: 1058281, partition: 0 },
	{ label: "ICBDC.V", value: 1058282, partition: 0 },
	{ label: "FRAEO.V", value: 2892206, partition: 0 },
	{ label: "FRSFA.V", value: 2892207, partition: 0 },
	{ label: "FR1AL.V", value: 2892210, partition: 0 },
	{ label: "EMKEL.E", value: 73676, partition: 0 },
	{ label: "GABCU.V", value: 401885, partition: 0 },
	{ label: "GCBBR.V", value: 4596599, partition: 0 },
	{ label: "EIARJ.V", value: 3678201, partition: 0 },
	{ label: "IBAWW.V", value: 3548648, partition: 0 },
	{ label: "EIBDC.V", value: 3678203, partition: 0 },
	{ label: "ISSQJ.V", value: 3548649, partition: 0 },
	{ label: "EISBB.V", value: 3678204, partition: 0 },
	{ label: "EMNIS.E", value: 73696, partition: 0 },
	{ label: "EI1PM.V", value: 3678205, partition: 0 },
	{ label: "IS1PM.V", value: 3548651, partition: 0 },
	{ label: "ENJSA.E", value: 73706, partition: 0 },
	{ label: "GRAIH.V", value: 2892251, partition: 0 },
	{ label: "ENERY.E", value: 73711, partition: 0 },
	{ label: "GASFZ.V", value: 2892252, partition: 0 },
	{ label: "EYIRI.V", value: 1449874, partition: 0 },
	{ label: "GABCY.V", value: 2892253, partition: 0 },
	{ label: "DJIVH.V", value: 1973853, partition: 0 },
	{ label: "GA1AM.V", value: 2892254, partition: 0 },
	{ label: "DJSEM.V", value: 1973854, partition: 0 },
	{ label: "EKSDA.V", value: 1449877, partition: 0 },
	{ label: "ENKAI.E", value: 73716, partition: 0 },
	{ label: "XOSPL.V", value: 925902, partition: 0 },
	{ label: "ELICE.V", value: 1187891, partition: 0 },
	{ label: "EXBCV.V", value: 925903, partition: 0 },
	{ label: "EXIXU.V", value: 925905, partition: 0 },
	{ label: "EDSFY.V", value: 1187894, partition: 0 },
	{ label: "EDBDC.V", value: 1187895, partition: 0 },
	{ label: "HEAEG.V", value: 1844310, partition: 0 },
	{ label: "ENTRA.E", value: 73726, partition: 0 },
	{ label: "HEIDP.V", value: 1844311, partition: 0 },
	{ label: "HESBC.V", value: 1844313, partition: 0 },
	{ label: "EUBQF.V", value: 925913, partition: 0 },
	{ label: "ICBQI.V", value: 6039018, partition: 0 },
	{ label: "EXSBG.V", value: 925918, partition: 0 },
	{ label: "EPLAS.E", value: 73736, partition: 0 },
	{ label: "GUSAX.V", value: 401949, partition: 0 },
	{ label: "GUGAB.V", value: 401950, partition: 0 },
	{ label: "GA1AW.V", value: 5382622, partition: 0 },
	{ label: "DJBBV.V", value: 4464223, partition: 0 },
	{ label: "EYBDE.V", value: 3940246, partition: 0 },
	{ label: "EK1AQ.V", value: 3940247, partition: 0 },
	{ label: "EXBCO.V", value: 3416271, partition: 0 },
	{ label: "EDAWI.V", value: 3678260, partition: 0 },
	{ label: "EDSSG.V", value: 3678262, partition: 0 },
	{ label: "ER1AN.V", value: 3678264, partition: 0 },
	{ label: "ERBOS.E", value: 73756, partition: 0 },
	{ label: "HEBDH.V", value: 4334680, partition: 0 },
	{ label: "HESPS.V", value: 4334681, partition: 0 },
	{ label: "HE1PO.V", value: 4334682, partition: 0 },
	{ label: "EUBBO.V", value: 3416281, partition: 0 },
	{ label: "ERCB.E", value: 73766, partition: 0 },
	{ label: "GNIKL.V", value: 1778135, partition: 0 },
	{ label: "GUBQC.V", value: 2892316, partition: 0 },
	{ label: "EREGL.E", value: 73776, partition: 0 },
	{ label: "GFADT.V", value: 2106355, partition: 0 },
	{ label: "EYBQL.V", value: 6430614, partition: 0 },
	{ label: "GFIAJ.V", value: 2106358, partition: 0 },
	{ label: "EXBRV.V", value: 5906639, partition: 0 },
	{ label: "EDBQJ.V", value: 6168631, partition: 0 },
	{ label: "FRIEQ.V", value: 663984, partition: 0 },
	{ label: "FRBCY.V", value: 663985, partition: 0 },
	{ label: "INVES.E", value: 206238, partition: 0 },
	{ label: "GUSPQ.V", value: 5382685, partition: 0 },
	{ label: "GFBDJ.V", value: 4596724, partition: 0 },
	{ label: "ICITR.V", value: 1320422, partition: 0 },
	{ label: "GF1PP.V", value: 4596725, partition: 0 },
	{ label: "ERSU.E", value: 73816, partition: 0 },
	{ label: "EIIQX.V", value: 1449978, partition: 0 },
	{ label: "EXBSE.V", value: 8397007, partition: 0 },
	{ label: "ISSDJ.V", value: 1320425, partition: 0 },
	{ label: "EI1AF.V", value: 1449981, partition: 0 },
	{ label: "FRAEQ.V", value: 3154350, partition: 0 },
	{ label: "FRSQY.V", value: 3154351, partition: 0 },
	{ label: "FR1AN.V", value: 3154354, partition: 0 },
	{ label: "GABCW.V", value: 664029, partition: 0 },
	{ label: "ESCOM.E", value: 73836, partition: 0 },
	{ label: "GCBQO.V", value: 4858743, partition: 0 },
	{ label: "EIARL.V", value: 3940345, partition: 0 },
	{ label: "IBAWY.V", value: 3810792, partition: 0 },
	{ label: "EIBDE.V", value: 3940347, partition: 0 },
	{ label: "ISSQL.V", value: 3810793, partition: 0 },
	{ label: "EISPR.V", value: 3940348, partition: 0 },
	{ label: "ICBDE.V", value: 3810794, partition: 0 },
	{ label: "EI1AP.V", value: 3940349, partition: 0 },
	{ label: "IS1AO.V", value: 3810795, partition: 0 },
	{ label: "GRAIJ.V", value: 3154395, partition: 0 },
	{ label: "GASGB.V", value: 3154396, partition: 0 },
	{ label: "GABDA.V", value: 3154397, partition: 0 },
	{ label: "EYICJ.V", value: 1712018, partition: 0 },
	{ label: "GA1PJ.V", value: 3154398, partition: 0 },
	{ label: "DJIVJ.V", value: 2235997, partition: 0 },
	{ label: "EKAHM.V", value: 1712020, partition: 0 },
	{ label: "DJSEO.V", value: 2235998, partition: 0 },
	{ label: "EKSDE.V", value: 1712021, partition: 0 },
	{ label: "XOSAX.V", value: 1188046, partition: 0 },
	{ label: "ELICG.V", value: 1450035, partition: 0 },
	{ label: "EXBCX.V", value: 1188047, partition: 0 },
	{ label: "ETILR.E", value: 73876, partition: 0 },
	{ label: "ICIEO.V", value: 206310, partition: 0 },
	{ label: "EXIXW.V", value: 1188049, partition: 0 },
	{ label: "EDSGA.V", value: 1450038, partition: 0 },
	{ label: "EDBDE.V", value: 1450039, partition: 0 },
	{ label: "HEAEI.V", value: 2106454, partition: 0 },
	{ label: "HEIDR.V", value: 2106455, partition: 0 },
	{ label: "EUSDX.V", value: 1188056, partition: 0 },
	{ label: "HESBE.V", value: 2106457, partition: 0 },
	{ label: "EUBQH.V", value: 1188057, partition: 0 },
	{ label: "ICBQK.V", value: 6301162, partition: 0 },
	{ label: "EXSPN.V", value: 1188062, partition: 0 },
	{ label: "ETYAT.E", value: 73896, partition: 0 },
	{ label: "GUSAZ.V", value: 664093, partition: 0 },
	{ label: "GUGPB.V", value: 664094, partition: 0 },
	{ label: "GA1PS.V", value: 5644766, partition: 0 },
	{ label: "DJBQS.V", value: 4726367, partition: 0 },
	{ label: "EYBQE.V", value: 4202390, partition: 0 },
	{ label: "EK1PN.V", value: 4202391, partition: 0 },
	{ label: "EUHOL.E", value: 73906, partition: 0 },
	{ label: "EXBRG.V", value: 3678415, partition: 0 },
	{ label: "EDAWK.V", value: 3940404, partition: 0 },
	{ label: "EDSSI.V", value: 3940406, partition: 0 },
	{ label: "EDBDG.V", value: 3940407, partition: 0 },
	{ label: "ER1AP.V", value: 3940408, partition: 0 },
	{ label: "HEBDJ.V", value: 4596824, partition: 0 },
	{ label: "EUKYO.E", value: 73916, partition: 0 },
	{ label: "HE1PQ.V", value: 4596826, partition: 0 },
	{ label: "EUBQI.V", value: 3678425, partition: 0 },
	{ label: "EUPWR.E", value: 73919, partition: 0 },
	{ label: "GNIKN.V", value: 2040279, partition: 0 },
	{ label: "GUBQE.V", value: 3154460, partition: 0 },
	{ label: "GABDM.V", value: 8135133, partition: 0 },
	{ label: "GFASQ.V", value: 2368499, partition: 0 },
	{ label: "GF1AI.V", value: 2368501, partition: 0 },
	{ label: "GFIPG.V", value: 2368502, partition: 0 },
	{ label: "EXBRX.V", value: 6168783, partition: 0 },
	{ label: "EDBQL.V", value: 6430775, partition: 0 },
	{ label: "FRITO.V", value: 926128, partition: 0 },
	{ label: "FRBDA.V", value: 926129, partition: 0 },
	{ label: "EUYO.E", value: 73946, partition: 0 },
	{ label: "ISKPL.E", value: 206383, partition: 0 },
	{ label: "ICIET.V", value: 1582566, partition: 0 },
	{ label: "EIACE.V", value: 1712121, partition: 0 },
	{ label: "GF1AS.V", value: 4858869, partition: 0 },
	{ label: "EIIBZ.V", value: 1712122, partition: 0 },
	{ label: "EXBSG.V", value: 8659151, partition: 0 },
	{ label: "ISSDL.V", value: 1582569, partition: 0 },
	{ label: "EI1AH.V", value: 1712125, partition: 0 },
	{ label: "FRAES.V", value: 3416494, partition: 0 },
	{ label: "FRSRA.V", value: 3416495, partition: 0 },
	{ label: "FENER.E", value: 73976, partition: 0 },
	{ label: "FRBDE.V", value: 3416497, partition: 0 },
	{ label: "FR1PK.V", value: 3416498, partition: 0 },
	{ label: "GCBQQ.V", value: 5120887, partition: 0 },
	{ label: "IBAXA.V", value: 4072936, partition: 0 },
	{ label: "EIBDG.V", value: 4202491, partition: 0 },
	{ label: "ISSQM.V", value: 4072937, partition: 0 },
	{ label: "EISPV.V", value: 4202492, partition: 0 },
	{ label: "ICBQD.V", value: 4072938, partition: 0 },
	{ label: "EI1AR.V", value: 4202493, partition: 0 },
	{ label: "IS1AQ.V", value: 4072939, partition: 0 },
	{ label: "GRAIL.V", value: 3416539, partition: 0 },
	{ label: "GASGD.V", value: 3416540, partition: 0 },
	{ label: "GABDC.V", value: 3416541, partition: 0 },
	{ label: "EYICL.V", value: 1974162, partition: 0 },
	{ label: "GA1PL.V", value: 3416542, partition: 0 },
	{ label: "DJSEQ.V", value: 2498142, partition: 0 },
	{ label: "EKAHO.V", value: 1974164, partition: 0 },
	{ label: "EKSDG.V", value: 1974165, partition: 0 },
	{ label: "XOSAZ.V", value: 1450190, partition: 0 },
	{ label: "ELIRD.V", value: 1712179, partition: 0 },
	{ label: "EDAHC.V", value: 1712180, partition: 0 },
	{ label: "EXICZ.V", value: 1450193, partition: 0 },
	{ label: "EDSGC.V", value: 1712182, partition: 0 },
	{ label: "HEATF.V", value: 2368598, partition: 0 },
	{ label: "HEISN.V", value: 2368599, partition: 0 },
	{ label: "HESBF.V", value: 2368601, partition: 0 },
	{ label: "EUSDZ.V", value: 1450200, partition: 0 },
	{ label: "EUBBN.V", value: 1450201, partition: 0 },
	{ label: "ICBQM.V", value: 6563306, partition: 0 },
	{ label: "EXSPP.V", value: 1450206, partition: 0 },
	{ label: "GUSBD.V", value: 926237, partition: 0 },
	{ label: "GA1PU.V", value: 5906910, partition: 0 },
	{ label: "FLAP.E", value: 74056, partition: 0 },
	{ label: "DJBQU.V", value: 4988511, partition: 0 },
	{ label: "EYBQG.V", value: 4464534, partition: 0 },
	{ label: "EK1PP.V", value: 4464535, partition: 0 },
	{ label: "XOSPM.V", value: 3940558, partition: 0 },
	{ label: "EXBRI.V", value: 3940559, partition: 0 },
	{ label: "EDSGM.V", value: 4202550, partition: 0 },
	{ label: "EDBQE.V", value: 4202551, partition: 0 },
	{ label: "ER1AR.V", value: 4202552, partition: 0 },
	{ label: "HE1AS.V", value: 4858970, partition: 0 },
	{ label: "EUBQK.V", value: 3940569, partition: 0 },
	{ label: "FMIZP.E", value: 74076, partition: 0 },
	{ label: "GNIYN.V", value: 2302423, partition: 0 },
	{ label: "GUBBG.V", value: 3416604, partition: 0 },
	{ label: "GABPQ.V", value: 8397277, partition: 0 },
	{ label: "GFASS.V", value: 2630643, partition: 0 },
	{ label: "EXICV.V", value: 336081, partition: 0 },
	{ label: "GF1AK.V", value: 2630645, partition: 0 },
	{ label: "GFIPI.V", value: 2630646, partition: 0 },
	{ label: "EXBRZ.V", value: 6430927, partition: 0 },
	{ label: "FRITQ.V", value: 1188272, partition: 0 },
	{ label: "FRBDC.V", value: 1188273, partition: 0 },
	{ label: "GOSQG.V", value: 2892661, partition: 0 },
	{ label: "GCBBJ.V", value: 2892663, partition: 0 },
	{ label: "ICIEV.V", value: 1844710, partition: 0 },
	{ label: "EIACG.V", value: 1974265, partition: 0 },
	{ label: "GF1AU.V", value: 5121013, partition: 0 },
	{ label: "EIICB.V", value: 1974266, partition: 0 },
	{ label: "IBAHY.V", value: 1844712, partition: 0 },
	{ label: "EXBSI.V", value: 8921295, partition: 0 },
	{ label: "ISSDO.V", value: 1844713, partition: 0 },
	{ label: "EI1PE.V", value: 1974269, partition: 0 },
	{ label: "FRATP.V", value: 3678638, partition: 0 },
	{ label: "FRSFI.V", value: 3678639, partition: 0 },
	{ label: "FRBPB.V", value: 3678641, partition: 0 },
	{ label: "FR1PM.V", value: 3678642, partition: 0 },
	{ label: "FRIGO.E", value: 74136, partition: 0 },
	{ label: "GRAID.V", value: 1188315, partition: 0 },
	{ label: "DJIJF.V", value: 269917, partition: 0 },
	{ label: "DJBBM.V", value: 269919, partition: 0 },
	{ label: "GCBQS.V", value: 5383031, partition: 0 },
	{ label: "FROTO.E", value: 74156, partition: 0 },
	{ label: "EIBDI.V", value: 4464635, partition: 0 },
	{ label: "ISSQO.V", value: 4335081, partition: 0 },
	{ label: "EI1PN.V", value: 4464637, partition: 0 },
	{ label: "ICBQF.V", value: 4335082, partition: 0 },
	{ label: "IS1PN.V", value: 4335083, partition: 0 },
	{ label: "GRAXI.V", value: 3678683, partition: 0 },
	{ label: "GASRY.V", value: 3678684, partition: 0 },
	{ label: "EYIRJ.V", value: 2236306, partition: 0 },
	{ label: "GABPL.V", value: 3678685, partition: 0 },
	{ label: "GA1AN.V", value: 3678686, partition: 0 },
	{ label: "EKAWL.V", value: 2236308, partition: 0 },
	{ label: "DJSRB.V", value: 2760286, partition: 0 },
	{ label: "ELIRF.V", value: 1974323, partition: 0 },
	{ label: "XOSPO.V", value: 1712334, partition: 0 },
	{ label: "EDAHE.V", value: 1974324, partition: 0 },
	{ label: "EDSGG.V", value: 1974326, partition: 0 },
	{ label: "FZLGY.E", value: 74186, partition: 0 },
	{ label: "EXIOX.V", value: 1712337, partition: 0 },
	{ label: "HEATH.V", value: 2630742, partition: 0 },
	{ label: "HEISP.V", value: 2630743, partition: 0 },
	{ label: "HESBH.V", value: 2630745, partition: 0 },
	{ label: "EUSEB.V", value: 1712344, partition: 0 },
	{ label: "GARAN.E", value: 74196, partition: 0 },
	{ label: "EXSBJ.V", value: 1712350, partition: 0 },
	{ label: "GUSBF.V", value: 1188381, partition: 0 },
	{ label: "EYBDF.V", value: 4726678, partition: 0 },
	{ label: "GFBCS.V", value: 402420, partition: 0 },
	{ label: "EK1AR.V", value: 4726679, partition: 0 },
	{ label: "XOSPQ.V", value: 4202702, partition: 0 },
	{ label: "EXBRK.V", value: 4202703, partition: 0 },
	{ label: "EDSSN.V", value: 4464694, partition: 0 },
	{ label: "EDBQG.V", value: 4464695, partition: 0 },
	{ label: "ER1PO.V", value: 4464696, partition: 0 },
	{ label: "EUSQX.V", value: 4202712, partition: 0 },
	{ label: "HE1AU.V", value: 5121114, partition: 0 },
	{ label: "GNIZA.V", value: 2564567, partition: 0 },
	{ label: "GARFA.E", value: 74236, partition: 0 },
	{ label: "GUBBI.V", value: 3678748, partition: 0 },
	{ label: "GABPS.V", value: 8659421, partition: 0 },
	{ label: "GFADU.V", value: 2892787, partition: 0 },
	{ label: "GFBCW.V", value: 2892788, partition: 0 },
	{ label: "GF1AM.V", value: 2892789, partition: 0 },
	{ label: "GFIAK.V", value: 2892790, partition: 0 },
	{ label: "EXBDB.V", value: 6693071, partition: 0 },
	{ label: "EDSSJ.V", value: 6955062, partition: 0 },
	{ label: "FRITS.V", value: 1450416, partition: 0 },
	{ label: "FR1AF.V", value: 1450418, partition: 0 },
	{ label: "GOSQK.V", value: 3154805, partition: 0 },
	{ label: "GCBBL.V", value: 3154807, partition: 0 },
	{ label: "ICIEX.V", value: 2106854, partition: 0 },
	{ label: "EIARD.V", value: 2236409, partition: 0 },
	{ label: "GF1AW.V", value: 5383157, partition: 0 },
	{ label: "EIIQY.V", value: 2236410, partition: 0 },
	{ label: "IBAIA.V", value: 2106856, partition: 0 },
	{ label: "ISSDQ.V", value: 2106857, partition: 0 },
	{ label: "EI1PG.V", value: 2236413, partition: 0 },
	{ label: "FRATR.V", value: 3940782, partition: 0 },
	{ label: "FRSRC.V", value: 3940783, partition: 0 },
	{ label: "FRBDG.V", value: 3940785, partition: 0 },
	{ label: "FR1AP.V", value: 3940786, partition: 0 },
	{ label: "GRAIF.V", value: 1450459, partition: 0 },
	{ label: "DJIJH.V", value: 532061, partition: 0 },
	{ label: "DJBBO.V", value: 532063, partition: 0 },
	{ label: "HEIDT.V", value: 402519, partition: 0 },
	{ label: "HEBCS.V", value: 402520, partition: 0 },
	{ label: "EIBDK.V", value: 4726779, partition: 0 },
	{ label: "GEDIK.E", value: 74316, partition: 0 },
	{ label: "HE1AX.V", value: 402522, partition: 0 },
	{ label: "EI1PP.V", value: 4726781, partition: 0 },
	{ label: "ICBQH.V", value: 4597226, partition: 0 },
	{ label: "IS1PP.V", value: 4597227, partition: 0 },
	{ label: "GRAXK.V", value: 3940827, partition: 0 },
	{ label: "GASGF.V", value: 3940828, partition: 0 },
	{ label: "EYIRL.V", value: 2498450, partition: 0 },
	{ label: "GABPN.V", value: 3940829, partition: 0 },
	{ label: "GA1AP.V", value: 3940830, partition: 0 },
	{ label: "EKAWN.V", value: 2498452, partition: 0 },
	{ label: "ELIRH.V", value: 2236467, partition: 0 },
	{ label: "EDAWC.V", value: 2236468, partition: 0 },
	{ label: "EXIXY.V", value: 1974481, partition: 0 },
	{ label: "EDSGI.V", value: 2236470, partition: 0 },
	{ label: "HEAEJ.V", value: 2892886, partition: 0 },
	{ label: "HEBCW.V", value: 2892888, partition: 0 },
	{ label: "EUSED.V", value: 1974488, partition: 0 },
	{ label: "HESBJ.V", value: 2892889, partition: 0 },
	{ label: "EXSBL.V", value: 1974494, partition: 0 },
	{ label: "GNIKF.V", value: 336343, partition: 0 },
	{ label: "GEDZA.E", value: 74356, partition: 0 },
	{ label: "GENIL.E", value: 74361, partition: 0 },
	{ label: "GUBAZ.V", value: 1450524, partition: 0 },
	{ label: "GUSBH.V", value: 1450525, partition: 0 },
	{ label: "EYBDH.V", value: 4988822, partition: 0 },
	{ label: "GFBCU.V", value: 664564, partition: 0 },
	{ label: "EK1AT.V", value: 4988823, partition: 0 },
	{ label: "EXBRM.V", value: 4464847, partition: 0 },
	{ label: "EDSSP.V", value: 4726838, partition: 0 },
	{ label: "EDBDH.V", value: 4726839, partition: 0 },
	{ label: "ER1PQ.V", value: 4726840, partition: 0 },
	{ label: "GENTS.E", value: 74376, partition: 0 },
	{ label: "HE1PR.V", value: 5383258, partition: 0 },
	{ label: "GNIZC.V", value: 2826711, partition: 0 },
	{ label: "GUBQG.V", value: 3940892, partition: 0 },
	{ label: "GOSCU.V", value: 926581, partition: 0 },
	{ label: "GFADW.V", value: 3154931, partition: 0 },
	{ label: "GFBCY.V", value: 3154932, partition: 0 },
	{ label: "GF1PJ.V", value: 3154933, partition: 0 },
	{ label: "GFIAM.V", value: 3154934, partition: 0 },
	{ label: "EXBDD.V", value: 6955215, partition: 0 },
	{ label: "EDSSL.V", value: 7217206, partition: 0 },
	{ label: "FRAEK.V", value: 1712558, partition: 0 },
	{ label: "FRSEW.V", value: 1712559, partition: 0 },
	{ label: "FRIEJ.V", value: 1712560, partition: 0 },
	{ label: "FR1AH.V", value: 1712562, partition: 0 },
	{ label: "GEREL.E", value: 74416, partition: 0 },
	{ label: "GESAN.E", value: 74426, partition: 0 },
	{ label: "GIPTA.E", value: 74431, partition: 0 },
	{ label: "GCBBN.V", value: 3416951, partition: 0 },
	{ label: "ICITU.V", value: 2368998, partition: 0 },
	{ label: "EIARF.V", value: 2498553, partition: 0 },
	{ label: "GF1PS.V", value: 5645301, partition: 0 },
	{ label: "EIIRA.V", value: 2498554, partition: 0 },
	{ label: "GLBMD.E", value: 74436, partition: 0 },
	{ label: "IBAWS.V", value: 2369000, partition: 0 },
	{ label: "ISSDS.V", value: 2369001, partition: 0 },
	{ label: "EI1PI.V", value: 2498557, partition: 0 },
	{ label: "IS1AI.V", value: 2369003, partition: 0 },
	{ label: "FRBDI.V", value: 4202929, partition: 0 },
	{ label: "FR1AR.V", value: 4202930, partition: 0 },
	{ label: "GLCVY.E", value: 74446, partition: 0 },
	{ label: "DJIVC.V", value: 794205, partition: 0 },
	{ label: "GLDTR.F", value: 74456, partition: 0 },
	{ label: "DJBBQ.V", value: 794207, partition: 0 },
	{ label: "EYBCV.V", value: 270230, partition: 0 },
	{ label: "EK1AW.V", value: 270231, partition: 0 },
	{ label: "HEIDV.V", value: 664663, partition: 0 },
	{ label: "HEBCU.V", value: 664664, partition: 0 },
	{ label: "HE1AZ.V", value: 664666, partition: 0 },
	{ label: "ICBDH.V", value: 4859370, partition: 0 },
	{ label: "IS1AS.V", value: 4859371, partition: 0 },
	{ label: "GLRYH.E", value: 74476, partition: 0 },
	{ label: "GASSB.V", value: 4202972, partition: 0 },
	{ label: "EYIRN.V", value: 2760594, partition: 0 },
	{ label: "GABDD.V", value: 4202973, partition: 0 },
	{ label: "GA1AR.V", value: 4202974, partition: 0 },
	{ label: "EKAWP.V", value: 2760596, partition: 0 },
	{ label: "ELIQY.V", value: 2498611, partition: 0 },
	{ label: "EDAWE.V", value: 2498612, partition: 0 },
	{ label: "EXIZW.V", value: 2236625, partition: 0 },
	{ label: "GLYHO.E", value: 74496, partition: 0 },
	{ label: "ER1AJ.V", value: 2498616, partition: 0 },
	{ label: "HEAEL.V", value: 3155030, partition: 0 },
	{ label: "HEBCY.V", value: 3155032, partition: 0 },
	{ label: "EUSEF.V", value: 2236632, partition: 0 },
	{ label: "HESPR.V", value: 3155033, partition: 0 },
	{ label: "EXSBN.V", value: 2236638, partition: 0 },
	{ label: "GNIKH.V", value: 598487, partition: 0 },
	{ label: "GMSTR.F", value: 74516, partition: 0 },
	{ label: "GUBBB.V", value: 1712668, partition: 0 },
	{ label: "GUSBJ.V", value: 1712669, partition: 0 },
	{ label: "EKSQA.V", value: 5250965, partition: 0 },
	{ label: "EYBDJ.V", value: 5250966, partition: 0 },
	{ label: "EK1AV.V", value: 5250967, partition: 0 },
	{ label: "GMTAS.E", value: 74526, partition: 0 },
	{ label: "EXBRO.V", value: 4726991, partition: 0 },
	{ label: "EDSSR.V", value: 4988982, partition: 0 },
	{ label: "EDBDJ.V", value: 4988983, partition: 0 },
	{ label: "ER1AT.V", value: 4988984, partition: 0 },
	{ label: "HE1PT.V", value: 5645402, partition: 0 },
	{ label: "GOKNR.E", value: 74546, partition: 0 },
	{ label: "GUBQI.V", value: 4203036, partition: 0 },
	{ label: "GOSCW.V", value: 1188725, partition: 0 },
	{ label: "GFADY.V", value: 3417075, partition: 0 },
	{ label: "GFBDA.V", value: 3417076, partition: 0 },
	{ label: "GF1PL.V", value: 3417077, partition: 0 },
	{ label: "EIIBT.V", value: 270330, partition: 0 },
	{ label: "GFIAO.V", value: 3417078, partition: 0 },
	{ label: "EIBCR.V", value: 270331, partition: 0 },
	{ label: "EXBDF.V", value: 7217359, partition: 0 },
	{ label: "EISBC.V", value: 270332, partition: 0 },
	{ label: "FRAEM.V", value: 1974702, partition: 0 },
	{ label: "FRSEY.V", value: 1974703, partition: 0 },
	{ label: "FRIEL.V", value: 1974704, partition: 0 },
	{ label: "FR1PE.V", value: 1974706, partition: 0 },
	{ label: "GOLTS.E", value: 74576, partition: 0 },
	{ label: "EKAWU.V", value: 4136852, partition: 0 },
	{ label: "GCBQK.V", value: 3679095, partition: 0 },
	{ label: "ICITW.V", value: 2631142, partition: 0 },
	{ label: "EIARH.V", value: 2760697, partition: 0 },
	{ label: "GF1PU.V", value: 5907445, partition: 0 },
	{ label: "EIIRC.V", value: 2760698, partition: 0 },
	{ label: "IBAWU.V", value: 2631144, partition: 0 },
	{ label: "EIBCV.V", value: 2760699, partition: 0 },
	{ label: "EI1AK.V", value: 2760701, partition: 0 },
	{ label: "IS1AK.V", value: 2631147, partition: 0 },
	{ label: "GOODY.E", value: 74596, partition: 0 },
	{ label: "FRBDK.V", value: 4465073, partition: 0 },
	{ label: "FR1PN.V", value: 4465074, partition: 0 },
	{ label: "GASFT.V", value: 1974748, partition: 0 },
	{ label: "EYICF.V", value: 532370, partition: 0 },
	{ label: "DJIVE.V", value: 1056349, partition: 0 },
	{ label: "DJSEH.V", value: 1056350, partition: 0 },
	{ label: "DJBQN.V", value: 1056351, partition: 0 },
	{ label: "EYBCX.V", value: 532374, partition: 0 },
	{ label: "EK1AY.V", value: 532375, partition: 0 },
	{ label: "GOZDE.E", value: 74616, partition: 0 },
	{ label: "HEISR.V", value: 926807, partition: 0 },
	{ label: "HE1PV.V", value: 926810, partition: 0 },
	{ label: "ICBDJ.V", value: 5121514, partition: 0 },
	{ label: "IS1AU.V", value: 5121515, partition: 0 },
	{ label: "FRBDO.V", value: 6955441, partition: 0 },
	{ label: "GRNYO.E", value: 74636, partition: 0 },
	{ label: "GABDF.V", value: 4465117, partition: 0 },
	{ label: "GA1PO.V", value: 4465118, partition: 0 },
	{ label: "EKAHR.V", value: 3022740, partition: 0 },
	{ label: "EKSDC.V", value: 3022741, partition: 0 },
	{ label: "ELIRA.V", value: 2760755, partition: 0 },
	{ label: "EXBCH.V", value: 2498767, partition: 0 },
	{ label: "EDAWG.V", value: 2760756, partition: 0 },
	{ label: "ER1AL.V", value: 2760760, partition: 0 },
	{ label: "HEAEN.V", value: 3417174, partition: 0 },
	{ label: "GSDDE.E", value: 74656, partition: 0 },
	{ label: "HEBDA.V", value: 3417176, partition: 0 },
	{ label: "HE1AM.V", value: 3417178, partition: 0 },
	{ label: "GNIKJ.V", value: 860631, partition: 0 },
	{ label: "EXSPQ.V", value: 2498782, partition: 0 },
	{ label: "GUBPZ.V", value: 1974812, partition: 0 },
	{ label: "GSDHO.E", value: 74676, partition: 0 },
	{ label: "EYBDL.V", value: 5513110, partition: 0 },
	{ label: "EK1PS.V", value: 5513111, partition: 0 },
	{ label: "EXBCY.V", value: 4989135, partition: 0 },
	{ label: "EDBDL.V", value: 5251127, partition: 0 },
	{ label: "ER1AV.V", value: 5251128, partition: 0 },
	{ label: "HE1PY.V", value: 5907546, partition: 0 },
	{ label: "GSRAY.E", value: 74696, partition: 0 },
	{ label: "DNISI.E", value: 204258, partition: 0 },
	{ label: "GOSCY.V", value: 1450869, partition: 0 },
	{ label: "GFASV.V", value: 3679219, partition: 0 },
	{ label: "GFBDC.V", value: 3679220, partition: 0 },
	{ label: "ICIEP.V", value: 402918, partition: 0 },
	{ label: "EIIBV.V", value: 532474, partition: 0 },
	{ label: "GF1AN.V", value: 3679221, partition: 0 },
	{ label: "IBAHX.V", value: 402920, partition: 0 },
	{ label: "EIBCT.V", value: 532475, partition: 0 },
	{ label: "GFIPL.V", value: 3679222, partition: 0 },
	{ label: "EXBDH.V", value: 7479503, partition: 0 },
	{ label: "EISBE.V", value: 532476, partition: 0 },
	{ label: "ICBCX.V", value: 402922, partition: 0 },
	{ label: "FRATJ.V", value: 2236846, partition: 0 },
	{ label: "FRSFC.V", value: 2236847, partition: 0 },
	{ label: "FRITJ.V", value: 2236848, partition: 0 },
	{ label: "FR1PG.V", value: 2236850, partition: 0 },
	{ label: "GUBRF.E", value: 74736, partition: 0 },
	{ label: "GCBQM.V", value: 3941239, partition: 0 },
	{ label: "EIACJ.V", value: 3022841, partition: 0 },
	{ label: "IBAIB.V", value: 2893288, partition: 0 },
	{ label: "EIBCX.V", value: 3022843, partition: 0 },
	{ label: "EI1AM.V", value: 3022845, partition: 0 },
	{ label: "IS1AM.V", value: 2893291, partition: 0 },
	{ label: "FRBDM.V", value: 4727217, partition: 0 },
	{ label: "FR1PP.V", value: 4727218, partition: 0 },
	{ label: "GWIND.E", value: 74761, partition: 0 },
	{ label: "GRAXC.V", value: 2236891, partition: 0 },
	{ label: "GASFV.V", value: 2236892, partition: 0 },
	{ label: "DOFER.E", value: 204318, partition: 0 },
	{ label: "EYICH.V", value: 794514, partition: 0 },
	{ label: "DJIJJ.V", value: 1318493, partition: 0 },
	{ label: "DJSEJ.V", value: 1318494, partition: 0 },
	{ label: "EYBCZ.V", value: 794518, partition: 0 },
	{ label: "EK1BA.V", value: 794519, partition: 0 },
	{ label: "XOSAR.V", value: 270542, partition: 0 },
	{ label: "ELIBY.V", value: 532531, partition: 0 },
	{ label: "EXBCQ.V", value: 270543, partition: 0 },
	{ label: "GZNMI.E", value: 74771, partition: 0 },
	{ label: "EDBCX.V", value: 532535, partition: 0 },
	{ label: "HALKB.E", value: 74776, partition: 0 },
	{ label: "HEIST.V", value: 1188951, partition: 0 },
	{ label: "EUBBI.V", value: 270553, partition: 0 },
	{ label: "HE1PX.V", value: 1188954, partition: 0 },
	{ label: "ICBDL.V", value: 5383658, partition: 0 },
	{ label: "IS1AW.V", value: 5383659, partition: 0 },
	{ label: "FRBPD.V", value: 7217585, partition: 0 },
	{ label: "EXSBB.V", value: 270558, partition: 0 },
	{ label: "EXGAA.V", value: 270563, partition: 0 },
	{ label: "GABDH.V", value: 4727261, partition: 0 },
	{ label: "GA1PQ.V", value: 4727262, partition: 0 },
	{ label: "EKAHT.V", value: 3284884, partition: 0 },
	{ label: "EKSDI.V", value: 3284885, partition: 0 },
	{ label: "DJBQQ.V", value: 3808863, partition: 0 },
	{ label: "ELIRC.V", value: 3022899, partition: 0 },
	{ label: "EXBCJ.V", value: 2760911, partition: 0 },
	{ label: "EDAHH.V", value: 3022900, partition: 0 },
	{ label: "ER1PI.V", value: 3022904, partition: 0 },
	{ label: "HEATK.V", value: 3679318, partition: 0 },
	{ label: "HEBDC.V", value: 3679320, partition: 0 },
	{ label: "HE1AO.V", value: 3679322, partition: 0 },
	{ label: "HATEK.E", value: 74816, partition: 0 },
	{ label: "GNIYJ.V", value: 1122775, partition: 0 },
	{ label: "EXSPS.V", value: 2760926, partition: 0 },
	{ label: "HATSN.E", value: 74821, partition: 0 },
	{ label: "HDFGS.E", value: 74826, partition: 0 },
	{ label: "GUBQB.V", value: 2236956, partition: 0 },
	{ label: "HEDEF.E", value: 74831, partition: 0 },
	{ label: "EYBDN.V", value: 5775254, partition: 0 },
	{ label: "EK1PU.V", value: 5775255, partition: 0 },
	{ label: "HEKTS.E", value: 74836, partition: 0 },
	{ label: "EXBRQ.V", value: 5251279, partition: 0 },
	{ label: "EDBDN.V", value: 5513271, partition: 0 },
	{ label: "ER1PR.V", value: 5513272, partition: 0 },
	{ label: "LIDER.E", value: 207288, partition: 0 },
	{ label: "KOIRE.V", value: 2369418, partition: 0 },
	{ label: "SANFM.E", value: 77736, partition: 0 },
	{ label: "NVBDM.V", value: 7744514, partition: 0 },
	{ label: "NXBBR.V", value: 273510, partition: 0 },
	{ label: "KHAWS.V", value: 2369422, partition: 0 },
	{ label: "KZSEC.V", value: 4335781, partition: 0 },
	{ label: "KZBDC.V", value: 4335782, partition: 0 },
	{ label: "KC1AI.V", value: 2369425, partition: 0 },
	{ label: "KZ1PO.V", value: 4335783, partition: 0 },
	{ label: "SSIMF.V", value: 929926, partition: 0 },
	{ label: "SSBDA.V", value: 929930, partition: 0 },
	{ label: "KDSFV.V", value: 2959625, partition: 0 },
	{ label: "SPSRK.V", value: 4730215, partition: 0 },
	{ label: "PWSCT.V", value: 1715902, partition: 0 },
	{ label: "PDBQE.V", value: 2239882, partition: 0 },
	{ label: "SANKO.E", value: 77756, partition: 0 },
	{ label: "PPIBO.V", value: 1715912, partition: 0 },
	{ label: "PBAGP.V", value: 1715914, partition: 0 },
	{ label: "PBSCX.V", value: 1715915, partition: 0 },
	{ label: "NXSBZ.V", value: 2763877, partition: 0 },
	{ label: "SOIRC.V", value: 1453934, partition: 0 },
	{ label: "KC1AS.V", value: 4859793, partition: 0 },
	{ label: "NOBQI.V", value: 2763881, partition: 0 },
	{ label: "SARKY.E", value: 77776, partition: 0 },
	{ label: "SIAHI.V", value: 3420296, partition: 0 },
	{ label: "SA1AF.V", value: 1453939, partition: 0 },
	{ label: "SISGD.V", value: 3420297, partition: 0 },
	{ label: "KDSRW.V", value: 5449993, partition: 0 },
	{ label: "SSBDG.V", value: 3420298, partition: 0 },
	{ label: "SI1PK.V", value: 3420299, partition: 0 },
	{ label: "OYBCK.V", value: 2501898, partition: 0 },
	{ label: "NABCW.V", value: 535542, partition: 0 },
	{ label: "OYIQB.V", value: 2501899, partition: 0 },
	{ label: "OY1AJ.V", value: 2501900, partition: 0 },
	{ label: "SPSRL.V", value: 7220583, partition: 0 },
	{ label: "PTIUI.V", value: 2239914, partition: 0 },
	{ label: "PEAWR.V", value: 2239916, partition: 0 },
	{ label: "PESFT.V", value: 2239917, partition: 0 },
	{ label: "OD1AL.V", value: 2763897, partition: 0 },
	{ label: "PPBDM.V", value: 4206278, partition: 0 },
	{ label: "NVBCX.V", value: 535554, partition: 0 },
	{ label: "KTIAJ.V", value: 2107488, partition: 0 },
	{ label: "SASA.E", value: 77796, partition: 0 },
	{ label: "PBSQP.V", value: 4206283, partition: 0 },
	{ label: "PLBBH.V", value: 1977938, partition: 0 },
	{ label: "PPBCX.V", value: 16395974, partition: 0 },
	{ label: "NXBQV.V", value: 5254246, partition: 0 },
	{ label: "SLIYJ.V", value: 929989, partition: 0 },
	{ label: "KDILN.V", value: 1845511, partition: 0 },
	{ label: "KRAGX.V", value: 1845512, partition: 0 },
	{ label: "SASRK.V", value: 3944304, partition: 0 },
	{ label: "SAARG.V", value: 3944305, partition: 0 },
	{ label: "SOBDI.V", value: 3944306, partition: 0 },
	{ label: "SL1PV.V", value: 929993, partition: 0 },
	{ label: "SA1AP.V", value: 3944307, partition: 0 },
	{ label: "NASFJ.V", value: 3025908, partition: 0 },
	{ label: "NAIRJ.V", value: 3025909, partition: 0 },
	{ label: "OY1AT.V", value: 4992268, partition: 0 },
	{ label: "SAYAS.E", value: 77816, partition: 0 },
	{ label: "PWBBR.V", value: 6696639, partition: 0 },
	{ label: "PDBBM.V", value: 7220618, partition: 0 },
	{ label: "SDTTR.E", value: 77821, partition: 0 },
	{ label: "PEBDH.V", value: 4730286, partition: 0 },
	{ label: "KTAAS.V", value: 4597852, partition: 0 },
	{ label: "OD1AV.V", value: 5254265, partition: 0 },
	{ label: "PE1AR.V", value: 4730287, partition: 0 },
	{ label: "PPBDT.V", value: 6696646, partition: 0 },
	{ label: "KT1PP.V", value: 4597855, partition: 0 },
	{ label: "NVSCD.V", value: 3025921, partition: 0 },
	{ label: "SEGMN.E", value: 77831, partition: 0 },
	{ label: "PPBSE.V", value: 18886342, partition: 0 },
	{ label: "SEKFK.E", value: 77836, partition: 0 },
	{ label: "PGIXY.V", value: 2239967, partition: 0 },
	{ label: "SLAFI.V", value: 3420358, partition: 0 },
	{ label: "PGAUM.V", value: 2239968, partition: 0 },
	{ label: "KRBQF.V", value: 4335882, partition: 0 },
	{ label: "SLBDA.V", value: 3420360, partition: 0 },
	{ label: "PGSPN.V", value: 2239970, partition: 0 },
	{ label: "KR1PO.V", value: 4335883, partition: 0 },
	{ label: "SL1AM.V", value: 3420361, partition: 0 },
	{ label: "PG1PG.V", value: 2239971, partition: 0 },
	{ label: "RHBRI.V", value: 4206330, partition: 0 },
	{ label: "NASRU.V", value: 5516276, partition: 0 },
	{ label: "PWBRE.V", value: 9187007, partition: 0 },
	{ label: "SEKUR.E", value: 77856, partition: 0 },
	{ label: "PPBTA.V", value: 9187014, partition: 0 },
	{ label: "NVSPY.V", value: 5516289, partition: 0 },
	{ label: "KZILH.V", value: 2107555, partition: 0 },
	{ label: "KZAGD.V", value: 2107556, partition: 0 },
	{ label: "KZSDX.V", value: 2107557, partition: 0 },
	{ label: "MANAS.E", value: 207418, partition: 0 },
	{ label: "PLBBK.V", value: 6958674, partition: 0 },
	{ label: "PGBDK.V", value: 4730337, partition: 0 },
	{ label: "SPSFF.V", value: 2501991, partition: 0 },
	{ label: "SL1PY.V", value: 5910729, partition: 0 },
	{ label: "PG1PP.V", value: 4730339, partition: 0 },
	{ label: "SELEC.E", value: 77876, partition: 0 },
	{ label: "RHBDB.V", value: 6696698, partition: 0 },
	{ label: "NABSF.V", value: 8006646, partition: 0 },
	{ label: "MSBQL.V", value: 3811945, partition: 0 },
	{ label: "MARBL.E", value: 207438, partition: 0 },
	{ label: "KOIRG.V", value: 2631562, partition: 0 },
	{ label: "NVBDO.V", value: 8006658, partition: 0 },
	{ label: "NXBBT.V", value: 535654, partition: 0 },
	{ label: "KHAWU.V", value: 2631566, partition: 0 },
	{ label: "KZSRA.V", value: 4597925, partition: 0 },
	{ label: "KZBDE.V", value: 4597926, partition: 0 },
	{ label: "SELGD.E", value: 77896, partition: 0 },
	{ label: "KC1AK.V", value: 2631569, partition: 0 },
	{ label: "KZ1PQ.V", value: 4597927, partition: 0 },
	{ label: "SSIMH.V", value: 1192070, partition: 0 },
	{ label: "SISFT.V", value: 1192073, partition: 0 },
	{ label: "SSBDC.V", value: 1192074, partition: 0 },
	{ label: "KDSFX.V", value: 3221769, partition: 0 },
	{ label: "OYIAU.V", value: 273675, partition: 0 },
	{ label: "SPSRP.V", value: 4992359, partition: 0 },
	{ label: "SELVA.E", value: 77906, partition: 0 },
	{ label: "PWSCV.V", value: 1978046, partition: 0 },
	{ label: "PDBQG.V", value: 2502026, partition: 0 },
	{ label: "PPIBQ.V", value: 1978056, partition: 0 },
	{ label: "PBAVM.V", value: 1978058, partition: 0 },
	{ label: "PBSCZ.V", value: 1978059, partition: 0 },
	{ label: "NXSPU.V", value: 3026021, partition: 0 },
	{ label: "SEYKM.E", value: 77926, partition: 0 },
	{ label: "SOICE.V", value: 1716078, partition: 0 },
	{ label: "KC1AU.V", value: 5121937, partition: 0 },
	{ label: "NOBBK.V", value: 3026025, partition: 0 },
	{ label: "SAABZ.V", value: 1716081, partition: 0 },
	{ label: "SIAWE.V", value: 3682440, partition: 0 },
	{ label: "SA1AH.V", value: 1716083, partition: 0 },
	{ label: "SISGF.V", value: 3682441, partition: 0 },
	{ label: "SSBDI.V", value: 3682442, partition: 0 },
	{ label: "SI1PM.V", value: 3682443, partition: 0 },
	{ label: "OYBCM.V", value: 2764042, partition: 0 },
	{ label: "SILVR.E", value: 77936, partition: 0 },
	{ label: "NABCY.V", value: 797686, partition: 0 },
	{ label: "OYIQD.V", value: 2764043, partition: 0 },
	{ label: "OY1AL.V", value: 2764044, partition: 0 },
	{ label: "MEKAG.E", value: 207493, partition: 0 },
	{ label: "SPSRN.V", value: 7482727, partition: 0 },
	{ label: "PTIUK.V", value: 2502058, partition: 0 },
	{ label: "PEAWT.V", value: 2502060, partition: 0 },
	{ label: "PESFV.V", value: 2502061, partition: 0 },
	{ label: "OD1PI.V", value: 3026041, partition: 0 },
	{ label: "PPBDO.V", value: 4468422, partition: 0 },
	{ label: "KT1AI.V", value: 2369631, partition: 0 },
	{ label: "NVBCZ.V", value: 797698, partition: 0 },
	{ label: "KTIPG.V", value: 2369632, partition: 0 },
	{ label: "PBSQR.V", value: 4468427, partition: 0 },
	{ label: "PLBQE.V", value: 2240082, partition: 0 },
	{ label: "SISE.E", value: 77956, partition: 0 },
	{ label: "PPBCZ.V", value: 16658118, partition: 0 },
	{ label: "SLIYL.V", value: 1192133, partition: 0 },
	{ label: "KDIMA.V", value: 2107655, partition: 0 },
	{ label: "KRAGZ.V", value: 2107656, partition: 0 },
	{ label: "SASRM.V", value: 4206448, partition: 0 },
	{ label: "KDSFQ.V", value: 2107657, partition: 0 },
	{ label: "SOBDK.V", value: 4206450, partition: 0 },
	{ label: "SL1PX.V", value: 1192137, partition: 0 },
	{ label: "SA1AR.V", value: 4206451, partition: 0 },
	{ label: "NASFL.V", value: 3288052, partition: 0 },
	{ label: "NAIRL.V", value: 3288053, partition: 0 },
	{ label: "OY1AV.V", value: 5254412, partition: 0 },
	{ label: "PWBBT.V", value: 6958783, partition: 0 },
	{ label: "PDBQJ.V", value: 7482762, partition: 0 },
	{ label: "SKBNK.E", value: 77976, partition: 0 },
	{ label: "PEBDJ.V", value: 4992430, partition: 0 },
	{ label: "KTAPP.V", value: 4859996, partition: 0 },
	{ label: "OD1PR.V", value: 5516409, partition: 0 },
	{ label: "PE1AT.V", value: 4992431, partition: 0 },
	{ label: "PPBDV.V", value: 6958790, partition: 0 },
	{ label: "KT1AS.V", value: 4859999, partition: 0 },
	{ label: "NVSPV.V", value: 3288065, partition: 0 },
	{ label: "MERCN.E", value: 207538, partition: 0 },
	{ label: "PGIWA.V", value: 2502111, partition: 0 },
	{ label: "SLAUF.V", value: 3682502, partition: 0 },
	{ label: "KRBQH.V", value: 4598026, partition: 0 },
	{ label: "SLBDC.V", value: 3682504, partition: 0 },
	{ label: "PGSBF.V", value: 2502114, partition: 0 },
	{ label: "KR1PQ.V", value: 4598027, partition: 0 },
	{ label: "SL1AO.V", value: 3682505, partition: 0 },
	{ label: "PG1PI.V", value: 2502115, partition: 0 },
	{ label: "RHBRK.V", value: 4468474, partition: 0 },
	{ label: "PWBRG.V", value: 9449151, partition: 0 },
	{ label: "SKYLP.E", value: 78011, partition: 0 },
	{ label: "KOIBZ.V", value: 403338, partition: 0 },
	{ label: "MTBQN.V", value: 3812075, partition: 0 },
	{ label: "PPBTC.V", value: 9449158, partition: 0 },
	{ label: "NVBRT.V", value: 5778434, partition: 0 },
	{ label: "KZIZA.V", value: 2369699, partition: 0 },
	{ label: "SKTAS.E", value: 78016, partition: 0 },
	{ label: "KHBCX.V", value: 403343, partition: 0 },
	{ label: "KZAVA.V", value: 2369700, partition: 0 },
	{ label: "KHSAU.V", value: 403344, partition: 0 },
	{ label: "KZSDZ.V", value: 2369701, partition: 0 },
	{ label: "PLBBM.V", value: 7220818, partition: 0 },
	{ label: "SPSFH.V", value: 2764135, partition: 0 },
	{ label: "PDBAZ.V", value: 273802, partition: 0 },
	{ label: "RHBRT.V", value: 6958842, partition: 0 },
	{ label: "NABSH.V", value: 8268790, partition: 0 },
	{ label: "MSBQN.V", value: 4074089, partition: 0 },
	{ label: "NVBSC.V", value: 8268802, partition: 0 },
	{ label: "NXBBV.V", value: 797798, partition: 0 },
	{ label: "KHAHW.V", value: 2893710, partition: 0 },
	{ label: "KZSRC.V", value: 4860069, partition: 0 },
	{ label: "KZBDG.V", value: 4860070, partition: 0 },
	{ label: "KC1AM.V", value: 2893713, partition: 0 },
	{ label: "KZ1AS.V", value: 4860071, partition: 0 },
	{ label: "SSIQC.V", value: 1454214, partition: 0 },
	{ label: "SISFV.V", value: 1454217, partition: 0 },
	{ label: "KDSFZ.V", value: 3483913, partition: 0 },
	{ label: "SSBDE.V", value: 1454218, partition: 0 },
	{ label: "SI1AF.V", value: 1454219, partition: 0 },
	{ label: "OYIAW.V", value: 535819, partition: 0 },
	{ label: "SPSRR.V", value: 5254503, partition: 0 },
	{ label: "PTIFF.V", value: 273834, partition: 0 },
	{ label: "PDBQI.V", value: 2764170, partition: 0 },
	{ label: "PEAHW.V", value: 273836, partition: 0 },
	{ label: "PE1AW.V", value: 273839, partition: 0 },
	{ label: "PPIQO.V", value: 2240200, partition: 0 },
	{ label: "PBAVO.V", value: 2240202, partition: 0 },
	{ label: "SOICG.V", value: 1978222, partition: 0 },
	{ label: "KZSQX.V", value: 7350437, partition: 0 },
	{ label: "KC1AW.V", value: 5384081, partition: 0 },
	{ label: "NOBBM.V", value: 3288169, partition: 0 },
	{ label: "SASFD.V", value: 1978224, partition: 0 },
	{ label: "SAACB.V", value: 1978225, partition: 0 },
	{ label: "SIAWG.V", value: 3944584, partition: 0 },
	{ label: "SA1PE.V", value: 1978227, partition: 0 },
	{ label: "SISSC.V", value: 3944585, partition: 0 },
	{ label: "SSBDK.V", value: 3944586, partition: 0 },
	{ label: "SI1AP.V", value: 3944587, partition: 0 },
	{ label: "NABDA.V", value: 1059830, partition: 0 },
	{ label: "OY1PI.V", value: 3026188, partition: 0 },
	{ label: "PTIUM.V", value: 2764202, partition: 0 },
	{ label: "PEAWV.V", value: 2764204, partition: 0 },
	{ label: "MHRGY.E", value: 207653, partition: 0 },
	{ label: "PESRV.V", value: 2764205, partition: 0 },
	{ label: "OD1PK.V", value: 3288185, partition: 0 },
	{ label: "PPBDQ.V", value: 4730566, partition: 0 },
	{ label: "KT1AK.V", value: 2631775, partition: 0 },
	{ label: "MIATK.E", value: 207658, partition: 0 },
	{ label: "NVBDB.V", value: 1059842, partition: 0 },
	{ label: "KTIPI.V", value: 2631776, partition: 0 },
	{ label: "PLBQG.V", value: 2502226, partition: 0 },
	{ label: "PPBDB.V", value: 16920262, partition: 0 },
	{ label: "PGIAP.V", value: 273887, partition: 0 },
	{ label: "SLIYN.V", value: 1454277, partition: 0 },
	{ label: "KDIZO.V", value: 2369799, partition: 0 },
	{ label: "KRAVW.V", value: 2369800, partition: 0 },
	{ label: "PGBCR.V", value: 273889, partition: 0 },
	{ label: "SOBDM.V", value: 4468594, partition: 0 },
	{ label: "SA1PN.V", value: 4468595, partition: 0 },
	{ label: "NASFN.V", value: 3550196, partition: 0 },
	{ label: "OY1PR.V", value: 5516556, partition: 0 },
	{ label: "PWBBV.V", value: 7220927, partition: 0 },
	{ label: "PDBQL.V", value: 7744906, partition: 0 },
	{ label: "PEBDL.V", value: 5254574, partition: 0 },
	{ label: "KTAPR.V", value: 5122140, partition: 0 },
	{ label: "OD1PT.V", value: 5778553, partition: 0 },
	{ label: "PE1AV.V", value: 5254575, partition: 0 },
	{ label: "MTSBT.V", value: 1583850, partition: 0 },
	{ label: "PPBDX.V", value: 7220934, partition: 0 },
	{ label: "KT1AU.V", value: 5122143, partition: 0 },
	{ label: "NVSCJ.V", value: 3550209, partition: 0 },
	{ label: "PGIWC.V", value: 2764255, partition: 0 },
	{ label: "SLAUH.V", value: 3944646, partition: 0 },
	{ label: "PGAUO.V", value: 2764256, partition: 0 },
	{ label: "SLSQU.V", value: 3944647, partition: 0 },
	{ label: "PGBCV.V", value: 2764257, partition: 0 },
	{ label: "KRBDI.V", value: 4860170, partition: 0 },
	{ label: "SLBDE.V", value: 3944648, partition: 0 },
	{ label: "PGSBH.V", value: 2764258, partition: 0 },
	{ label: "KR1AS.V", value: 4860171, partition: 0 },
	{ label: "SL1AQ.V", value: 3944649, partition: 0 },
	{ label: "PG1AK.V", value: 2764259, partition: 0 },
	{ label: "RHBRM.V", value: 4730618, partition: 0 },
	{ label: "NABSA.V", value: 6040566, partition: 0 },
	{ label: "PPIBL.V", value: 3616456, partition: 0 },
	{ label: "MTSPU.V", value: 4074218, partition: 0 },
	{ label: "KOICB.V", value: 665482, partition: 0 },
	{ label: "MTBQP.V", value: 4074219, partition: 0 },
	{ label: "NVBRV.V", value: 6040578, partition: 0 },
	{ label: "KZIZC.V", value: 2631843, partition: 0 },
	{ label: "KHBCZ.V", value: 665487, partition: 0 },
	{ label: "KZAVC.V", value: 2631844, partition: 0 },
	{ label: "KHSAW.V", value: 665488, partition: 0 },
	{ label: "PLBQJ.V", value: 7482962, partition: 0 },
	{ label: "SPSFJ.V", value: 3026279, partition: 0 },
	{ label: "PDBBB.V", value: 535946, partition: 0 },
	{ label: "RHBRV.V", value: 7220986, partition: 0 },
	{ label: "NABSJ.V", value: 8530934, partition: 0 },
	{ label: "MSBBP.V", value: 4336233, partition: 0 },
	{ label: "NVBSE.V", value: 8530946, partition: 0 },
	{ label: "KHAHY.V", value: 3155854, partition: 0 },
	{ label: "NXBQO.V", value: 1059942, partition: 0 },
	{ label: "KZSRE.V", value: 5122213, partition: 0 },
	{ label: "KZBDI.V", value: 5122214, partition: 0 },
	{ label: "KC1PJ.V", value: 3155857, partition: 0 },
	{ label: "KZ1AU.V", value: 5122215, partition: 0 },
	{ label: "SSIQE.V", value: 1716358, partition: 0 },
	{ label: "SIAHA.V", value: 1716360, partition: 0 },
	{ label: "SISFX.V", value: 1716361, partition: 0 },
	{ label: "KDSGB.V", value: 3746057, partition: 0 },
	{ label: "SI1AH.V", value: 1716363, partition: 0 },
	{ label: "OYIAY.V", value: 797963, partition: 0 },
	{ label: "SPSRT.V", value: 5516647, partition: 0 },
	{ label: "PTIFH.V", value: 535978, partition: 0 },
	{ label: "KTAAV.V", value: 403548, partition: 0 },
	{ label: "PEBCX.V", value: 535982, partition: 0 },
	{ label: "PE1AY.V", value: 535983, partition: 0 },
	{ label: "PPIQQ.V", value: 2502344, partition: 0 },
	{ label: "PBAVQ.V", value: 2502346, partition: 0 },
	{ label: "PLBAZ.V", value: 274002, partition: 0 },
	{ label: "SOIRD.V", value: 2240366, partition: 0 },
	{ label: "KC1PS.V", value: 5646225, partition: 0 },
	{ label: "NOBQJ.V", value: 3550313, partition: 0 },
	{ label: "SASFF.V", value: 2240368, partition: 0 },
	{ label: "SAAQY.V", value: 2240369, partition: 0 },
	{ label: "SA1PG.V", value: 2240371, partition: 0 },
	{ label: "SSBDM.V", value: 4206730, partition: 0 },
	{ label: "SI1AR.V", value: 4206731, partition: 0 },
	{ label: "NAICE.V", value: 1321973, partition: 0 },
	{ label: "NABDC.V", value: 1321974, partition: 0 },
	{ label: "OY1PK.V", value: 3288332, partition: 0 },
	{ label: "MTRKS.E", value: 207803, partition: 0 },
	{ label: "PWBBW.V", value: 4992703, partition: 0 },
	{ label: "PEAIC.V", value: 3026348, partition: 0 },
	{ label: "PESFY.V", value: 3026349, partition: 0 },
	{ label: "OD1PM.V", value: 3550329, partition: 0 },
	{ label: "PPBSG.V", value: 4992710, partition: 0 },
	{ label: "KT1AM.V", value: 2893919, partition: 0 },
	{ label: "KTIAK.V", value: 2893920, partition: 0 },
	{ label: "NVBDD.V", value: 1321986, partition: 0 },
	{ label: "PLBQI.V", value: 2764370, partition: 0 },
	{ label: "PPBDD.V", value: 17182406, partition: 0 },
	{ label: "KDIQB.V", value: 2631943, partition: 0 },
	{ label: "PGIAR.V", value: 536031, partition: 0 },
	{ label: "SLILE.V", value: 1716421, partition: 0 },
	{ label: "KRAVY.V", value: 2631944, partition: 0 },
	{ label: "SLAFA.V", value: 1716422, partition: 0 },
	{ label: "PGBCT.V", value: 536033, partition: 0 },
	{ label: "SLSDE.V", value: 1716423, partition: 0 },
	{ label: "SOBDO.V", value: 4730738, partition: 0 },
	{ label: "SA1PP.V", value: 4730739, partition: 0 },
	{ label: "OY1PT.V", value: 5778700, partition: 0 },
	{ label: "PWBQR.V", value: 7483071, partition: 0 },
	{ label: "PDBQN.V", value: 8007050, partition: 0 },
	{ label: "PEBDN.V", value: 5516718, partition: 0 },
	{ label: "KTAPT.V", value: 5384284, partition: 0 },
	{ label: "PE1PS.V", value: 5516719, partition: 0 },
	{ label: "MTSBV.V", value: 1845994, partition: 0 },
	{ label: "PPBDZ.V", value: 7483078, partition: 0 },
	{ label: "KT1AW.V", value: 5384287, partition: 0 },
	{ label: "KZIKO.V", value: 403619, partition: 0 },
	{ label: "KZBCS.V", value: 403622, partition: 0 },
	{ label: "KZ1AX.V", value: 403623, partition: 0 },
	{ label: "PGAFQ.V", value: 3026400, partition: 0 },
	{ label: "SLSQY.V", value: 4206791, partition: 0 },
	{ label: "PGBCX.V", value: 3026401, partition: 0 },
	{ label: "KRBDK.V", value: 5122314, partition: 0 },
	{ label: "SLBDG.V", value: 4206792, partition: 0 },
	{ label: "PGSPQ.V", value: 3026402, partition: 0 },
	{ label: "KR1AU.V", value: 5122315, partition: 0 },
	{ label: "SL1PN.V", value: 4206793, partition: 0 },
	{ label: "PG1AM.V", value: 3026403, partition: 0 },
	{ label: "RHBRO.V", value: 4992762, partition: 0 },
	{ label: "NABSC.V", value: 6302710, partition: 0 },
	{ label: "PPIBN.V", value: 3878600, partition: 0 },
	{ label: "MTSPY.V", value: 4336362, partition: 0 },
	{ label: "KOIQY.V", value: 927626, partition: 0 },
	{ label: "MTBBT.V", value: 4336363, partition: 0 },
	{ label: "NATEN.E", value: 207878, partition: 0 },
	{ label: "NVBRX.V", value: 6302722, partition: 0 },
	{ label: "KZAGE.V", value: 2893988, partition: 0 },
	{ label: "KHBDB.V", value: 927631, partition: 0 },
	{ label: "KHSPM.V", value: 927632, partition: 0 },
	{ label: "KZBCW.V", value: 2893990, partition: 0 },
	{ label: "PLBQL.V", value: 7745106, partition: 0 },
	{ label: "SPSFL.V", value: 3288423, partition: 0 },
	{ label: "PWSCJ.V", value: 274110, partition: 0 },
	{ label: "PDBBD.V", value: 798090, partition: 0 },
	{ label: "RHBRX.V", value: 7483130, partition: 0 },
	{ label: "NABSL.V", value: 8793078, partition: 0 },
	{ label: "MSBBR.V", value: 4598377, partition: 0 },
	{ label: "PBAGH.V", value: 274122, partition: 0 },
	{ label: "PBSCO.V", value: 274123, partition: 0 },
	{ label: "NVBSG.V", value: 8793090, partition: 0 },
	{ label: "KHAIA.V", value: 3417998, partition: 0 },
	{ label: "NXBQQ.V", value: 1322086, partition: 0 },
	{ label: "KHBDF.V", value: 3417999, partition: 0 },
	{ label: "KHSAP.V", value: 3418000, partition: 0 },
	{ label: "KC1PL.V", value: 3418001, partition: 0 },
	{ label: "KZ1PR.V", value: 5384359, partition: 0 },
	{ label: "SSIQG.V", value: 1978502, partition: 0 },
	{ label: "SIAHC.V", value: 1978504, partition: 0 },
	{ label: "SISGA.V", value: 1978505, partition: 0 },
	{ label: "KDSRY.V", value: 4008201, partition: 0 },
	{ label: "OYBBZ.V", value: 1060106, partition: 0 },
	{ label: "SI1PE.V", value: 1978507, partition: 0 },
	{ label: "OYIPV.V", value: 1060107, partition: 0 },
	{ label: "PTIFJ.V", value: 798122, partition: 0 },
	{ label: "KTAAX.V", value: 665692, partition: 0 },
	{ label: "PEBCZ.V", value: 798126, partition: 0 },
	{ label: "PE1BA.V", value: 798127, partition: 0 },
	{ label: "PLBBB.V", value: 536146, partition: 0 },
	{ label: "SOIRF.V", value: 2502510, partition: 0 },
	{ label: "KDILH.V", value: 403719, partition: 0 },
	{ label: "KC1PU.V", value: 5908369, partition: 0 },
	{ label: "NOBQL.V", value: 3812457, partition: 0 },
	{ label: "SASFH.V", value: 2502512, partition: 0 },
	{ label: "SAARA.V", value: 2502513, partition: 0 },
	{ label: "KR1AX.V", value: 403723, partition: 0 },
	{ label: "SA1PI.V", value: 2502515, partition: 0 },
	{ label: "SSBDO.V", value: 4468874, partition: 0 },
	{ label: "NASEY.V", value: 1584116, partition: 0 },
	{ label: "SI1PN.V", value: 4468875, partition: 0 },
	{ label: "NAICG.V", value: 1584117, partition: 0 },
	{ label: "NABRU.V", value: 1584118, partition: 0 },
	{ label: "OY1PM.V", value: 3550476, partition: 0 },
	{ label: "PWBBY.V", value: 5254847, partition: 0 },
	{ label: "PEAIE.V", value: 3288492, partition: 0 },
	{ label: "PESGA.V", value: 3288493, partition: 0 },
	{ label: "OD1AO.V", value: 3812473, partition: 0 },
	{ label: "PPBSI.V", value: 5254854, partition: 0 },
	{ label: "KT1PJ.V", value: 3156063, partition: 0 },
	{ label: "NVSBX.V", value: 1584129, partition: 0 },
	{ label: "KTIAM.V", value: 3156064, partition: 0 },
	{ label: "NVBRR.V", value: 1584130, partition: 0 },
	{ label: "PPBDF.V", value: 17444550, partition: 0 },
	{ label: "OBASE.E", value: 207978, partition: 0 },
	{ label: "JANTS.E", value: 75546, partition: 0 },
	{ label: "PGIAT.V", value: 798175, partition: 0 },
	{ label: "SLILG.V", value: 1978565, partition: 0 },
	{ label: "KRAHA.V", value: 2894088, partition: 0 },
	{ label: "SLAFC.V", value: 1978566, partition: 0 },
	{ label: "SLSDG.V", value: 1978567, partition: 0 },
	{ label: "KAPLM.E", value: 75556, partition: 0 },
	{ label: "PWBQT.V", value: 7745215, partition: 0 },
	{ label: "PEBDP.V", value: 5778862, partition: 0 },
	{ label: "OFSYM.E", value: 207997, partition: 0 },
	{ label: "PE1PU.V", value: 5778863, partition: 0 },
	{ label: "MTSBX.V", value: 2108138, partition: 0 },
	{ label: "PPBEB.V", value: 7745222, partition: 0 },
	{ label: "KT1PS.V", value: 5646431, partition: 0 },
	{ label: "KZILB.V", value: 665763, partition: 0 },
	{ label: "KZBCU.V", value: 665766, partition: 0 },
	{ label: "KZ1AZ.V", value: 665767, partition: 0 },
	{ label: "KAREL.E", value: 75576, partition: 0 },
	{ label: "PGAFS.V", value: 3288544, partition: 0 },
	{ label: "PGBCZ.V", value: 3288545, partition: 0 },
	{ label: "KRBDM.V", value: 5384458, partition: 0 },
	{ label: "SLBDI.V", value: 4468936, partition: 0 },
	{ label: "KR1PR.V", value: 5384459, partition: 0 },
	{ label: "SL1PP.V", value: 4468937, partition: 0 },
	{ label: "PG1PJ.V", value: 3288547, partition: 0 },
	{ label: "RHBRQ.V", value: 5254906, partition: 0 },
	{ label: "NABDE.V", value: 6564854, partition: 0 },
	{ label: "ORCAY.E", value: 208023, partition: 0 },
	{ label: "PPIQL.V", value: 4140744, partition: 0 },
	{ label: "KARSN.E", value: 75596, partition: 0 },
	{ label: "KOIRA.V", value: 1189770, partition: 0 },
	{ label: "MTBBV.V", value: 4598507, partition: 0 },
	{ label: "ONRYT.E", value: 208033, partition: 0 },
	{ label: "NVBRZ.V", value: 6564866, partition: 0 },
	{ label: "KZAGG.V", value: 3156132, partition: 0 },
	{ label: "KHBDD.V", value: 1189775, partition: 0 },
	{ label: "KZBCY.V", value: 3156134, partition: 0 },
	{ label: "PLBQN.V", value: 8007250, partition: 0 },
	{ label: "SPSFN.V", value: 3550567, partition: 0 },
	{ label: "PWSCL.V", value: 536254, partition: 0 },
	{ label: "KARTN.E", value: 75616, partition: 0 },
	{ label: "PDBQA.V", value: 1060234, partition: 0 },
	{ label: "RHBRZ.V", value: 7745274, partition: 0 },
	{ label: "NABSN.V", value: 9055222, partition: 0 },
	{ label: "MSBQO.V", value: 4860521, partition: 0 },
	{ label: "PBAGJ.V", value: 536266, partition: 0 },
	{ label: "PBSCQ.V", value: 536267, partition: 0 },
	{ label: "NVBSI.V", value: 9055234, partition: 0 },
	{ label: "NXSBQ.V", value: 1584229, partition: 0 },
	{ label: "KHAWX.V", value: 3680142, partition: 0 },
	{ label: "NXBBW.V", value: 1584230, partition: 0 },
	{ label: "KHBDH.V", value: 3680143, partition: 0 },
	{ label: "KHSAR.V", value: 3680144, partition: 0 },
	{ label: "KATMR.E", value: 75636, partition: 0 },
	{ label: "KC1AN.V", value: 3680145, partition: 0 },
	{ label: "NOBBE.V", value: 1584233, partition: 0 },
	{ label: "KZ1PT.V", value: 5646503, partition: 0 },
	{ label: "SSIZM.V", value: 2240646, partition: 0 },
	{ label: "SOBCV.V", value: 274290, partition: 0 },
	{ label: "SIAVY.V", value: 2240648, partition: 0 },
	{ label: "SISGC.V", value: 2240649, partition: 0 },
	{ label: "KAYSE.E", value: 75641, partition: 0 },
	{ label: "SI1PG.V", value: 2240651, partition: 0 },
	{ label: "OYBCB.V", value: 1322250, partition: 0 },
	{ label: "OYIPX.V", value: 1322251, partition: 0 },
	{ label: "KCAER.E", value: 75646, partition: 0 },
	{ label: "PWSQD.V", value: 3026622, partition: 0 },
	{ label: "PTIUE.V", value: 1060266, partition: 0 },
	{ label: "PESFQ.V", value: 1060269, partition: 0 },
	{ label: "KTAPU.V", value: 927836, partition: 0 },
	{ label: "PEBDB.V", value: 1060270, partition: 0 },
	{ label: "PE1PW.V", value: 1060271, partition: 0 },
	{ label: "KCHOL.E", value: 75656, partition: 0 },
	{ label: "PBSQJ.V", value: 3026635, partition: 0 },
	{ label: "PLBBD.V", value: 798290, partition: 0 },
	{ label: "NXSPS.V", value: 4074597, partition: 0 },
	{ label: "SOIRH.V", value: 2764654, partition: 0 },
	{ label: "KDILJ.V", value: 665863, partition: 0 },
	{ label: "KZBDK.V", value: 8136870, partition: 0 },
	{ label: "NOBQN.V", value: 4074601, partition: 0 },
	{ label: "SASRH.V", value: 2764656, partition: 0 },
	{ label: "SAARC.V", value: 2764657, partition: 0 },
	{ label: "KRBCY.V", value: 665866, partition: 0 },
	{ label: "KR1AZ.V", value: 665867, partition: 0 },
	{ label: "SA1AK.V", value: 2764659, partition: 0 },
	{ label: "SSBDQ.V", value: 4731018, partition: 0 },
	{ label: "NASFA.V", value: 1846260, partition: 0 },
	{ label: "SI1PP.V", value: 4731019, partition: 0 },
	{ label: "NAIRE.V", value: 1846261, partition: 0 },
	{ label: "KENT.E", value: 75676, partition: 0 },
	{ label: "NABRW.V", value: 1846262, partition: 0 },
	{ label: "OY1AO.V", value: 3812620, partition: 0 },
	{ label: "PWBCA.V", value: 5516991, partition: 0 },
	{ label: "PEAWW.V", value: 3550636, partition: 0 },
	{ label: "PESGC.V", value: 3550637, partition: 0 },
	{ label: "OD1AQ.V", value: 4074617, partition: 0 },
	{ label: "PE1AN.V", value: 3550639, partition: 0 },
	{ label: "PPBSK.V", value: 5516998, partition: 0 },
	{ label: "NVSBZ.V", value: 1846273, partition: 0 },
	{ label: "KT1PL.V", value: 3418207, partition: 0 },
	{ label: "KTIAO.V", value: 3418208, partition: 0 },
	{ label: "KERVN.E", value: 75696, partition: 0 },
	{ label: "PPBRV.V", value: 17706694, partition: 0 },
	{ label: "SLIYO.V", value: 2240709, partition: 0 },
	{ label: "PGIWE.V", value: 1060319, partition: 0 },
	{ label: "SLATZ.V", value: 2240710, partition: 0 },
	{ label: "KRAHC.V", value: 3156232, partition: 0 },
	{ label: "SLSQT.V", value: 2240711, partition: 0 },
	{ label: "RHBCJ.V", value: 3026682, partition: 0 },
	{ label: "OYBCO.V", value: 6302986, partition: 0 },
	{ label: "PWBQV.V", value: 8007359, partition: 0 },
	{ label: "KERVT.E", value: 75716, partition: 0 },
	{ label: "PEBQI.V", value: 6041006, partition: 0 },
	{ label: "MTSBZ.V", value: 2370282, partition: 0 },
	{ label: "PPBED.V", value: 8007366, partition: 0 },
	{ label: "KT1PU.V", value: 5908575, partition: 0 },
	{ label: "KZIYJ.V", value: 927907, partition: 0 },
	{ label: "KZ1PV.V", value: 927911, partition: 0 },
	{ label: "KGYO.E", value: 75731, partition: 0 },
	{ label: "PGAUP.V", value: 3550688, partition: 0 },
	{ label: "PGBDB.V", value: 3550689, partition: 0 },
	{ label: "KRBDO.V", value: 5646602, partition: 0 },
	{ label: "SLBDK.V", value: 4731080, partition: 0 },
	{ label: "KR1PT.V", value: 5646603, partition: 0 },
	{ label: "SL1AR.V", value: 4731081, partition: 0 },
	{ label: "PG1PL.V", value: 3550691, partition: 0 },
	{ label: "RHBCS.V", value: 5517050, partition: 0 },
	{ label: "NABDG.V", value: 6826998, partition: 0 },
	{ label: "PPIQN.V", value: 4402888, partition: 0 },
	{ label: "KOIRC.V", value: 1451914, partition: 0 },
	{ label: "MTBQQ.V", value: 4860651, partition: 0 },
	{ label: "NVBDF.V", value: 6827010, partition: 0 },
	{ label: "KZAGI.V", value: 3418276, partition: 0 },
	{ label: "KZSDT.V", value: 3418277, partition: 0 },
	{ label: "KZBDA.V", value: 3418278, partition: 0 },
	{ label: "KZ1AM.V", value: 3418279, partition: 0 },
	{ label: "PWSCN.V", value: 798398, partition: 0 },
	{ label: "PDBQC.V", value: 1322378, partition: 0 },
	{ label: "RHBSB.V", value: 8007418, partition: 0 },
	{ label: "KLGYO.E", value: 75776, partition: 0 },
	{ label: "MSBQQ.V", value: 5122665, partition: 0 },
	{ label: "PBAGL.V", value: 798410, partition: 0 },
	{ label: "PBSCS.V", value: 798411, partition: 0 },
	{ label: "KLKIM.E", value: 75786, partition: 0 },
	{ label: "NVBSK.V", value: 9317378, partition: 0 },
	{ label: "NXSBS.V", value: 1846373, partition: 0 },
	{ label: "KHAWZ.V", value: 3942286, partition: 0 },
	{ label: "OZSUB.E", value: 208223, partition: 0 },
	{ label: "KHBDJ.V", value: 3942287, partition: 0 },
	{ label: "SOICA.V", value: 536430, partition: 0 },
	{ label: "KHSPJ.V", value: 3942288, partition: 0 },
	{ label: "OZYSR.E", value: 208225, partition: 0 },
	{ label: "KC1AP.V", value: 3942289, partition: 0 },
	{ label: "NOBBG.V", value: 1846377, partition: 0 },
	{ label: "KZ1PY.V", value: 5908647, partition: 0 },
	{ label: "SSIZO.V", value: 2502790, partition: 0 },
	{ label: "SOBCX.V", value: 536434, partition: 0 },
	{ label: "SIAWA.V", value: 2502792, partition: 0 },
	{ label: "KLMSN.E", value: 75796, partition: 0 },
	{ label: "OYBCD.V", value: 1584394, partition: 0 },
	{ label: "SI1PI.V", value: 2502795, partition: 0 },
	{ label: "OYIAZ.V", value: 1584395, partition: 0 },
	{ label: "PTIUG.V", value: 1322410, partition: 0 },
	{ label: "PAPIL.E", value: 208238, partition: 0 },
	{ label: "KTAPW.V", value: 1189980, partition: 0 },
	{ label: "PEBDD.V", value: 1322414, partition: 0 },
	{ label: "PBSQL.V", value: 3288779, partition: 0 },
	{ label: "KLNMA.E", value: 75816, partition: 0 },
	{ label: "PLBQA.V", value: 1060434, partition: 0 },
	{ label: "NXSPW.V", value: 4336741, partition: 0 },
	{ label: "KLRHO.E", value: 75821, partition: 0 },
	{ label: "KDIZI.V", value: 928007, partition: 0 },
	{ label: "PCILT.E", value: 208258, partition: 0 },
	{ label: "KZBPQ.V", value: 8399014, partition: 0 },
	{ label: "SASFK.V", value: 3026800, partition: 0 },
	{ label: "SAACE.V", value: 3026801, partition: 0 },
	{ label: "KLSYN.E", value: 75826, partition: 0 },
	{ label: "KRBDA.V", value: 928010, partition: 0 },
	{ label: "KR1PV.V", value: 928011, partition: 0 },
	{ label: "SA1AM.V", value: 3026803, partition: 0 },
	{ label: "NASFC.V", value: 2108404, partition: 0 },
	{ label: "NAIRG.V", value: 2108405, partition: 0 },
	{ label: "KLSER.E", value: 75831, partition: 0 },
	{ label: "NABRY.V", value: 2108406, partition: 0 },
	{ label: "OY1AQ.V", value: 4074764, partition: 0 },
	{ label: "PWBQW.V", value: 5779135, partition: 0 },
	{ label: "KNFRT.E", value: 75836, partition: 0 },
	{ label: "PEAWY.V", value: 3812780, partition: 0 },
	{ label: "PESGD.V", value: 3812781, partition: 0 },
	{ label: "PEBDF.V", value: 3812782, partition: 0 },
	{ label: "OD1PN.V", value: 4336761, partition: 0 },
	{ label: "PE1AP.V", value: 3812783, partition: 0 },
	{ label: "KOCMT.E", value: 75841, partition: 0 },
	{ label: "PPBSM.V", value: 5779142, partition: 0 },
	{ label: "KT1AN.V", value: 3680351, partition: 0 },
	{ label: "NVSCB.V", value: 2108417, partition: 0 },
	{ label: "KTIPL.V", value: 3680352, partition: 0 },
	{ label: "KMPUR.E", value: 75846, partition: 0 },
	{ label: "PPBRX.V", value: 17968838, partition: 0 },
	{ label: "PGIWG.V", value: 1322463, partition: 0 },
	{ label: "SLIZB.V", value: 2502853, partition: 0 },
	{ label: "PGAFK.V", value: 1322464, partition: 0 },
	{ label: "SLAUB.V", value: 2502854, partition: 0 },
	{ label: "KRAHE.V", value: 3418376, partition: 0 },
	{ label: "SLSDJ.V", value: 2502855, partition: 0 },
	{ label: "PG1AE.V", value: 1322467, partition: 0 },
	{ label: "KR1AM.V", value: 3418379, partition: 0 },
	{ label: "RHBCL.V", value: 3288826, partition: 0 },
	{ label: "NASRK.V", value: 4598772, partition: 0 },
	{ label: "OYBCQ.V", value: 6565130, partition: 0 },
	{ label: "KONTR.E", value: 75866, partition: 0 },
	{ label: "PWBCD.V", value: 8269503, partition: 0 },
	{ label: "PEBQK.V", value: 6303150, partition: 0 },
	{ label: "MTSCB.V", value: 2632426, partition: 0 },
	{ label: "PPBST.V", value: 8269510, partition: 0 },
	{ label: "KONYA.E", value: 75876, partition: 0 },
	{ label: "KZIYL.V", value: 1190051, partition: 0 },
	{ label: "KZSDO.V", value: 1190053, partition: 0 },
	{ label: "KZ1PX.V", value: 1190055, partition: 0 },
	{ label: "KONKA.E", value: 75886, partition: 0 },
	{ label: "PGAUR.V", value: 3812832, partition: 0 },
	{ label: "PGBDD.V", value: 3812833, partition: 0 },
	{ label: "SPSEY.V", value: 1584487, partition: 0 },
	{ label: "KOPOL.E", value: 75891, partition: 0 },
	{ label: "KRBDQ.V", value: 5908746, partition: 0 },
	{ label: "KR1PY.V", value: 5908747, partition: 0 },
	{ label: "SL1AT.V", value: 4993225, partition: 0 },
	{ label: "PG1AO.V", value: 3812835, partition: 0 },
	{ label: "RHBCU.V", value: 5779194, partition: 0 },
	{ label: "KORDS.E", value: 75896, partition: 0 },
	{ label: "NABDI.V", value: 7089142, partition: 0 },
	{ label: "MSBBJ.V", value: 2894441, partition: 0 },
	{ label: "KOTON.E", value: 75906, partition: 0 },
	{ label: "KOICE.V", value: 1714058, partition: 0 },
	{ label: "MTBQS.V", value: 5122795, partition: 0 },
	{ label: "NVBDH.V", value: 7089154, partition: 0 },
	{ label: "KHAHS.V", value: 1714062, partition: 0 },
	{ label: "KZAVF.V", value: 3680420, partition: 0 },
	{ label: "KZSQT.V", value: 3680421, partition: 0 },
	{ label: "KZBPL.V", value: 3680422, partition: 0 },
	{ label: "KZ1AO.V", value: 3680423, partition: 0 },
	{ label: "SSILO.V", value: 274566, partition: 0 },
	{ label: "KOZAA.E", value: 75916, partition: 0 },
	{ label: "PWSCP.V", value: 1060542, partition: 0 },
	{ label: "PDBBE.V", value: 1584522, partition: 0 },
	{ label: "MSBQS.V", value: 5384809, partition: 0 },
	{ label: "PBAVI.V", value: 1060554, partition: 0 },
	{ label: "KOZAL.E", value: 75936, partition: 0 },
	{ label: "PBSCU.V", value: 1060555, partition: 0 },
	{ label: "NXSBU.V", value: 2108517, partition: 0 },
	{ label: "KHBDL.V", value: 4204431, partition: 0 },
	{ label: "SOICC.V", value: 798574, partition: 0 },
	{ label: "KHSPL.V", value: 4204432, partition: 0 },
	{ label: "KC1AR.V", value: 4204433, partition: 0 },
	{ label: "NOBBI.V", value: 2108521, partition: 0 },
	{ label: "SSIQB.V", value: 2764934, partition: 0 },
	{ label: "SOBCZ.V", value: 798578, partition: 0 },
	{ label: "SIAWC.V", value: 2764936, partition: 0 },
	{ label: "OYBCF.V", value: 1846538, partition: 0 },
	{ label: "SI1AK.V", value: 2764939, partition: 0 },
	{ label: "OYIBB.V", value: 1846539, partition: 0 },
	{ label: "PTIFK.V", value: 1584554, partition: 0 },
	{ label: "KTAPY.V", value: 1452124, partition: 0 },
	{ label: "PPBDH.V", value: 3550918, partition: 0 },
	{ label: "PBSDC.V", value: 3550923, partition: 0 },
	{ label: "PLBQC.V", value: 1322578, partition: 0 },
	{ label: "NXBBZ.V", value: 4598886, partition: 0 },
	{ label: "KDIZK.V", value: 1190151, partition: 0 },
	{ label: "SLIKN.V", value: 274629, partition: 0 },
	{ label: "KZBPS.V", value: 8661158, partition: 0 },
	{ label: "KDSFP.V", value: 1190153, partition: 0 },
	{ label: "SASFM.V", value: 3288944, partition: 0 },
	{ label: "KRBDC.V", value: 1190154, partition: 0 },
	{ label: "SAACG.V", value: 3288945, partition: 0 },
	{ label: "SLBCR.V", value: 274632, partition: 0 },
	{ label: "KR1PX.V", value: 1190155, partition: 0 },
	{ label: "SOBDD.V", value: 3288946, partition: 0 },
	{ label: "SL1AW.V", value: 274633, partition: 0 },
	{ label: "SA1PJ.V", value: 3288947, partition: 0 },
	{ label: "SISRZ.V", value: 5255305, partition: 0 },
	{ label: "NASFE.V", value: 2370548, partition: 0 },
	{ label: "NAICI.V", value: 2370549, partition: 0 },
	{ label: "OY1PN.V", value: 4336908, partition: 0 },
	{ label: "PWSQA.V", value: 6041278, partition: 0 },
	{ label: "PWBQY.V", value: 6041279, partition: 0 },
	{ label: "PEAXA.V", value: 4074924, partition: 0 },
	{ label: "PESRZ.V", value: 4074925, partition: 0 },
	{ label: "PEBQD.V", value: 4074926, partition: 0 },
	{ label: "OD1PP.V", value: 4598905, partition: 0 },
	{ label: "PE1PM.V", value: 4074927, partition: 0 },
	{ label: "KRDMA.E", value: 75996, partition: 0 },
	{ label: "MTBBO.V", value: 404203, partition: 0 },
	{ label: "PPBSO.V", value: 6041286, partition: 0 },
	{ label: "KT1AP.V", value: 3942495, partition: 0 },
	{ label: "NVSCF.V", value: 2370561, partition: 0 },
	{ label: "KTIPN.V", value: 3942496, partition: 0 },
	{ label: "PPBRZ.V", value: 18230982, partition: 0 },
	{ label: "PGIOV.V", value: 1584607, partition: 0 },
	{ label: "SLIZD.V", value: 2764997, partition: 0 },
	{ label: "KRAWB.V", value: 3680520, partition: 0 },
	{ label: "PGAFM.V", value: 1584608, partition: 0 },
	{ label: "SLAUD.V", value: 2764998, partition: 0 },
	{ label: "SLSDL.V", value: 2764999, partition: 0 },
	{ label: "PGSAZ.V", value: 1584610, partition: 0 },
	{ label: "SLBCV.V", value: 2765000, partition: 0 },
	{ label: "KR1AO.V", value: 3680523, partition: 0 },
	{ label: "PG1AG.V", value: 1584611, partition: 0 },
	{ label: "KRDMB.E", value: 76016, partition: 0 },
	{ label: "RHBCN.V", value: 3550970, partition: 0 },
	{ label: "NASRP.V", value: 4860916, partition: 0 },
	{ label: "PLTUR.E", value: 208453, partition: 0 },
	{ label: "PWBCF.V", value: 8531647, partition: 0 },
	{ label: "PEBQM.V", value: 6565294, partition: 0 },
	{ label: "MTSPT.V", value: 2894570, partition: 0 },
	{ label: "PPBSV.V", value: 8531654, partition: 0 },
	{ label: "KZIYN.V", value: 1452195, partition: 0 },
	{ label: "KZSDQ.V", value: 1452197, partition: 0 },
	{ label: "KRDMD.E", value: 76036, partition: 0 },
	{ label: "PGAUT.V", value: 4074976, partition: 0 },
	{ label: "PGBDF.V", value: 4074977, partition: 0 },
	{ label: "SPSFA.V", value: 1846631, partition: 0 },
	{ label: "KRGYO.E", value: 76046, partition: 0 },
	{ label: "KRBQJ.V", value: 6170890, partition: 0 },
	{ label: "PGSPP.V", value: 4074978, partition: 0 },
	{ label: "SL1AV.V", value: 5255369, partition: 0 },
	{ label: "PG1AQ.V", value: 4074979, partition: 0 },
	{ label: "RHBCW.V", value: 6041338, partition: 0 },
	{ label: "NASRN.V", value: 7351284, partition: 0 },
	{ label: "NABDK.V", value: 7351286, partition: 0 },
	{ label: "MSBBL.V", value: 3156585, partition: 0 },
	{ label: "KRONT.E", value: 76056, partition: 0 },
	{ label: "KOICG.V", value: 1976202, partition: 0 },
	{ label: "MTBQU.V", value: 5384939, partition: 0 },
	{ label: "NVBDJ.V", value: 7351298, partition: 0 },
	{ label: "KHAHU.V", value: 1976206, partition: 0 },
	{ label: "KRPLS.E", value: 76066, partition: 0 },
	{ label: "KZAVH.V", value: 3942564, partition: 0 },
	{ label: "KZSQV.V", value: 3942565, partition: 0 },
	{ label: "KZBPN.V", value: 3942566, partition: 0 },
	{ label: "KZ1AQ.V", value: 3942567, partition: 0 },
	{ label: "SSIMB.V", value: 536710, partition: 0 },
	{ label: "KDSFT.V", value: 2566409, partition: 0 },
	{ label: "SSBCX.V", value: 536714, partition: 0 },
	{ label: "PWSCQ.V", value: 1322686, partition: 0 },
	{ label: "PDBBG.V", value: 1846666, partition: 0 },
	{ label: "PRDGS.E", value: 208518, partition: 0 },
	{ label: "PBAGM.V", value: 1322698, partition: 0 },
	{ label: "PBSQG.V", value: 1322699, partition: 0 },
	{ label: "KRSTL.E", value: 76096, partition: 0 },
	{ label: "NXSBW.V", value: 2370661, partition: 0 },
	{ label: "KHBDN.V", value: 4466575, partition: 0 },
	{ label: "SOIQZ.V", value: 1060718, partition: 0 },
	{ label: "KC1PO.V", value: 4466577, partition: 0 },
	{ label: "NOBQF.V", value: 2370665, partition: 0 },
	{ label: "SOBDB.V", value: 1060722, partition: 0 },
	{ label: "SIAHF.V", value: 3027080, partition: 0 },
	{ label: "OYBCH.V", value: 2108682, partition: 0 },
	{ label: "SI1AM.V", value: 3027083, partition: 0 },
	{ label: "OYIBD.V", value: 2108683, partition: 0 },
	{ label: "PTIFM.V", value: 1846698, partition: 0 },
	{ label: "PEAHY.V", value: 1846700, partition: 0 },
	{ label: "KRTEK.E", value: 76116, partition: 0 },
	{ label: "OD1AI.V", value: 2370681, partition: 0 },
	{ label: "PPBDJ.V", value: 3813062, partition: 0 },
	{ label: "KTIAG.V", value: 1714272, partition: 0 },
	{ label: "PBSQM.V", value: 3813067, partition: 0 },
	{ label: "PLBBE.V", value: 1584722, partition: 0 },
	{ label: "KRVGD.E", value: 76126, partition: 0 },
	{ label: "PPBCU.V", value: 16002758, partition: 0 },
	{ label: "NXBQS.V", value: 4861030, partition: 0 },
	{ label: "SLILA.V", value: 536773, partition: 0 },
	{ label: "KDIZM.V", value: 1452295, partition: 0 },
	{ label: "KRAGW.V", value: 1452296, partition: 0 },
	{ label: "SASFO.V", value: 3551088, partition: 0 },
	{ label: "SAARD.V", value: 3551089, partition: 0 },
	{ label: "SLBCT.V", value: 536776, partition: 0 },
	{ label: "KRBDE.V", value: 1452298, partition: 0 },
	{ label: "KSTUR.E", value: 76136, partition: 0 },
	{ label: "SOBDF.V", value: 3551090, partition: 0 },
	{ label: "SL1AY.V", value: 536777, partition: 0 },
	{ label: "SA1PL.V", value: 3551091, partition: 0 },
	{ label: "NASFG.V", value: 2632692, partition: 0 },
	{ label: "NAICK.V", value: 2632693, partition: 0 },
	{ label: "OY1PP.V", value: 4599052, partition: 0 },
	{ label: "PWBRA.V", value: 6303423, partition: 0 },
	{ label: "PDBBJ.V", value: 6827402, partition: 0 },
	{ label: "PEBQF.V", value: 4337070, partition: 0 },
	{ label: "KTAAP.V", value: 4204636, partition: 0 },
	{ label: "OD1AS.V", value: 4861049, partition: 0 },
	{ label: "PE1PO.V", value: 4337071, partition: 0 },
	{ label: "MTBBQ.V", value: 666347, partition: 0 },
	{ label: "PPBSQ.V", value: 6303430, partition: 0 },
	{ label: "KT1AR.V", value: 4204639, partition: 0 },
	{ label: "NVSCH.V", value: 2632705, partition: 0 },
	{ label: "QUAGR.E", value: 208595, partition: 0 },
	{ label: "PPBSB.V", value: 18493126, partition: 0 },
	{ label: "PGIOX.V", value: 1846751, partition: 0 },
	{ label: "KRAWD.V", value: 3942664, partition: 0 },
	{ label: "PGAFO.V", value: 1846752, partition: 0 },
	{ label: "SLAFF.V", value: 3027142, partition: 0 },
	{ label: "SLSQW.V", value: 3027143, partition: 0 },
	{ label: "KRBDG.V", value: 3942666, partition: 0 },
	{ label: "PGSBB.V", value: 1846754, partition: 0 },
	{ label: "SLBCX.V", value: 3027144, partition: 0 },
	{ label: "KR1AQ.V", value: 3942667, partition: 0 },
	{ label: "PG1AI.V", value: 1846755, partition: 0 },
	{ label: "KUVVA.E", value: 76171, partition: 0 },
	{ label: "RHBCP.V", value: 3813114, partition: 0 },
	{ label: "NASRR.V", value: 5123060, partition: 0 },
	{ label: "KUTPO.E", value: 76176, partition: 0 },
	{ label: "PWBCH.V", value: 8793791, partition: 0 },
	{ label: "MTSPX.V", value: 3156714, partition: 0 },
	{ label: "PPBSX.V", value: 8793798, partition: 0 },
	{ label: "KZILE.V", value: 1714339, partition: 0 },
	{ label: "KZAGA.V", value: 1714340, partition: 0 },
	{ label: "KZSDS.V", value: 1714341, partition: 0 },
	{ label: "KUYAS.E", value: 76196, partition: 0 },
	{ label: "PGBDH.V", value: 4337121, partition: 0 },
	{ label: "SPSFC.V", value: 2108775, partition: 0 },
	{ label: "KZGYO.E", value: 76201, partition: 0 },
	{ label: "KRBQL.V", value: 6433034, partition: 0 },
	{ label: "SL1PS.V", value: 5517513, partition: 0 },
	{ label: "PG1AS.V", value: 4337123, partition: 0 },
	{ label: "RNPOL.E", value: 208638, partition: 0 },
	{ label: "RHBCY.V", value: 6303482, partition: 0 },
	{ label: "KZBGY.E", value: 76206, partition: 0 },
	{ label: "NABDM.V", value: 7613430, partition: 0 },
	{ label: "MSBBN.V", value: 3418729, partition: 0 },
	{ label: "KOIRD.V", value: 2238346, partition: 0 },
	{ label: "NVBDL.V", value: 7613442, partition: 0 },
	{ label: "KHAWR.V", value: 2238350, partition: 0 },
	{ label: "KZSEB.V", value: 4204709, partition: 0 },
	{ label: "KZBDB.V", value: 4204710, partition: 0 },
	{ label: "KZ1PN.V", value: 4204711, partition: 0 },
	{ label: "SSIME.V", value: 798854, partition: 0 },
	{ label: "LIDFA.E", value: 76226, partition: 0 },
	{ label: "SSBCZ.V", value: 798858, partition: 0 },
	{ label: "KDSRU.V", value: 2828553, partition: 0 },
	{ label: "LILAK.E", value: 76231, partition: 0 },
	{ label: "SPSRJ.V", value: 4599143, partition: 0 },
	{ label: "PWSCS.V", value: 1584830, partition: 0 },
	{ label: "PDBBI.V", value: 2108810, partition: 0 },
	{ label: "LINK.E", value: 76236, partition: 0 },
	{ label: "PBAGO.V", value: 1584842, partition: 0 },
	{ label: "PBSCW.V", value: 1584843, partition: 0 },
	{ label: "NXSBY.V", value: 2632805, partition: 0 },
	{ label: "KHBDP.V", value: 4728719, partition: 0 },
	{ label: "SOIRB.V", value: 1322862, partition: 0 },
	{ label: "LKMNH.E", value: 76256, partition: 0 },
	{ label: "KC1PQ.V", value: 4728721, partition: 0 },
	{ label: "NOBQH.V", value: 2632809, partition: 0 },
	{ label: "SA1AE.V", value: 1322867, partition: 0 },
	{ label: "SIAHH.V", value: 3289224, partition: 0 },
	{ label: "SISRY.V", value: 3289225, partition: 0 },
	{ label: "KDSRV.V", value: 5318921, partition: 0 },
	{ label: "SSBDF.V", value: 3289226, partition: 0 },
	{ label: "OYBCJ.V", value: 2370826, partition: 0 },
	{ label: "SI1PJ.V", value: 3289227, partition: 0 },
	{ label: "NABCV.V", value: 404470, partition: 0 },
	{ label: "OYIQA.V", value: 2370827, partition: 0 },
	{ label: "OY1AI.V", value: 2370828, partition: 0 },
	{ label: "LMKDC.E", value: 76266, partition: 0 },
	{ label: "PTIFO.V", value: 2108842, partition: 0 },
	{ label: "PEAIA.V", value: 2108844, partition: 0 },
	{ label: "PESFS.V", value: 2108845, partition: 0 },
	{ label: "OD1AK.V", value: 2632825, partition: 0 },
	{ label: "PPBDL.V", value: 4075206, partition: 0 },
	{ label: "LOGO.E", value: 76276, partition: 0 },
	{ label: "NVBCW.V", value: 404482, partition: 0 },
	{ label: "KTIAI.V", value: 1976416, partition: 0 },
	{ label: "PBAVL.V", value: 4075210, partition: 0 },
	{ label: "PBSQO.V", value: 4075211, partition: 0 },
	{ label: "PLBBG.V", value: 1846866, partition: 0 },
	{ label: "PPBCW.V", value: 16264902, partition: 0 },
	{ label: "LRSHO.E", value: 76286, partition: 0 },
	{ label: "NXBQU.V", value: 5123174, partition: 0 },
	{ label: "SLILC.V", value: 798917, partition: 0 },
	{ label: "KDILM.V", value: 1714439, partition: 0 },
	{ label: "SASFP.V", value: 3813232, partition: 0 },
	{ label: "SAARF.V", value: 3813233, partition: 0 },
	{ label: "SOBDH.V", value: 3813234, partition: 0 },
	{ label: "SL1BA.V", value: 798921, partition: 0 },
	{ label: "SA1AO.V", value: 3813235, partition: 0 },
	{ label: "NASFI.V", value: 2894836, partition: 0 },
	{ label: "LUKSK.E", value: 76296, partition: 0 },
	{ label: "NAIRI.V", value: 2894837, partition: 0 },
	{ label: "OY1AS.V", value: 4861196, partition: 0 },
	{ label: "PWBBQ.V", value: 6565567, partition: 0 },
	{ label: "MACKO.E", value: 76301, partition: 0 },
	{ label: "PDBBL.V", value: 7089546, partition: 0 },
	{ label: "SAFKR.E", value: 208738, partition: 0 },
	{ label: "PEBQH.V", value: 4599214, partition: 0 },
	{ label: "KTAAR.V", value: 4466780, partition: 0 },
	{ label: "OD1AU.V", value: 5123193, partition: 0 },
	{ label: "PE1PQ.V", value: 4599215, partition: 0 },
	{ label: "MAKIM.E", value: 76306, partition: 0 },
	{ label: "MTBQL.V", value: 928491, partition: 0 },
	{ label: "PPBDS.V", value: 6565574, partition: 0 },
	{ label: "KT1PO.V", value: 4466783, partition: 0 },
	{ label: "NVSCC.V", value: 2894849, partition: 0 },
	{ label: "MAALT.E", value: 76316, partition: 0 },
	{ label: "SOIBY.V", value: 208750, partition: 0 },
	{ label: "PPBSD.V", value: 18755270, partition: 0 },
	{ label: "PGIOZ.V", value: 2108895, partition: 0 },
	{ label: "PGAUL.V", value: 2108896, partition: 0 },
	{ label: "SLAFH.V", value: 3289286, partition: 0 },
	{ label: "KRBQE.V", value: 4204810, partition: 0 },
	{ label: "PGSBD.V", value: 2108898, partition: 0 },
	{ label: "SLBCZ.V", value: 3289288, partition: 0 },
	{ label: "KR1PN.V", value: 4204811, partition: 0 },
	{ label: "PG1PF.V", value: 2108899, partition: 0 },
	{ label: "MAGEN.E", value: 76326, partition: 0 },
	{ label: "RHBCR.V", value: 4075258, partition: 0 },
	{ label: "NASRT.V", value: 5385204, partition: 0 },
	{ label: "PWBRD.V", value: 9055935, partition: 0 },
	{ label: "MAKTK.E", value: 76336, partition: 0 },
	{ label: "PPBSZ.V", value: 9055942, partition: 0 },
	{ label: "NVSPX.V", value: 5385217, partition: 0 },
	{ label: "KZILG.V", value: 1976483, partition: 0 },
	{ label: "KZAGC.V", value: 1976484, partition: 0 },
	{ label: "KZSDW.V", value: 1976485, partition: 0 },
	{ label: "PLBBJ.V", value: 6827602, partition: 0 },
	{ label: "PGBDJ.V", value: 4599265, partition: 0 },
	{ label: "MARKA.E", value: 76356, partition: 0 },
	{ label: "SPSFE.V", value: 2370919, partition: 0 },
	{ label: "SL1PU.V", value: 5779657, partition: 0 },
	{ label: "PG1PO.V", value: 4599267, partition: 0 },
	{ label: "RHBDA.V", value: 6565626, partition: 0 },
	{ label: "NABSE.V", value: 7875574, partition: 0 },
	{ label: "MSBQK.V", value: 3680873, partition: 0 },
	{ label: "KOIRF.V", value: 2500490, partition: 0 },
	{ label: "NVBDN.V", value: 7875586, partition: 0 },
	{ label: "MARTI.E", value: 76376, partition: 0 },
	{ label: "NXBBS.V", value: 404582, partition: 0 },
	{ label: "KHAWT.V", value: 2500494, partition: 0 },
	{ label: "KZSQZ.V", value: 4466853, partition: 0 },
	{ label: "KZBDD.V", value: 4466854, partition: 0 },
	{ label: "KC1AJ.V", value: 2500497, partition: 0 },
	{ label: "KZ1PP.V", value: 4466855, partition: 0 },
	{ label: "SSIMG.V", value: 1060998, partition: 0 },
	{ label: "SISFS.V", value: 1061001, partition: 0 },
	{ label: "SSBDB.V", value: 1061002, partition: 0 },
	{ label: "KDSFW.V", value: 3090697, partition: 0 },
	{ label: "MAVI.E", value: 76386, partition: 0 },
	{ label: "SPSRO.V", value: 4861287, partition: 0 },
	{ label: "PWSCU.V", value: 1846974, partition: 0 },
	{ label: "PDBQF.V", value: 2370954, partition: 0 },
	{ label: "PPIBP.V", value: 1846984, partition: 0 },
	{ label: "PBAGQ.V", value: 1846986, partition: 0 },
	{ label: "PBSCY.V", value: 1846987, partition: 0 },
	{ label: "MEDTR.E", value: 76406, partition: 0 },
	{ label: "NXSPR.V", value: 2894949, partition: 0 },
	{ label: "SOICD.V", value: 1585006, partition: 0 },
	{ label: "MEGMT.E", value: 76411, partition: 0 },
	{ label: "KC1AT.V", value: 4990865, partition: 0 },
	{ label: "NOBBJ.V", value: 2894953, partition: 0 },
	{ label: "SAABY.V", value: 1585009, partition: 0 },
	{ label: "SIAWD.V", value: 3551368, partition: 0 },
	{ label: "SA1AG.V", value: 1585011, partition: 0 },
	{ label: "SISGE.V", value: 3551369, partition: 0 },
	{ label: "MEGAP.E", value: 76416, partition: 0 },
	{ label: "SSBDH.V", value: 3551370, partition: 0 },
	{ label: "SI1PL.V", value: 3551371, partition: 0 },
	{ label: "OYBCL.V", value: 2632970, partition: 0 },
	{ label: "NABCX.V", value: 666614, partition: 0 },
	{ label: "OYIQC.V", value: 2632971, partition: 0 },
	{ label: "OY1AK.V", value: 2632972, partition: 0 },
	{ label: "SPSRM.V", value: 7351655, partition: 0 },
	{ label: "PTIUJ.V", value: 2370986, partition: 0 },
	{ label: "PEAWS.V", value: 2370988, partition: 0 },
	{ label: "PESFU.V", value: 2370989, partition: 0 },
	{ label: "OD1AM.V", value: 2894969, partition: 0 },
	{ label: "PPBDN.V", value: 4337350, partition: 0 },
	{ label: "NVBCY.V", value: 666626, partition: 0 },
	{ label: "KTIPF.V", value: 2238560, partition: 0 },
	{ label: "PBSQQ.V", value: 4337355, partition: 0 },
	{ label: "PLBBI.V", value: 2109010, partition: 0 },
	{ label: "PPBCY.V", value: 16527046, partition: 0 },
	{ label: "NXBQW.V", value: 5385318, partition: 0 },
	{ label: "SLIYK.V", value: 1061061, partition: 0 },
	{ label: "KDILO.V", value: 1976583, partition: 0 },
	{ label: "KRAGY.V", value: 1976584, partition: 0 },
	{ label: "SASRL.V", value: 4075376, partition: 0 },
	{ label: "SAARH.V", value: 4075377, partition: 0 },
	{ label: "SOBDJ.V", value: 4075378, partition: 0 },
	{ label: "SL1PW.V", value: 1061065, partition: 0 },
	{ label: "SA1AQ.V", value: 4075379, partition: 0 },
	{ label: "NASFK.V", value: 3156980, partition: 0 },
	{ label: "NAIRK.V", value: 3156981, partition: 0 },
	{ label: "OY1AU.V", value: 5123340, partition: 0 },
	{ label: "PWBBS.V", value: 6827711, partition: 0 },
	{ label: "MEPET.E", value: 76456, partition: 0 },
	{ label: "PDBBN.V", value: 7351690, partition: 0 },
	{ label: "PEBDI.V", value: 4861358, partition: 0 },
	{ label: "KTAAT.V", value: 4728924, partition: 0 },
	{ label: "OD1AW.V", value: 5385337, partition: 0 },
	{ label: "PE1AS.V", value: 4861359, partition: 0 },
	{ label: "PPBDU.V", value: 6827718, partition: 0 },
	{ label: "KT1PQ.V", value: 4728927, partition: 0 },
	{ label: "NVSPU.V", value: 3156993, partition: 0 },
	{ label: "SEGYO.E", value: 208898, partition: 0 },
	{ label: "PPBSF.V", value: 19017414, partition: 0 },
	{ label: "MERIT.E", value: 76476, partition: 0 },
	{ label: "PGIXZ.V", value: 2371039, partition: 0 },
	{ label: "SLAUE.V", value: 3551430, partition: 0 },
	{ label: "KRBQG.V", value: 4466954, partition: 0 },
	{ label: "SLBDB.V", value: 3551432, partition: 0 },
	{ label: "PGSBE.V", value: 2371042, partition: 0 },
	{ label: "KR1PP.V", value: 4466955, partition: 0 },
	{ label: "SL1AN.V", value: 3551433, partition: 0 },
	{ label: "PG1PH.V", value: 2371043, partition: 0 },
	{ label: "RHBRJ.V", value: 4337402, partition: 0 },
	{ label: "PWBRF.V", value: 9318079, partition: 0 },
	{ label: "MTBQM.V", value: 3681003, partition: 0 },
	{ label: "PPBTB.V", value: 9318086, partition: 0 },
	{ label: "NVSPZ.V", value: 5647361, partition: 0 },
	{ label: "MERKO.E", value: 76496, partition: 0 },
	{ label: "KZIYO.V", value: 2238627, partition: 0 },
	{ label: "KHBCW.V", value: 272271, partition: 0 },
	{ label: "KZAUZ.V", value: 2238628, partition: 0 },
	{ label: "KHSAT.V", value: 272272, partition: 0 },
	{ label: "KZSDY.V", value: 2238629, partition: 0 },
	{ label: "PLBBL.V", value: 7089746, partition: 0 },
	{ label: "SPSFG.V", value: 2633063, partition: 0 },
	{ label: "PG1PQ.V", value: 4861411, partition: 0 },
	{ label: "RHBRS.V", value: 6827770, partition: 0 },
	{ label: "NABSG.V", value: 8137718, partition: 0 },
	{ label: "MSBQM.V", value: 3943017, partition: 0 },
	{ label: "KOIRH.V", value: 2762634, partition: 0 },
	{ label: "NVBSB.V", value: 8137730, partition: 0 },
	{ label: "NXBBU.V", value: 666726, partition: 0 },
	{ label: "KHAWV.V", value: 2762638, partition: 0 },
	{ label: "KZSRB.V", value: 4728997, partition: 0 },
	{ label: "KZBDF.V", value: 4728998, partition: 0 },
	{ label: "KC1AL.V", value: 2762641, partition: 0 },
	{ label: "KZ1AR.V", value: 4728999, partition: 0 },
	{ label: "SSIMI.V", value: 1323142, partition: 0 },
	{ label: "METRO.E", value: 76536, partition: 0 },
	{ label: "SISFU.V", value: 1323145, partition: 0 },
	{ label: "SSBDD.V", value: 1323146, partition: 0 },
	{ label: "KDSFY.V", value: 3352841, partition: 0 },
	{ label: "SI1AE.V", value: 1323147, partition: 0 },
	{ label: "OYIAV.V", value: 404747, partition: 0 },
	{ label: "SPSRQ.V", value: 5123431, partition: 0 },
	{ label: "PWSCW.V", value: 2109118, partition: 0 },
	{ label: "PDBQH.V", value: 2633098, partition: 0 },
	{ label: "PPIBR.V", value: 2109128, partition: 0 },
	{ label: "METUR.E", value: 76556, partition: 0 },
	{ label: "PBAVN.V", value: 2109130, partition: 0 },
	{ label: "PBSDA.V", value: 2109131, partition: 0 },
	{ label: "NXSPV.V", value: 3157093, partition: 0 },
	{ label: "SOICF.V", value: 1847150, partition: 0 },
	{ label: "KC1AV.V", value: 5253009, partition: 0 },
	{ label: "SASFC.V", value: 1847152, partition: 0 },
	{ label: "NOBBL.V", value: 3157097, partition: 0 },
	{ label: "SAACA.V", value: 1847153, partition: 0 },
	{ label: "SIAWF.V", value: 3813512, partition: 0 },
	{ label: "SA1AI.V", value: 1847155, partition: 0 },
	{ label: "SISSB.V", value: 3813513, partition: 0 },
	{ label: "SSBDJ.V", value: 3813514, partition: 0 },
	{ label: "SI1AO.V", value: 3813515, partition: 0 },
	{ label: "NABCZ.V", value: 928758, partition: 0 },
	{ label: "OY1AM.V", value: 2895116, partition: 0 },
	{ label: "MGROS.E", value: 76576, partition: 0 },
	{ label: "PTIUL.V", value: 2633130, partition: 0 },
	{ label: "PEAWU.V", value: 2633132, partition: 0 },
	{ label: "PESFW.V", value: 2633133, partition: 0 },
	{ label: "OD1PJ.V", value: 3157113, partition: 0 },
	{ label: "PPBDP.V", value: 4599494, partition: 0 },
	{ label: "KT1AJ.V", value: 2500703, partition: 0 },
	{ label: "NVBDA.V", value: 928770, partition: 0 },
	{ label: "KTIPH.V", value: 2500704, partition: 0 },
	{ label: "PLBQF.V", value: 2371154, partition: 0 },
	{ label: "PPBDA.V", value: 16789190, partition: 0 },
	{ label: "MIPAZ.E", value: 76596, partition: 0 },
	{ label: "SLIYM.V", value: 1323205, partition: 0 },
	{ label: "KDIZN.V", value: 2238727, partition: 0 },
	{ label: "KRAVV.V", value: 2238728, partition: 0 },
	{ label: "KDSFR.V", value: 2238729, partition: 0 },
	{ label: "SOBDL.V", value: 4337522, partition: 0 },
	{ label: "SA1AS.V", value: 4337523, partition: 0 },
	{ label: "NASFM.V", value: 3419124, partition: 0 },
	{ label: "OY1AW.V", value: 5385484, partition: 0 },
	{ label: "PWBBU.V", value: 7089855, partition: 0 },
	{ label: "PDBQK.V", value: 7613834, partition: 0 },
	{ label: "PEBDK.V", value: 5123502, partition: 0 },
	{ label: "KTAPQ.V", value: 4991068, partition: 0 },
	{ label: "OD1PS.V", value: 5647481, partition: 0 },
	{ label: "PE1AU.V", value: 5123503, partition: 0 },
	{ label: "MMCAS.E", value: 76616, partition: 0 },
	{ label: "PPBDW.V", value: 7089862, partition: 0 },
	{ label: "KT1AT.V", value: 4991071, partition: 0 },
	{ label: "NVSPW.V", value: 3419137, partition: 0 },
	{ label: "PGIWB.V", value: 2633183, partition: 0 },
	{ label: "SLAUG.V", value: 3813574, partition: 0 },
	{ label: "PGAUN.V", value: 2633184, partition: 0 },
	{ label: "KRBDH.V", value: 4729098, partition: 0 },
	{ label: "SLBDD.V", value: 3813576, partition: 0 },
	{ label: "PGSBG.V", value: 2633186, partition: 0 },
	{ label: "KR1AR.V", value: 4729099, partition: 0 },
	{ label: "SL1AP.V", value: 3813577, partition: 0 },
	{ label: "PG1AJ.V", value: 2633187, partition: 0 },
	{ label: "MNDRS.E", value: 76636, partition: 0 },
	{ label: "RHBRL.V", value: 4599546, partition: 0 },
	{ label: "MNDTR.E", value: 76641, partition: 0 },
	{ label: "PWBRH.V", value: 9580223, partition: 0 },
	{ label: "MPARK.E", value: 76646, partition: 0 },
	{ label: "PPIBK.V", value: 3485384, partition: 0 },
	{ label: "KOICA.V", value: 534410, partition: 0 },
	{ label: "MTBQO.V", value: 3943147, partition: 0 },
	{ label: "PPBTD.V", value: 9580230, partition: 0 },
	{ label: "NVBRU.V", value: 5909506, partition: 0 },
	{ label: "KZIZB.V", value: 2500771, partition: 0 },
	{ label: "KHBCY.V", value: 534415, partition: 0 },
	{ label: "KZAVB.V", value: 2500772, partition: 0 },
	{ label: "KHSAV.V", value: 534416, partition: 0 },
	{ label: "KZSEA.V", value: 2500773, partition: 0 },
	{ label: "SKYMD.E", value: 209090, partition: 0 },
	{ label: "PLBBN.V", value: 7351890, partition: 0 },
	{ label: "MPATA.F1", value: 76666, partition: 0 },
	{ label: "SPSFI.V", value: 2895207, partition: 0 },
	{ label: "PDBBA.V", value: 404874, partition: 0 },
	{ label: "RHBRU.V", value: 7089914, partition: 0 },
	{ label: "NABSI.V", value: 8399862, partition: 0 },
	{ label: "MSBBO.V", value: 4205161, partition: 0 },
	{ label: "MRGYO.E", value: 76676, partition: 0 },
	{ label: "NVBSD.V", value: 8399874, partition: 0 },
	{ label: "KHAHX.V", value: 3024782, partition: 0 },
	{ label: "MOBTL.E", value: 76686, partition: 0 },
	{ label: "NXBQN.V", value: 928870, partition: 0 },
	{ label: "KZSRD.V", value: 4991141, partition: 0 },
	{ label: "KZBDH.V", value: 4991142, partition: 0 },
	{ label: "KC1PI.V", value: 3024785, partition: 0 },
	{ label: "KZ1AT.V", value: 4991143, partition: 0 },
	{ label: "SSIQD.V", value: 1585286, partition: 0 },
	{ label: "SIAGZ.V", value: 1585288, partition: 0 },
	{ label: "SISFW.V", value: 1585289, partition: 0 },
	{ label: "KDSGA.V", value: 3614985, partition: 0 },
	{ label: "SI1AG.V", value: 1585291, partition: 0 },
	{ label: "MRSHL.E", value: 76696, partition: 0 },
	{ label: "OYIAX.V", value: 666891, partition: 0 },
	{ label: "SPSRS.V", value: 5385575, partition: 0 },
	{ label: "PTIFG.V", value: 404906, partition: 0 },
	{ label: "PEAHX.V", value: 404908, partition: 0 },
	{ label: "KTAAU.V", value: 272476, partition: 0 },
	{ label: "PE1AX.V", value: 404911, partition: 0 },
	{ label: "MOGAN.E", value: 76706, partition: 0 },
	{ label: "PPIQP.V", value: 2371272, partition: 0 },
	{ label: "PBAVP.V", value: 2371274, partition: 0 },
	{ label: "SOICH.V", value: 2109294, partition: 0 },
	{ label: "KZSQY.V", value: 7481509, partition: 0 },
	{ label: "KC1PR.V", value: 5515153, partition: 0 },
	{ label: "NOBBN.V", value: 3419241, partition: 0 },
	{ label: "SASFE.V", value: 2109296, partition: 0 },
	{ label: "SAACC.V", value: 2109297, partition: 0 },
	{ label: "SIAWH.V", value: 4075656, partition: 0 },
	{ label: "SA1PF.V", value: 2109299, partition: 0 },
	{ label: "SISSD.V", value: 4075657, partition: 0 },
	{ label: "MSGYO.E", value: 76726, partition: 0 },
	{ label: "SSBDL.V", value: 4075658, partition: 0 },
	{ label: "SI1AQ.V", value: 4075659, partition: 0 },
	{ label: "NABDB.V", value: 1190902, partition: 0 },
	{ label: "OY1PJ.V", value: 3157260, partition: 0 },
	{ label: "PEAIB.V", value: 2895276, partition: 0 },
	{ label: "PESFX.V", value: 2895277, partition: 0 },
	{ label: "MTRYO.E", value: 76736, partition: 0 },
	{ label: "OD1PL.V", value: 3419257, partition: 0 },
	{ label: "PPBDR.V", value: 4861638, partition: 0 },
	{ label: "KT1AL.V", value: 2762847, partition: 0 },
	{ label: "NVBDC.V", value: 1190914, partition: 0 },
	{ label: "KTIPJ.V", value: 2762848, partition: 0 },
	{ label: "PLBQH.V", value: 2633298, partition: 0 },
	{ label: "PPBDC.V", value: 17051334, partition: 0 },
	{ label: "PGIAQ.V", value: 404959, partition: 0 },
	{ label: "SLILD.V", value: 1585349, partition: 0 },
	{ label: "KDIQA.V", value: 2500871, partition: 0 },
	{ label: "SLAEZ.V", value: 1585350, partition: 0 },
	{ label: "KRAVX.V", value: 2500872, partition: 0 },
	{ label: "PGBCS.V", value: 404961, partition: 0 },
	{ label: "SLSDD.V", value: 1585351, partition: 0 },
	{ label: "SOBDN.V", value: 4599666, partition: 0 },
	{ label: "SA1PO.V", value: 4599667, partition: 0 },
	{ label: "OY1PS.V", value: 5647628, partition: 0 },
	{ label: "PWBQQ.V", value: 7351999, partition: 0 },
	{ label: "PDBQM.V", value: 7875978, partition: 0 },
	{ label: "PEBDM.V", value: 5385646, partition: 0 },
	{ label: "KTAPS.V", value: 5253212, partition: 0 },
	{ label: "OD1PU.V", value: 5909625, partition: 0 },
	{ label: "PE1PR.V", value: 5385647, partition: 0 },
	{ label: "MTSBU.V", value: 1714922, partition: 0 },
	{ label: "PPBDY.V", value: 7352006, partition: 0 },
	{ label: "KT1AV.V", value: 5253215, partition: 0 },
	{ label: "NVSCK.V", value: 3681281, partition: 0 },
	{ label: "KZIKN.V", value: 272547, partition: 0 },
	{ label: "MZHLD.E", value: 76776, partition: 0 },
	{ label: "KZBCR.V", value: 272550, partition: 0 },
	{ label: "KZ1AW.V", value: 272551, partition: 0 },
	{ label: "SLAUI.V", value: 4075718, partition: 0 },
	{ label: "PGAFP.V", value: 2895328, partition: 0 },
	{ label: "SLSQV.V", value: 4075719, partition: 0 },
	{ label: "PGBCW.V", value: 2895329, partition: 0 },
	{ label: "KRBDJ.V", value: 4991242, partition: 0 },
	{ label: "SLBDF.V", value: 4075720, partition: 0 },
	{ label: "PGSBI.V", value: 2895330, partition: 0 },
	{ label: "KR1AT.V", value: 4991243, partition: 0 },
	{ label: "SL1PM.V", value: 4075721, partition: 0 },
	{ label: "PG1AL.V", value: 2895331, partition: 0 },
	{ label: "RHBRN.V", value: 4861690, partition: 0 },
	{ label: "NABSB.V", value: 6171638, partition: 0 },
	{ label: "NETAS.E", value: 76796, partition: 0 },
	{ label: "PPIBM.V", value: 3747528, partition: 0 },
	{ label: "MTSPV.V", value: 4205290, partition: 0 },
	{ label: "KOICC.V", value: 796554, partition: 0 },
	{ label: "MTBBS.V", value: 4205291, partition: 0 },
	{ label: "NVBRW.V", value: 6171650, partition: 0 },
	{ label: "KZIZD.V", value: 2762915, partition: 0 },
	{ label: "KZAVD.V", value: 2762916, partition: 0 },
	{ label: "KHBDA.V", value: 796559, partition: 0 },
	{ label: "KHSAX.V", value: 796560, partition: 0 },
	{ label: "KZBCV.V", value: 2762918, partition: 0 },
	{ label: "PLBQK.V", value: 7614034, partition: 0 },
	{ label: "NIBAS.E", value: 76816, partition: 0 },
	{ label: "SPSFK.V", value: 3157351, partition: 0 },
	{ label: "PDBBC.V", value: 667018, partition: 0 },
	{ label: "RHBRW.V", value: 7352058, partition: 0 },
	{ label: "NABSK.V", value: 8662006, partition: 0 },
	{ label: "MSBBQ.V", value: 4467305, partition: 0 },
	{ label: "NTGAZ.E", value: 76831, partition: 0 },
	{ label: "NTHOL.E", value: 76836, partition: 0 },
	{ label: "NVBSF.V", value: 8662018, partition: 0 },
	{ label: "KHAHZ.V", value: 3286926, partition: 0 },
	{ label: "NXBQP.V", value: 1191014, partition: 0 },
	{ label: "KHBDE.V", value: 3286927, partition: 0 },
	{ label: "KHSAO.V", value: 3286928, partition: 0 },
	{ label: "KZBDJ.V", value: 5253286, partition: 0 },
	{ label: "KC1PK.V", value: 3286929, partition: 0 },
	{ label: "KZ1AV.V", value: 5253287, partition: 0 },
	{ label: "SSIQF.V", value: 1847430, partition: 0 },
	{ label: "SIAHB.V", value: 1847432, partition: 0 },
	{ label: "SISFZ.V", value: 1847433, partition: 0 },
	{ label: "KDSRX.V", value: 3877129, partition: 0 },
	{ label: "OYBBY.V", value: 929034, partition: 0 },
	{ label: "SI1AI.V", value: 1847435, partition: 0 },
	{ label: "OYIPU.V", value: 929035, partition: 0 },
	{ label: "PTIFI.V", value: 667050, partition: 0 },
	{ label: "KTAAW.V", value: 534620, partition: 0 },
	{ label: "PEBCY.V", value: 667054, partition: 0 },
	{ label: "PE1AZ.V", value: 667055, partition: 0 },
	{ label: "PPIQR.V", value: 2633416, partition: 0 },
	{ label: "PLBBA.V", value: 405074, partition: 0 },
	{ label: "KDILG.V", value: 272647, partition: 0 },
	{ label: "SOIRE.V", value: 2371438, partition: 0 },
	{ label: "NUGYO.E", value: 76876, partition: 0 },
	{ label: "KC1PT.V", value: 5777297, partition: 0 },
	{ label: "NOBQK.V", value: 3681385, partition: 0 },
	{ label: "SASFG.V", value: 2371440, partition: 0 },
	{ label: "SAAQZ.V", value: 2371441, partition: 0 },
	{ label: "KR1AW.V", value: 272651, partition: 0 },
	{ label: "SA1PH.V", value: 2371443, partition: 0 },
	{ label: "SSBDN.V", value: 4337802, partition: 0 },
	{ label: "SI1AS.V", value: 4337803, partition: 0 },
	{ label: "NAICF.V", value: 1453045, partition: 0 },
	{ label: "NABDD.V", value: 1453046, partition: 0 },
	{ label: "OY1PL.V", value: 3419404, partition: 0 },
	{ label: "PWBBX.V", value: 5123775, partition: 0 },
	{ label: "PEAID.V", value: 3157420, partition: 0 },
	{ label: "PESFZ.V", value: 3157421, partition: 0 },
	{ label: "OD1AN.V", value: 3681401, partition: 0 },
	{ label: "PPBSH.V", value: 5123782, partition: 0 },
	{ label: "KT1PI.V", value: 3024991, partition: 0 },
	{ label: "KTIAL.V", value: 3024992, partition: 0 },
	{ label: "NUHCM.E", value: 76896, partition: 0 },
	{ label: "NVBDE.V", value: 1453058, partition: 0 },
	{ label: "PPBDE.V", value: 17313478, partition: 0 },
	{ label: "KDIQC.V", value: 2763015, partition: 0 },
	{ label: "PGIAS.V", value: 667103, partition: 0 },
	{ label: "SLILF.V", value: 1847493, partition: 0 },
	{ label: "KRAVZ.V", value: 2763016, partition: 0 },
	{ label: "SLAFB.V", value: 1847494, partition: 0 },
	{ label: "PGBCU.V", value: 667105, partition: 0 },
	{ label: "SLSDF.V", value: 1847495, partition: 0 },
	{ label: "OBAMS.E", value: 76911, partition: 0 },
	{ label: "SA1PQ.V", value: 4861811, partition: 0 },
	{ label: "ODAS.E", value: 76916, partition: 0 },
	{ label: "OY1PU.V", value: 5909772, partition: 0 },
	{ label: "ODINE.E", value: 76918, partition: 0 },
	{ label: "PWBQS.V", value: 7614143, partition: 0 },
	{ label: "PESRX.V", value: 5647789, partition: 0 },
	{ label: "PEBDO.V", value: 5647790, partition: 0 },
	{ label: "PE1PT.V", value: 5647791, partition: 0 },
	{ label: "MTSBW.V", value: 1977066, partition: 0 },
	{ label: "PPBEA.V", value: 7614150, partition: 0 },
	{ label: "KT1PR.V", value: 5515359, partition: 0 },
	{ label: "KZILA.V", value: 534691, partition: 0 },
	{ label: "OMDBE.F2", value: 76931, partition: 0 },
	{ label: "OMGIO.F2", value: 76933, partition: 0 },
	{ label: "KZBCT.V", value: 534694, partition: 0 },
	{ label: "KZ1AY.V", value: 534695, partition: 0 },
	{ label: "PGAFR.V", value: 3157472, partition: 0 },
	{ label: "ONCSM.E", value: 76942, partition: 0 },
	{ label: "PGBCY.V", value: 3157473, partition: 0 },
	{ label: "KRBDL.V", value: 5253386, partition: 0 },
	{ label: "SLBDH.V", value: 4337864, partition: 0 },
	{ label: "PGSPR.V", value: 3157474, partition: 0 },
	{ label: "KR1AV.V", value: 5253387, partition: 0 },
	{ label: "SL1PO.V", value: 4337865, partition: 0 },
	{ label: "PG1AN.V", value: 3157475, partition: 0 },
	{ label: "OPX30.F", value: 76946, partition: 0 },
	{ label: "RHBRP.V", value: 5123834, partition: 0 },
	{ label: "NABSD.V", value: 6433782, partition: 0 },
	{ label: "ORGE.E", value: 76956, partition: 0 },
	{ label: "PPIQK.V", value: 4009672, partition: 0 },
	{ label: "KOIQZ.V", value: 1058698, partition: 0 },
	{ label: "MTBBU.V", value: 4467435, partition: 0 },
	{ label: "NVBRY.V", value: 6433794, partition: 0 },
	{ label: "KZAGF.V", value: 3025060, partition: 0 },
	{ label: "KHBDC.V", value: 1058703, partition: 0 },
	{ label: "KHSPN.V", value: 1058704, partition: 0 },
	{ label: "KZBCX.V", value: 3025062, partition: 0 },
	{ label: "PLBQM.V", value: 7876178, partition: 0 },
	{ label: "SPSFM.V", value: 3419495, partition: 0 },
	{ label: "ORMA.E", value: 76976, partition: 0 },
	{ label: "PWSCK.V", value: 405182, partition: 0 },
	{ label: "PDBPZ.V", value: 929162, partition: 0 },
	{ label: "RHBRY.V", value: 7614202, partition: 0 },
	{ label: "NABSM.V", value: 8924150, partition: 0 },
	{ label: "MSBBS.V", value: 4729449, partition: 0 },
	{ label: "PBAGI.V", value: 405194, partition: 0 },
	{ label: "PBSCP.V", value: 405195, partition: 0 },
	{ label: "NVBSH.V", value: 8924162, partition: 0 },
	{ label: "KHAWW.V", value: 3549070, partition: 0 },
	{ label: "OSMEN.E", value: 76996, partition: 0 },
	{ label: "NXBQR.V", value: 1453158, partition: 0 },
	{ label: "KHBDG.V", value: 3549071, partition: 0 },
	{ label: "KHSAQ.V", value: 3549072, partition: 0 },
	{ label: "KC1PM.V", value: 3549073, partition: 0 },
	{ label: "KZ1PS.V", value: 5515431, partition: 0 },
	{ label: "SSIMD.V", value: 2109574, partition: 0 },
	{ label: "SIAHD.V", value: 2109576, partition: 0 },
	{ label: "SISGB.V", value: 2109577, partition: 0 },
	{ label: "KDSRZ.V", value: 4139273, partition: 0 },
	{ label: "SI1PF.V", value: 2109579, partition: 0 },
	{ label: "OYBCA.V", value: 1191178, partition: 0 },
	{ label: "OYIPW.V", value: 1191179, partition: 0 },
	{ label: "PWSQC.V", value: 2895550, partition: 0 },
	{ label: "PTIUD.V", value: 929194, partition: 0 },
	{ label: "KTAAY.V", value: 796764, partition: 0 },
	{ label: "PEBDA.V", value: 929198, partition: 0 },
	{ label: "PE1PV.V", value: 929199, partition: 0 },
	{ label: "OSTIM.E", value: 77016, partition: 0 },
	{ label: "PBSQI.V", value: 2895563, partition: 0 },
	{ label: "PLBBC.V", value: 667218, partition: 0 },
	{ label: "SOIRG.V", value: 2633582, partition: 0 },
	{ label: "KDILI.V", value: 534791, partition: 0 },
	{ label: "NOBQM.V", value: 3943529, partition: 0 },
	{ label: "SASFI.V", value: 2633584, partition: 0 },
	{ label: "SAARB.V", value: 2633585, partition: 0 },
	{ label: "KRBCX.V", value: 534794, partition: 0 },
	{ label: "KR1AY.V", value: 534795, partition: 0 },
	{ label: "SA1AJ.V", value: 2633587, partition: 0 },
	{ label: "OTKAR.E", value: 77036, partition: 0 },
	{ label: "SSBDP.V", value: 4599946, partition: 0 },
	{ label: "NASEZ.V", value: 1715188, partition: 0 },
	{ label: "SI1PO.V", value: 4599947, partition: 0 },
	{ label: "NAICH.V", value: 1715189, partition: 0 },
	{ label: "NABRV.V", value: 1715190, partition: 0 },
	{ label: "OY1AN.V", value: 3681548, partition: 0 },
	{ label: "OTTO.E", value: 77041, partition: 0 },
	{ label: "PWBBZ.V", value: 5385919, partition: 0 },
	{ label: "PEAIF.V", value: 3419564, partition: 0 },
	{ label: "PESGB.V", value: 3419565, partition: 0 },
	{ label: "OYAKC.E", value: 77046, partition: 0 },
	{ label: "OD1AP.V", value: 3943545, partition: 0 },
	{ label: "PE1AM.V", value: 3419567, partition: 0 },
	{ label: "PPBSJ.V", value: 5385926, partition: 0 },
	{ label: "KT1PK.V", value: 3287135, partition: 0 },
	{ label: "NVSBY.V", value: 1715201, partition: 0 },
	{ label: "KTIAN.V", value: 3287136, partition: 0 },
	{ label: "NVBRS.V", value: 1715202, partition: 0 },
	{ label: "OYAYO.E", value: 77056, partition: 0 },
	{ label: "PPBRU.V", value: 17575622, partition: 0 },
	{ label: "SLILH.V", value: 2109637, partition: 0 },
	{ label: "PGIWD.V", value: 929247, partition: 0 },
	{ label: "SLAFD.V", value: 2109638, partition: 0 },
	{ label: "KRAHB.V", value: 3025160, partition: 0 },
	{ label: "SLSDH.V", value: 2109639, partition: 0 },
	{ label: "RHBCI.V", value: 2895610, partition: 0 },
	{ label: "OYBCN.V", value: 6171914, partition: 0 },
	{ label: "PWBQU.V", value: 7876287, partition: 0 },
	{ label: "OYLUM.E", value: 77076, partition: 0 },
	{ label: "PEBDQ.V", value: 5909934, partition: 0 },
	{ label: "PE1PY.V", value: 5909935, partition: 0 },
	{ label: "MTSBY.V", value: 2239210, partition: 0 },
	{ label: "PPBEC.V", value: 7876294, partition: 0 },
	{ label: "KT1PT.V", value: 5777503, partition: 0 },
	{ label: "KZILC.V", value: 796835, partition: 0 },
	{ label: "OYYAT.E", value: 77086, partition: 0 },
	{ label: "KZ1BA.V", value: 796839, partition: 0 },
	{ label: "PGAFT.V", value: 3419616, partition: 0 },
	{ label: "PGBDA.V", value: 3419617, partition: 0 },
	{ label: "KRBDN.V", value: 5515530, partition: 0 },
	{ label: "SLBDJ.V", value: 4600008, partition: 0 },
	{ label: "KR1PS.V", value: 5515531, partition: 0 },
	{ label: "SL1PQ.V", value: 4600009, partition: 0 },
	{ label: "PG1PK.V", value: 3419619, partition: 0 },
	{ label: "RHBRR.V", value: 5385978, partition: 0 },
	{ label: "NABDF.V", value: 6695926, partition: 0 },
	{ label: "PPIQM.V", value: 4271816, partition: 0 },
	{ label: "KOIRB.V", value: 1320842, partition: 0 },
	{ label: "MTBBW.V", value: 4729579, partition: 0 },
	{ label: "OZGYO.E", value: 77116, partition: 0 },
	{ label: "NVBSA.V", value: 6695938, partition: 0 },
	{ label: "KZAGH.V", value: 3287204, partition: 0 },
	{ label: "KZBCZ.V", value: 3287206, partition: 0 },
	{ label: "PWSCM.V", value: 667326, partition: 0 },
	{ label: "PDBQB.V", value: 1191306, partition: 0 },
	{ label: "RHBSA.V", value: 7876346, partition: 0 },
	{ label: "OZKGY.E", value: 77136, partition: 0 },
	{ label: "KARYE.E", value: 206693, partition: 0 },
	{ label: "MSBQP.V", value: 4991593, partition: 0 },
	{ label: "PBAGK.V", value: 667338, partition: 0 },
	{ label: "PBSCR.V", value: 667339, partition: 0 },
	{ label: "OZRDN.E", value: 77146, partition: 0 },
	{ label: "NVBSJ.V", value: 9186306, partition: 0 },
	{ label: "NXSBR.V", value: 1715301, partition: 0 },
	{ label: "KHAWY.V", value: 3811214, partition: 0 },
	{ label: "NXBBX.V", value: 1715302, partition: 0 },
	{ label: "KHBDI.V", value: 3811215, partition: 0 },
	{ label: "SOIBZ.V", value: 405358, partition: 0 },
	{ label: "KHSAS.V", value: 3811216, partition: 0 },
	{ label: "KC1AO.V", value: 3811217, partition: 0 },
	{ label: "NOBBF.V", value: 1715305, partition: 0 },
	{ label: "KZ1PU.V", value: 5777575, partition: 0 },
	{ label: "SSIZN.V", value: 2371718, partition: 0 },
	{ label: "PAGYO.E", value: 77156, partition: 0 },
	{ label: "SOBCW.V", value: 405362, partition: 0 },
	{ label: "SIAVZ.V", value: 2371720, partition: 0 },
	{ label: "SI1PH.V", value: 2371723, partition: 0 },
	{ label: "OYBCC.V", value: 1453322, partition: 0 },
	{ label: "OYIPY.V", value: 1453323, partition: 0 },
	{ label: "PAMEL.E", value: 77162, partition: 0 },
	{ label: "PWSQE.V", value: 3157694, partition: 0 },
	{ label: "PTIUF.V", value: 1191338, partition: 0 },
	{ label: "KBORU.E", value: 206723, partition: 0 },
	{ label: "PESFR.V", value: 1191341, partition: 0 },
	{ label: "KTAPV.V", value: 1058908, partition: 0 },
	{ label: "PEBDC.V", value: 1191342, partition: 0 },
	{ label: "PE1PX.V", value: 1191343, partition: 0 },
	{ label: "PATEK.E", value: 77171, partition: 0 },
	{ label: "KOIBY.V", value: 206730, partition: 0 },
	{ label: "PARSN.E", value: 77176, partition: 0 },
	{ label: "PBSQK.V", value: 3157707, partition: 0 },
	{ label: "PLBPZ.V", value: 929362, partition: 0 },
	{ label: "PASEU.E", value: 77181, partition: 0 },
	{ label: "NXSPT.V", value: 4205669, partition: 0 },
	{ label: "KDILK.V", value: 796935, partition: 0 },
	{ label: "KZBPP.V", value: 8267942, partition: 0 },
	{ label: "SASFJ.V", value: 2895728, partition: 0 },
	{ label: "SAACD.V", value: 2895729, partition: 0 },
	{ label: "KRBCZ.V", value: 796938, partition: 0 },
	{ label: "KR1BA.V", value: 796939, partition: 0 },
	{ label: "SA1AL.V", value: 2895731, partition: 0 },
	{ label: "NASFB.V", value: 1977332, partition: 0 },
	{ label: "SI1PQ.V", value: 4862091, partition: 0 },
	{ label: "NAIRF.V", value: 1977333, partition: 0 },
	{ label: "NABRX.V", value: 1977334, partition: 0 },
	{ label: "OY1AP.V", value: 3943692, partition: 0 },
	{ label: "PWBCB.V", value: 5648063, partition: 0 },
	{ label: "PEAWX.V", value: 3681708, partition: 0 },
	{ label: "PESRW.V", value: 3681709, partition: 0 },
	{ label: "PEHOL.E", value: 77201, partition: 0 },
	{ label: "OD1AR.V", value: 4205689, partition: 0 },
	{ label: "PE1AO.V", value: 3681711, partition: 0 },
	{ label: "PPBSL.V", value: 5648070, partition: 0 },
	{ label: "KT1PM.V", value: 3549279, partition: 0 },
	{ label: "NVSCA.V", value: 1977345, partition: 0 },
	{ label: "KTIPK.V", value: 3549280, partition: 0 },
	{ label: "PEKGY.E", value: 77206, partition: 0 },
	{ label: "PPBRW.V", value: 17837766, partition: 0 },
	{ label: "PENGD.E", value: 77216, partition: 0 },
	{ label: "SLIZA.V", value: 2371781, partition: 0 },
	{ label: "PGIWF.V", value: 1191391, partition: 0 },
	{ label: "SLAUA.V", value: 2371782, partition: 0 },
	{ label: "KRAHD.V", value: 3287304, partition: 0 },
	{ label: "SLSDI.V", value: 2371783, partition: 0 },
	{ label: "PENTA.E", value: 77221, partition: 0 },
	{ label: "RHBCK.V", value: 3157754, partition: 0 },
	{ label: "NASRJ.V", value: 4467700, partition: 0 },
	{ label: "OYBCP.V", value: 6434058, partition: 0 },
	{ label: "PWBCC.V", value: 8138431, partition: 0 },
	{ label: "PEBQJ.V", value: 6172078, partition: 0 },
	{ label: "MTSCA.V", value: 2501354, partition: 0 },
	{ label: "PETKM.E", value: 77236, partition: 0 },
	{ label: "PPBSS.V", value: 8138438, partition: 0 },
	{ label: "KZIYK.V", value: 1058979, partition: 0 },
	{ label: "KZSDN.V", value: 1058981, partition: 0 },
	{ label: "KFEIN.E", value: 206798, partition: 0 },
	{ label: "KZ1PW.V", value: 1058983, partition: 0 },
	{ label: "PGAUQ.V", value: 3681760, partition: 0 },
	{ label: "PGBDC.V", value: 3681761, partition: 0 },
	{ label: "KRBDP.V", value: 5777674, partition: 0 },
	{ label: "KR1PU.V", value: 5777675, partition: 0 },
	{ label: "SL1AS.V", value: 4862153, partition: 0 },
	{ label: "PG1PM.V", value: 3681763, partition: 0 },
	{ label: "PETUN.E", value: 77256, partition: 0 },
	{ label: "RHBCT.V", value: 5648122, partition: 0 },
	{ label: "NABDH.V", value: 6958070, partition: 0 },
	{ label: "KIMMR.E", value: 206818, partition: 0 },
	{ label: "KOICD.V", value: 1582986, partition: 0 },
	{ label: "MTBQR.V", value: 4991723, partition: 0 },
	{ label: "NVBDG.V", value: 6958082, partition: 0 },
	{ label: "KHAHR.V", value: 1582990, partition: 0 },
	{ label: "KZAVE.V", value: 3549348, partition: 0 },
	{ label: "KZSDU.V", value: 3549349, partition: 0 },
	{ label: "KZBPK.V", value: 3549350, partition: 0 },
	{ label: "PGSUS.E", value: 77276, partition: 0 },
	{ label: "KZ1AN.V", value: 3549351, partition: 0 },
	{ label: "PWSCO.V", value: 929470, partition: 0 },
	{ label: "PDBQD.V", value: 1453450, partition: 0 },
	{ label: "MSBQR.V", value: 5253737, partition: 0 },
	{ label: "PBAVH.V", value: 929482, partition: 0 },
	{ label: "PBSCT.V", value: 929483, partition: 0 },
	{ label: "NXSBT.V", value: 1977445, partition: 0 },
	{ label: "KHAXA.V", value: 4073358, partition: 0 },
	{ label: "KHBDK.V", value: 4073359, partition: 0 },
	{ label: "SOICB.V", value: 667502, partition: 0 },
	{ label: "KHSPK.V", value: 4073360, partition: 0 },
	{ label: "KC1AQ.V", value: 4073361, partition: 0 },
	{ label: "NOBBH.V", value: 1977449, partition: 0 },
	{ label: "SSIQA.V", value: 2633862, partition: 0 },
	{ label: "SOBCY.V", value: 667506, partition: 0 },
	{ label: "SIAWB.V", value: 2633864, partition: 0 },
	{ label: "OYBCE.V", value: 1715466, partition: 0 },
	{ label: "SI1AJ.V", value: 2633867, partition: 0 },
	{ label: "OYIBA.V", value: 1715467, partition: 0 },
	{ label: "PINSU.E", value: 77316, partition: 0 },
	{ label: "PTIUH.V", value: 1453482, partition: 0 },
	{ label: "KTAPX.V", value: 1321052, partition: 0 },
	{ label: "PEBDE.V", value: 1453486, partition: 0 },
	{ label: "PPBDG.V", value: 3419846, partition: 0 },
	{ label: "PBSDB.V", value: 3419851, partition: 0 },
	{ label: "PLBQB.V", value: 1191506, partition: 0 },
	{ label: "PKART.E", value: 77336, partition: 0 },
	{ label: "NXBBY.V", value: 4467814, partition: 0 },
	{ label: "KDIZJ.V", value: 1059079, partition: 0 },
	{ label: "KZBPR.V", value: 8530086, partition: 0 },
	{ label: "SASFL.V", value: 3157872, partition: 0 },
	{ label: "KDSFO.V", value: 1059081, partition: 0 },
	{ label: "SAACF.V", value: 3157873, partition: 0 },
	{ label: "KRBDB.V", value: 1059082, partition: 0 },
	{ label: "KR1PW.V", value: 1059083, partition: 0 },
	{ label: "SA1AN.V", value: 3157875, partition: 0 },
	{ label: "NASFD.V", value: 2239476, partition: 0 },
	{ label: "NAIRH.V", value: 2239477, partition: 0 },
	{ label: "NABRZ.V", value: 2239478, partition: 0 },
	{ label: "OY1AR.V", value: 4205836, partition: 0 },
	{ label: "PWBQX.V", value: 5910207, partition: 0 },
	{ label: "PEAWZ.V", value: 3943852, partition: 0 },
	{ label: "PESRY.V", value: 3943853, partition: 0 },
	{ label: "PKENT.E", value: 77356, partition: 0 },
	{ label: "PEBDG.V", value: 3943854, partition: 0 },
	{ label: "OD1PO.V", value: 4467833, partition: 0 },
	{ label: "PE1AQ.V", value: 3943855, partition: 0 },
	{ label: "MTBBN.V", value: 273131, partition: 0 },
	{ label: "PPBSN.V", value: 5910214, partition: 0 },
	{ label: "KT1AO.V", value: 3811423, partition: 0 },
	{ label: "NVSCE.V", value: 2239489, partition: 0 },
	{ label: "KTIPM.V", value: 3811424, partition: 0 },
	{ label: "PPBRY.V", value: 18099910, partition: 0 },
	{ label: "PGIWH.V", value: 1453535, partition: 0 },
	{ label: "SLIZC.V", value: 2633925, partition: 0 },
	{ label: "KRAWA.V", value: 3549448, partition: 0 },
	{ label: "PGAFL.V", value: 1453536, partition: 0 },
	{ label: "SLAUC.V", value: 2633926, partition: 0 },
	{ label: "SASRJ.V", value: 5648240, partition: 0 },
	{ label: "SLSDK.V", value: 2633927, partition: 0 },
	{ label: "KR1AN.V", value: 3549451, partition: 0 },
	{ label: "PG1AF.V", value: 1453539, partition: 0 },
	{ label: "RHBCM.V", value: 3419898, partition: 0 },
	{ label: "NASRL.V", value: 4729844, partition: 0 },
	{ label: "OYBCR.V", value: 6696202, partition: 0 },
	{ label: "PWBCE.V", value: 8400575, partition: 0 },
	{ label: "PEBQL.V", value: 6434222, partition: 0 },
	{ label: "MTSCC.V", value: 2763498, partition: 0 },
	{ label: "PPBSU.V", value: 8400582, partition: 0 },
	{ label: "KZIYM.V", value: 1321123, partition: 0 },
	{ label: "PNSUT.E", value: 77396, partition: 0 },
	{ label: "KZSDP.V", value: 1321125, partition: 0 },
	{ label: "PNLSN.E", value: 77406, partition: 0 },
	{ label: "PGAUS.V", value: 3943904, partition: 0 },
	{ label: "PGBDE.V", value: 3943905, partition: 0 },
	{ label: "SPSEZ.V", value: 1715559, partition: 0 },
	{ label: "KRBQI.V", value: 6039818, partition: 0 },
	{ label: "PGSPO.V", value: 3943906, partition: 0 },
	{ label: "SL1AU.V", value: 5124297, partition: 0 },
	{ label: "PG1AP.V", value: 3943907, partition: 0 },
	{ label: "RHBCV.V", value: 5910266, partition: 0 },
	{ label: "NASRM.V", value: 7220212, partition: 0 },
	{ label: "NABDJ.V", value: 7220214, partition: 0 },
	{ label: "POLHO.E", value: 77416, partition: 0 },
	{ label: "MSBBK.V", value: 3025513, partition: 0 },
	{ label: "KOICF.V", value: 1845130, partition: 0 },
	{ label: "MTBQT.V", value: 5253867, partition: 0 },
	{ label: "NVBDI.V", value: 7220226, partition: 0 },
	{ label: "KHAHT.V", value: 1845134, partition: 0 },
	{ label: "KZAVG.V", value: 3811492, partition: 0 },
	{ label: "KZSQU.V", value: 3811493, partition: 0 },
	{ label: "KZBPM.V", value: 3811494, partition: 0 },
	{ label: "KZ1AP.V", value: 3811495, partition: 0 },
	{ label: "SSIMA.V", value: 405638, partition: 0 },
	{ label: "KDSFS.V", value: 2435337, partition: 0 },
	{ label: "POLTK.E", value: 77436, partition: 0 },
	{ label: "PWSPZ.V", value: 1191614, partition: 0 },
	{ label: "PDBBF.V", value: 1715594, partition: 0 },
	{ label: "PBAVJ.V", value: 1191626, partition: 0 },
	{ label: "PBSCV.V", value: 1191627, partition: 0 },
	{ label: "PRKAB.E", value: 77456, partition: 0 },
	{ label: "NXSBV.V", value: 2239589, partition: 0 },
	{ label: "KHBDM.V", value: 4335503, partition: 0 },
	{ label: "SOIQY.V", value: 929646, partition: 0 },
	{ label: "KHSPO.V", value: 4335504, partition: 0 },
	{ label: "KC1PN.V", value: 4335505, partition: 0 },
	{ label: "NOBQE.V", value: 2239593, partition: 0 },
	{ label: "SOBDA.V", value: 929650, partition: 0 },
	{ label: "SIAHE.V", value: 2896008, partition: 0 },
	{ label: "OYBCG.V", value: 1977610, partition: 0 },
	{ label: "SI1AL.V", value: 2896011, partition: 0 },
	{ label: "OYIBC.V", value: 1977611, partition: 0 },
	{ label: "PTIFL.V", value: 1715626, partition: 0 },
	{ label: "PRKME.E", value: 77476, partition: 0 },
	{ label: "PPBDI.V", value: 3681990, partition: 0 },
	{ label: "KTIAF.V", value: 1583200, partition: 0 },
	{ label: "PBSDD.V", value: 3681995, partition: 0 },
	{ label: "PLBQD.V", value: 1453650, partition: 0 },
	{ label: "NXBCA.V", value: 4729958, partition: 0 },
	{ label: "KDIZL.V", value: 1321223, partition: 0 },
	{ label: "SLIKO.V", value: 405701, partition: 0 },
	{ label: "KRAGV.V", value: 1321224, partition: 0 },
	{ label: "PRZMA.E", value: 77496, partition: 0 },
	{ label: "KZBPT.V", value: 8792230, partition: 0 },
	{ label: "SASFN.V", value: 3420016, partition: 0 },
	{ label: "SAACH.V", value: 3420017, partition: 0 },
	{ label: "KRBDD.V", value: 1321226, partition: 0 },
	{ label: "SLBCS.V", value: 405704, partition: 0 },
	{ label: "SOBDE.V", value: 3420018, partition: 0 },
	{ label: "SL1AX.V", value: 405705, partition: 0 },
	{ label: "SA1PK.V", value: 3420019, partition: 0 },
	{ label: "SISSA.V", value: 5386377, partition: 0 },
	{ label: "NASFF.V", value: 2501620, partition: 0 },
	{ label: "NAICJ.V", value: 2501621, partition: 0 },
	{ label: "OY1PO.V", value: 4467980, partition: 0 },
	{ label: "PSDTC.E", value: 77506, partition: 0 },
	{ label: "PWSQB.V", value: 6172350, partition: 0 },
	{ label: "PWBQZ.V", value: 6172351, partition: 0 },
	{ label: "PSGYO.E", value: 77508, partition: 0 },
	{ label: "PESSA.V", value: 4205997, partition: 0 },
	{ label: "PEBQE.V", value: 4205998, partition: 0 },
	{ label: "OD1PQ.V", value: 4729977, partition: 0 },
	{ label: "PE1PN.V", value: 4205999, partition: 0 },
	{ label: "MTBBP.V", value: 535275, partition: 0 },
	{ label: "PPBSP.V", value: 6172358, partition: 0 },
	{ label: "KT1AQ.V", value: 4073567, partition: 0 },
	{ label: "NVSCG.V", value: 2501633, partition: 0 },
	{ label: "KTIPO.V", value: 4073568, partition: 0 },
	{ label: "QNBFB.E", value: 77516, partition: 0 },
	{ label: "QNBFL.E", value: 77518, partition: 0 },
	{ label: "QPERP.F1", value: 77520, partition: 0 },
	{ label: "QTEMZ.F", value: 77522, partition: 0 },
	{ label: "PPBSA.V", value: 18362054, partition: 0 },
	{ label: "RALYH.E", value: 77526, partition: 0 },
	{ label: "PGIOW.V", value: 1715679, partition: 0 },
	{ label: "KRAWC.V", value: 3811592, partition: 0 },
	{ label: "PGAFN.V", value: 1715680, partition: 0 },
	{ label: "SLAFE.V", value: 2896070, partition: 0 },
	{ label: "SLSDM.V", value: 2896071, partition: 0 },
	{ label: "KRBDF.V", value: 3811594, partition: 0 },
	{ label: "PGSBA.V", value: 1715682, partition: 0 },
	{ label: "SLBCW.V", value: 2896072, partition: 0 },
	{ label: "KR1AP.V", value: 3811595, partition: 0 },
	{ label: "PG1AH.V", value: 1715683, partition: 0 },
	{ label: "RHBCO.V", value: 3682042, partition: 0 },
	{ label: "NASRQ.V", value: 4991988, partition: 0 },
	{ label: "RAYSG.E", value: 77536, partition: 0 },
	{ label: "PWBCG.V", value: 8662719, partition: 0 },
	{ label: "MTSPW.V", value: 3025642, partition: 0 },
	{ label: "REEDR.E", value: 77546, partition: 0 },
	{ label: "PPBSW.V", value: 8662726, partition: 0 },
	{ label: "KZILD.V", value: 1583267, partition: 0 },
	{ label: "KZAFZ.V", value: 1583268, partition: 0 },
	{ label: "RGYAS.E", value: 77551, partition: 0 },
	{ label: "KZSDR.V", value: 1583269, partition: 0 },
	{ label: "PGBDG.V", value: 4206049, partition: 0 },
	{ label: "SPSFB.V", value: 1977703, partition: 0 },
	{ label: "KRBQK.V", value: 6301962, partition: 0 },
	{ label: "PGSPS.V", value: 4206050, partition: 0 },
	{ label: "SL1PR.V", value: 5386441, partition: 0 },
	{ label: "PG1AR.V", value: 4206051, partition: 0 },
	{ label: "RHBCX.V", value: 6172410, partition: 0 },
	{ label: "NASRO.V", value: 7482356, partition: 0 },
	{ label: "NABDL.V", value: 7482358, partition: 0 },
	{ label: "MSBBM.V", value: 3287657, partition: 0 },
	{ label: "RODRG.E", value: 77576, partition: 0 },
	{ label: "KOICH.V", value: 2107274, partition: 0 },
	{ label: "NVBDK.V", value: 7482370, partition: 0 },
	{ label: "KHAHV.V", value: 2107278, partition: 0 },
	{ label: "KZAVI.V", value: 4073636, partition: 0 },
	{ label: "KZSQW.V", value: 4073637, partition: 0 },
	{ label: "KZBPO.V", value: 4073638, partition: 0 },
	{ label: "RPAVS.F1", value: 77586, partition: 0 },
	{ label: "KZ1PM.V", value: 4073639, partition: 0 },
	{ label: "SSIMC.V", value: 667782, partition: 0 },
	{ label: "RPFGY.F1", value: 77588, partition: 0 },
	{ label: "SSBCY.V", value: 667786, partition: 0 },
	{ label: "KDSFU.V", value: 2697481, partition: 0 },
	{ label: "SPSRI.V", value: 4468071, partition: 0 },
	{ label: "PWSCR.V", value: 1453758, partition: 0 },
	{ label: "ROYAL.E", value: 77596, partition: 0 },
	{ label: "PDBBH.V", value: 1977738, partition: 0 },
	{ label: "RTALB.E", value: 77606, partition: 0 },
	{ label: "PBAGN.V", value: 1453770, partition: 0 },
	{ label: "PBSQH.V", value: 1453771, partition: 0 },
	{ label: "NXSBX.V", value: 2501733, partition: 0 },
	{ label: "RYGYO.E", value: 77616, partition: 0 },
	{ label: "KHBDO.V", value: 4597647, partition: 0 },
	{ label: "SOIRA.V", value: 1191790, partition: 0 },
	{ label: "KC1PP.V", value: 4597649, partition: 0 },
	{ label: "NOBQG.V", value: 2501737, partition: 0 },
	{ label: "SOBDC.V", value: 1191794, partition: 0 },
	{ label: "SIAHG.V", value: 3158152, partition: 0 },
	{ label: "SISFY.V", value: 3158153, partition: 0 },
	{ label: "OYBCI.V", value: 2239754, partition: 0 },
	{ label: "SI1AN.V", value: 3158155, partition: 0 },
	{ label: "OYIPZ.V", value: 2239755, partition: 0 },
	{ label: "RUBNS.E", value: 77626, partition: 0 },
	{ label: "NABCU.V", value: 273398, partition: 0 },
	{ label: "PTIFN.V", value: 1977770, partition: 0 },
	{ label: "PEAHZ.V", value: 1977772, partition: 0 },
	{ label: "OD1AJ.V", value: 2501753, partition: 0 },
	{ label: "RYSAS.E", value: 77636, partition: 0 },
	{ label: "PPBDK.V", value: 3944134, partition: 0 },
	{ label: "NVBCV.V", value: 273410, partition: 0 },
	{ label: "KTIAH.V", value: 1845344, partition: 0 },
	{ label: "PBAVK.V", value: 3944138, partition: 0 },
	{ label: "PBSQN.V", value: 3944139, partition: 0 },
	{ label: "PLBBF.V", value: 1715794, partition: 0 },
	{ label: "PPBCV.V", value: 16133830, partition: 0 },
	{ label: "NXBQT.V", value: 4992102, partition: 0 },
	{ label: "SLILB.V", value: 667845, partition: 0 },
	{ label: "KDILL.V", value: 1583367, partition: 0 },
	{ label: "SASRI.V", value: 3682160, partition: 0 },
	{ label: "SAARE.V", value: 3682161, partition: 0 },
	{ label: "SLBCU.V", value: 667848, partition: 0 },
	{ label: "SOBDG.V", value: 3682162, partition: 0 },
	{ label: "SL1AZ.V", value: 667849, partition: 0 },
	{ label: "SA1PM.V", value: 3682163, partition: 0 },
	{ label: "NASFH.V", value: 2763764, partition: 0 },
	{ label: "NAICL.V", value: 2763765, partition: 0 },
	{ label: "OY1PQ.V", value: 4730124, partition: 0 },
	{ label: "PWBRB.V", value: 6434495, partition: 0 },
	{ label: "KTLEV.E", value: 207218, partition: 0 },
	{ label: "PDBBK.V", value: 6958474, partition: 0 },
	{ label: "PEBQG.V", value: 4468142, partition: 0 },
	{ label: "KTAAQ.V", value: 4335708, partition: 0 },
	{ label: "OD1AT.V", value: 4992121, partition: 0 },
	{ label: "PE1PP.V", value: 4468143, partition: 0 },
	{ label: "MTBBR.V", value: 797419, partition: 0 },
	{ label: "PPBSR.V", value: 6434502, partition: 0 },
	{ label: "KT1PN.V", value: 4335711, partition: 0 },
	{ label: "NVSCI.V", value: 2763777, partition: 0 },
	{ label: "SAHOL.E", value: 77676, partition: 0 },
	{ label: "PPBSC.V", value: 18624198, partition: 0 },
	{ label: "KTSKR.E", value: 207238, partition: 0 },
	{ label: "PGIOY.V", value: 1977823, partition: 0 },
	{ label: "KRAWE.V", value: 4073736, partition: 0 },
	{ label: "PGAUK.V", value: 1977824, partition: 0 },
	{ label: "SLAFG.V", value: 3158214, partition: 0 },
	{ label: "SLSQX.V", value: 3158215, partition: 0 },
	{ label: "KRBQD.V", value: 4073738, partition: 0 },
	{ label: "PGSBC.V", value: 1977826, partition: 0 },
	{ label: "SLBCY.V", value: 3158216, partition: 0 },
	{ label: "KR1PM.V", value: 4073739, partition: 0 },
	{ label: "PG1PE.V", value: 1977827, partition: 0 },
	{ label: "RHBCQ.V", value: 3944186, partition: 0 },
	{ label: "NASRS.V", value: 5254132, partition: 0 },
	{ label: "PWBRC.V", value: 8924863, partition: 0 },
	{ label: "SAMAT.E", value: 77696, partition: 0 },
	{ label: "PPBSY.V", value: 8924870, partition: 0 },
	{ label: "KZILF.V", value: 1845411, partition: 0 },
	{ label: "KZAGB.V", value: 1845412, partition: 0 },
	{ label: "KZSDV.V", value: 1845413, partition: 0 },
	{ label: "SANEL.E", value: 77716, partition: 0 },
	{ label: "PGBDI.V", value: 4468193, partition: 0 },
	{ label: "SPSFD.V", value: 2239847, partition: 0 },
	{ label: "KRBQM.V", value: 6564106, partition: 0 },
	{ label: "SL1PT.V", value: 5648585, partition: 0 },
	{ label: "PG1PN.V", value: 4468195, partition: 0 },
	{ label: "RHBCZ.V", value: 6434554, partition: 0 },
	{ label: "NABDN.V", value: 7744502, partition: 0 },
	{ label: "MSBQJ.V", value: 3549801, partition: 0 },
	{ label: "IYASG.V", value: 6043028, partition: 0 },
	{ label: "TRBDL.V", value: 4338664, partition: 0 },
	{ label: "TSSFC.V", value: 2634296, partition: 0 },
	{ label: "TP1AS.V", value: 4338665, partition: 0 },
	{ label: "IZBJD.V", value: 18232726, partition: 0 },
	{ label: "AXBSC.V", value: 6043040, partition: 0 },
	{ label: "VBTYZ.E", value: 210193, partition: 0 },
	{ label: "AXBSX.V", value: 18232736, partition: 0 },
	{ label: "YPIEM.V", value: 800394, partition: 0 },
	{ label: "YPBCZ.V", value: 800397, partition: 0 },
	{ label: "TLIOE.V", value: 405979, partition: 0 },
	{ label: "TCSAZ.V", value: 405981, partition: 0 },
	{ label: "TOIWU.V", value: 2372338, partition: 0 },
	{ label: "TLBCW.V", value: 405982, partition: 0 },
	{ label: "TL1AX.V", value: 405983, partition: 0 },
	{ label: "TSSRG.V", value: 5124664, partition: 0 },
	{ label: "TOSFC.V", value: 2372340, partition: 0 },
	{ label: "TOATE.V", value: 2372341, partition: 0 },
	{ label: "TO1PH.V", value: 2372343, partition: 0 },
	{ label: "IZBJW.V", value: 20723094, partition: 0 },
	{ label: "AXBSJ.V", value: 8533408, partition: 0 },
	{ label: "SPIJI.V", value: 667982, partition: 0 },
	{ label: "SPBCW.V", value: 667983, partition: 0 },
	{ label: "TKAWD.V", value: 2634344, partition: 0 },
	{ label: "YBAHZ.V", value: 3290764, partition: 0 },
	{ label: "YPBDD.V", value: 3290765, partition: 0 },
	{ label: "YPSBV.V", value: 3290766, partition: 0 },
	{ label: "YK1PK.V", value: 3290767, partition: 0 },
	{ label: "TLAHC.V", value: 2896348, partition: 0 },
	{ label: "TSBDL.V", value: 7615033, partition: 0 },
	{ label: "TO1PQ.V", value: 4862711, partition: 0 },
	{ label: "IZBYJ.V", value: 23213462, partition: 0 },
	{ label: "TK1AU.V", value: 5124715, partition: 0 },
	{ label: "IYIOK.V", value: 1324435, partition: 0 },
	{ label: "IZSGS.V", value: 1324437, partition: 0 },
	{ label: "IZBHW.V", value: 1324438, partition: 0 },
	{ label: "YPBQG.V", value: 5781133, partition: 0 },
	{ label: "YPSPS.V", value: 5781134, partition: 0 },
	{ label: "YK1PT.V", value: 5781135, partition: 0 },
	{ label: "AXAFK.V", value: 1324446, partition: 0 },
	{ label: "AXSDD.V", value: 1324447, partition: 0 },
	{ label: "IZBIX.V", value: 13514134, partition: 0 },
	{ label: "TCSPQ.V", value: 5386717, partition: 0 },
	{ label: "TL1PR.V", value: 5386719, partition: 0 },
	{ label: "UJBPY.V", value: 1848432, partition: 0 },
	{ label: "EJBPY.V", value: 1848433, partition: 0 },
	{ label: "ECBPY.V", value: 1848434, partition: 0 },
	{ label: "UJSBJ.V", value: 1848435, partition: 0 },
	{ label: "TRICM.V", value: 2110434, partition: 0 },
	{ label: "IYIWU.V", value: 3814803, partition: 0 },
	{ label: "IYARX.V", value: 3814804, partition: 0 },
	{ label: "TPAHP.V", value: 2110436, partition: 0 },
	{ label: "IZSSP.V", value: 3814805, partition: 0 },
	{ label: "IZBIP.V", value: 3814806, partition: 0 },
	{ label: "TPSFX.V", value: 2110439, partition: 0 },
	{ label: "TSBCW.V", value: 406073, partition: 0 },
	{ label: "TP1PF.V", value: 2110441, partition: 0 },
	{ label: "AXAUQ.V", value: 3814814, partition: 0 },
	{ label: "AXSQT.V", value: 3814815, partition: 0 },
	{ label: "IZBXK.V", value: 16004502, partition: 0 },
	{ label: "AXBCX.V", value: 3814816, partition: 0 },
	{ label: "UJBQJ.V", value: 4338800, partition: 0 },
	{ label: "EJBQJ.V", value: 4338801, partition: 0 },
	{ label: "ECBQJ.V", value: 4338802, partition: 0 },
	{ label: "AXBDS.V", value: 16004512, partition: 0 },
	{ label: "SPBSF.V", value: 8139087, partition: 0 },
	{ label: "IYASI.V", value: 6305172, partition: 0 },
	{ label: "IZSSQ.V", value: 6305173, partition: 0 },
	{ label: "TRBDN.V", value: 4600808, partition: 0 },
	{ label: "TSSFE.V", value: 2896440, partition: 0 },
	{ label: "TP1PO.V", value: 4600809, partition: 0 },
	{ label: "VWSCT.V", value: 1324511, partition: 0 },
	{ label: "IZBJF.V", value: 18494870, partition: 0 },
	{ label: "AXBSE.V", value: 6305184, partition: 0 },
	{ label: "TYICE.V", value: 406118, partition: 0 },
	{ label: "TKBCS.V", value: 406122, partition: 0 },
	{ label: "AXBSZ.V", value: 18494880, partition: 0 },
	{ label: "TK1AX.V", value: 406123, partition: 0 },
	{ label: "YPITK.V", value: 1062538, partition: 0 },
	{ label: "YPBDB.V", value: 1062541, partition: 0 },
	{ label: "TLIOG.V", value: 668123, partition: 0 },
	{ label: "TCSBB.V", value: 668125, partition: 0 },
	{ label: "TOIWW.V", value: 2634482, partition: 0 },
	{ label: "TLBCY.V", value: 668126, partition: 0 },
	{ label: "TL1AZ.V", value: 668127, partition: 0 },
	{ label: "TOSFE.V", value: 2634484, partition: 0 },
	{ label: "TOATG.V", value: 2634485, partition: 0 },
	{ label: "TO1AJ.V", value: 2634487, partition: 0 },
	{ label: "VWSQH.V", value: 3814879, partition: 0 },
	{ label: "IZBJY.V", value: 20985238, partition: 0 },
	{ label: "AXBSL.V", value: 8795552, partition: 0 },
	{ label: "SPIVC.V", value: 930126, partition: 0 },
	{ label: "SPBCY.V", value: 930127, partition: 0 },
	{ label: "TKAHF.V", value: 2896488, partition: 0 },
	{ label: "TKBCW.V", value: 2896490, partition: 0 },
	{ label: "YBAWW.V", value: 3552908, partition: 0 },
	{ label: "YPBPZ.V", value: 3552909, partition: 0 },
	{ label: "YPSBX.V", value: 3552910, partition: 0 },
	{ label: "YK1PM.V", value: 3552911, partition: 0 },
	{ label: "TLAHE.V", value: 3158492, partition: 0 },
	{ label: "TSBDN.V", value: 7877177, partition: 0 },
	{ label: "IZBYL.V", value: 23475606, partition: 0 },
	{ label: "TK1PR.V", value: 5386859, partition: 0 },
	{ label: "IYIOM.V", value: 1586579, partition: 0 },
	{ label: "IZSGU.V", value: 1586581, partition: 0 },
	{ label: "IZBHY.V", value: 1586582, partition: 0 },
	{ label: "YPBQI.V", value: 6043277, partition: 0 },
	{ label: "AXAFM.V", value: 1586590, partition: 0 },
	{ label: "AXSDF.V", value: 1586591, partition: 0 },
	{ label: "IZBWT.V", value: 13776278, partition: 0 },
	{ label: "TCSPS.V", value: 5648861, partition: 0 },
	{ label: "TL1PT.V", value: 5648863, partition: 0 },
	{ label: "UJBQA.V", value: 2110576, partition: 0 },
	{ label: "EJBQA.V", value: 2110577, partition: 0 },
	{ label: "ECBQA.V", value: 2110578, partition: 0 },
	{ label: "TYICJ.V", value: 1782374, partition: 0 },
	{ label: "SPBRY.V", value: 5910863, partition: 0 },
	{ label: "TRIRJ.V", value: 2372578, partition: 0 },
	{ label: "IYIXA.V", value: 4076947, partition: 0 },
	{ label: "IYARZ.V", value: 4076948, partition: 0 },
	{ label: "TPAWH.V", value: 2372580, partition: 0 },
	{ label: "IZSHF.V", value: 4076949, partition: 0 },
	{ label: "IZBIR.V", value: 4076950, partition: 0 },
	{ label: "TSBCY.V", value: 668217, partition: 0 },
	{ label: "TP1PH.V", value: 2372585, partition: 0 },
	{ label: "AXAUS.V", value: 4076958, partition: 0 },
	{ label: "AXSQV.V", value: 4076959, partition: 0 },
	{ label: "IZBXM.V", value: 16266646, partition: 0 },
	{ label: "AXBCZ.V", value: 4076960, partition: 0 },
	{ label: "AXBDU.V", value: 16266656, partition: 0 },
	{ label: "SPBSH.V", value: 8401231, partition: 0 },
	{ label: "SMRTG.E", value: 78046, partition: 0 },
	{ label: "TOIAW.V", value: 406258, partition: 0 },
	{ label: "TSSFG.V", value: 3158584, partition: 0 },
	{ label: "TP1PQ.V", value: 4862953, partition: 0 },
	{ label: "SNGYO.E", value: 78056, partition: 0 },
	{ label: "TOBCS.V", value: 406262, partition: 0 },
	{ label: "VWSCV.V", value: 1586655, partition: 0 },
	{ label: "IZBJH.V", value: 18757014, partition: 0 },
	{ label: "AXBDG.V", value: 6567328, partition: 0 },
	{ label: "SNKRN.E", value: 78066, partition: 0 },
	{ label: "TYICG.V", value: 668262, partition: 0 },
	{ label: "YEOTK.E", value: 210503, partition: 0 },
	{ label: "TKBCU.V", value: 668266, partition: 0 },
	{ label: "AXBTB.V", value: 18757024, partition: 0 },
	{ label: "TK1AZ.V", value: 668267, partition: 0 },
	{ label: "YPITM.V", value: 1324682, partition: 0 },
	{ label: "SNPAM.E", value: 78076, partition: 0 },
	{ label: "TLIOJ.V", value: 930267, partition: 0 },
	{ label: "TCSBD.V", value: 930269, partition: 0 },
	{ label: "SNICA.E", value: 78086, partition: 0 },
	{ label: "TLBDA.V", value: 930270, partition: 0 },
	{ label: "TL1PV.V", value: 930271, partition: 0 },
	{ label: "TOAEG.V", value: 2896629, partition: 0 },
	{ label: "TOBCW.V", value: 2896630, partition: 0 },
	{ label: "TO1AL.V", value: 2896631, partition: 0 },
	{ label: "VWSQJ.V", value: 4077023, partition: 0 },
	{ label: "IZBKA.V", value: 21247382, partition: 0 },
	{ label: "AXBSN.V", value: 9057696, partition: 0 },
	{ label: "SPIVE.V", value: 1192270, partition: 0 },
	{ label: "SPBDA.V", value: 1192271, partition: 0 },
	{ label: "TKAHH.V", value: 3158632, partition: 0 },
	{ label: "TKSGD.V", value: 3158633, partition: 0 },
	{ label: "TKBCY.V", value: 3158634, partition: 0 },
	{ label: "YBAWY.V", value: 3815052, partition: 0 },
	{ label: "YPBQB.V", value: 3815053, partition: 0 },
	{ label: "YPSPU.V", value: 3815054, partition: 0 },
	{ label: "YK1AO.V", value: 3815055, partition: 0 },
	{ label: "SODSN.E", value: 78116, partition: 0 },
	{ label: "TLAHG.V", value: 3420636, partition: 0 },
	{ label: "TLBDE.V", value: 3420638, partition: 0 },
	{ label: "TL1AM.V", value: 3420639, partition: 0 },
	{ label: "TSBSB.V", value: 8139321, partition: 0 },
	{ label: "SOKE.E", value: 78121, partition: 0 },
	{ label: "SOKM.E", value: 78126, partition: 0 },
	{ label: "IZBYN.V", value: 23737750, partition: 0 },
	{ label: "SONME.E", value: 78136, partition: 0 },
	{ label: "YPIEI.V", value: 210570, partition: 0 },
	{ label: "TK1PT.V", value: 5649003, partition: 0 },
	{ label: "IYIOO.V", value: 1848723, partition: 0 },
	{ label: "IZBIA.V", value: 1848726, partition: 0 },
	{ label: "AXAFO.V", value: 1848734, partition: 0 },
	{ label: "AXSDJ.V", value: 1848735, partition: 0 },
	{ label: "IZBWV.V", value: 14038422, partition: 0 },
	{ label: "TL1PY.V", value: 5911007, partition: 0 },
	{ label: "UJBBC.V", value: 2372720, partition: 0 },
	{ label: "EJBBC.V", value: 2372721, partition: 0 },
	{ label: "ECBBC.V", value: 2372722, partition: 0 },
	{ label: "TYICL.V", value: 2044518, partition: 0 },
	{ label: "SPBSA.V", value: 6173007, partition: 0 },
	{ label: "TRIRL.V", value: 2634722, partition: 0 },
	{ label: "IYIXC.V", value: 4339091, partition: 0 },
	{ label: "IYASB.V", value: 4339092, partition: 0 },
	{ label: "TPAWJ.V", value: 2634724, partition: 0 },
	{ label: "IZSST.V", value: 4339093, partition: 0 },
	{ label: "SRVGY.E", value: 78176, partition: 0 },
	{ label: "TWSAS.V", value: 406382, partition: 0 },
	{ label: "TSBDA.V", value: 930361, partition: 0 },
	{ label: "TP1AJ.V", value: 2634729, partition: 0 },
	{ label: "SURGY.E", value: 78181, partition: 0 },
	{ label: "AXSQX.V", value: 4339103, partition: 0 },
	{ label: "IZBXO.V", value: 16528790, partition: 0 },
	{ label: "AXBDB.V", value: 4339104, partition: 0 },
	{ label: "SUMAS.E", value: 78186, partition: 0 },
	{ label: "SUNTK.E", value: 78189, partition: 0 },
	{ label: "SUWEN.E", value: 78191, partition: 0 },
	{ label: "UJSPM.V", value: 4863091, partition: 0 },
	{ label: "AXBDW.V", value: 16528800, partition: 0 },
	{ label: "SPBSJ.V", value: 8663375, partition: 0 },
	{ label: "TABGD.E", value: 78201, partition: 0 },
	{ label: "TOIAY.V", value: 668402, partition: 0 },
	{ label: "TSSFI.V", value: 3420728, partition: 0 },
	{ label: "TOBCU.V", value: 668406, partition: 0 },
	{ label: "VWSCY.V", value: 1848799, partition: 0 },
	{ label: "IZBJJ.V", value: 19019158, partition: 0 },
	{ label: "AXBDI.V", value: 6829472, partition: 0 },
	{ label: "TYIRE.V", value: 930406, partition: 0 },
	{ label: "TATEN.E", value: 78226, partition: 0 },
	{ label: "AXBTD.V", value: 19019168, partition: 0 },
	{ label: "TK1PV.V", value: 930411, partition: 0 },
	{ label: "YPIEN.V", value: 1586826, partition: 0 },
	{ label: "YBAHR.V", value: 1586828, partition: 0 },
	{ label: "YPSBK.V", value: 1586830, partition: 0 },
	{ label: "TATGD.E", value: 78236, partition: 0 },
	{ label: "TLIOL.V", value: 1192411, partition: 0 },
	{ label: "TCSBG.V", value: 1192413, partition: 0 },
	{ label: "TLBDC.V", value: 1192414, partition: 0 },
	{ label: "TOSEZ.V", value: 3158772, partition: 0 },
	{ label: "TL1PX.V", value: 1192415, partition: 0 },
	{ label: "TOAEI.V", value: 3158773, partition: 0 },
	{ label: "TSBRU.V", value: 5911097, partition: 0 },
	{ label: "TOBCY.V", value: 3158774, partition: 0 },
	{ label: "TO1AN.V", value: 3158775, partition: 0 },
	{ label: "IZBXW.V", value: 21509526, partition: 0 },
	{ label: "AXBSP.V", value: 9319840, partition: 0 },
	{ label: "SPIJK.V", value: 1454414, partition: 0 },
	{ label: "SPBDC.V", value: 1454415, partition: 0 },
	{ label: "TAVHL.E", value: 78256, partition: 0 },
	{ label: "TKAHJ.V", value: 3420776, partition: 0 },
	{ label: "TKSGJ.V", value: 3420777, partition: 0 },
	{ label: "TKBDA.V", value: 3420778, partition: 0 },
	{ label: "TK1AM.V", value: 3420779, partition: 0 },
	{ label: "YBAXA.V", value: 4077196, partition: 0 },
	{ label: "YPBQD.V", value: 4077197, partition: 0 },
	{ label: "YK1AQ.V", value: 4077199, partition: 0 },
	{ label: "TLAWD.V", value: 3682780, partition: 0 },
	{ label: "TLBPB.V", value: 3682782, partition: 0 },
	{ label: "TL1AO.V", value: 3682783, partition: 0 },
	{ label: "TSBSD.V", value: 8401465, partition: 0 },
	{ label: "TBORG.E", value: 78276, partition: 0 },
	{ label: "IZBYP.V", value: 23999894, partition: 0 },
	{ label: "TRICE.V", value: 406498, partition: 0 },
	{ label: "TK1PY.V", value: 5911147, partition: 0 },
	{ label: "IYIAQ.V", value: 2110867, partition: 0 },
	{ label: "TCELL.E", value: 78296, partition: 0 },
	{ label: "IZBIC.V", value: 2110870, partition: 0 },
	{ label: "TRBCW.V", value: 406504, partition: 0 },
	{ label: "AXAUK.V", value: 2110878, partition: 0 },
	{ label: "AXSDL.V", value: 2110879, partition: 0 },
	{ label: "IZBWX.V", value: 14300566, partition: 0 },
	{ label: "UJBBE.V", value: 2634864, partition: 0 },
	{ label: "EJBBE.V", value: 2634865, partition: 0 },
	{ label: "ECBBE.V", value: 2634866, partition: 0 },
	{ label: "TYIRJ.V", value: 2306662, partition: 0 },
	{ label: "SPBSC.V", value: 6435151, partition: 0 },
	{ label: "TPAHQ.V", value: 2896868, partition: 0 },
	{ label: "IYADD.V", value: 4601236, partition: 0 },
	{ label: "IZSSV.V", value: 4601237, partition: 0 },
	{ label: "TWSAU.V", value: 668526, partition: 0 },
	{ label: "TP1AL.V", value: 2896873, partition: 0 },
	{ label: "TSBDC.V", value: 1192505, partition: 0 },
	{ label: "AXSDQ.V", value: 4601247, partition: 0 },
	{ label: "IZBXQ.V", value: 16790934, partition: 0 },
	{ label: "AXBDD.V", value: 4601248, partition: 0 },
	{ label: "UJSPO.V", value: 5125235, partition: 0 },
	{ label: "AXBDY.V", value: 16790944, partition: 0 },
	{ label: "SPBSL.V", value: 8925519, partition: 0 },
	{ label: "TOIWY.V", value: 930546, partition: 0 },
	{ label: "VWSDA.V", value: 2110943, partition: 0 },
	{ label: "TERA.E", value: 78371, partition: 0 },
	{ label: "IZBJL.V", value: 19281302, partition: 0 },
	{ label: "AXBDK.V", value: 7091616, partition: 0 },
	{ label: "TEKTU.E", value: 78376, partition: 0 },
	{ label: "TYIRG.V", value: 1192550, partition: 0 },
	{ label: "TKSFY.V", value: 1192553, partition: 0 },
	{ label: "TETMT.E", value: 78382, partition: 0 },
	{ label: "TK1PX.V", value: 1192555, partition: 0 },
	{ label: "YPIEP.V", value: 1848970, partition: 0 },
	{ label: "TEZOL.E", value: 78386, partition: 0 },
	{ label: "YBAHT.V", value: 1848972, partition: 0 },
	{ label: "YPSBM.V", value: 1848974, partition: 0 },
	{ label: "TLIQT.V", value: 1454555, partition: 0 },
	{ label: "TCSBI.V", value: 1454557, partition: 0 },
	{ label: "TOSRB.V", value: 3420916, partition: 0 },
	{ label: "TOAEK.V", value: 3420917, partition: 0 },
	{ label: "TSBRW.V", value: 6173241, partition: 0 },
	{ label: "TOBDA.V", value: 3420918, partition: 0 },
	{ label: "TO1PK.V", value: 3420919, partition: 0 },
	{ label: "IZBXY.V", value: 21771670, partition: 0 },
	{ label: "AXBSR.V", value: 9581984, partition: 0 },
	{ label: "SPIJM.V", value: 1716558, partition: 0 },
	{ label: "SPBRU.V", value: 1716559, partition: 0 },
	{ label: "TKAWG.V", value: 3682920, partition: 0 },
	{ label: "TKSSD.V", value: 3682921, partition: 0 },
	{ label: "TKBPB.V", value: 3682922, partition: 0 },
	{ label: "TK1AO.V", value: 3682923, partition: 0 },
	{ label: "TGSAS.E", value: 78416, partition: 0 },
	{ label: "YPBDG.V", value: 4339341, partition: 0 },
	{ label: "YK1PN.V", value: 4339343, partition: 0 },
	{ label: "TLAWF.V", value: 3944924, partition: 0 },
	{ label: "TLBDG.V", value: 3944926, partition: 0 },
	{ label: "TL1AQ.V", value: 3944927, partition: 0 },
	{ label: "TSBSF.V", value: 8663609, partition: 0 },
	{ label: "THYAO.E", value: 78436, partition: 0 },
	{ label: "UJSAY.V", value: 406643, partition: 0 },
	{ label: "IZBYR.V", value: 24262038, partition: 0 },
	{ label: "UDGAB.V", value: 406646, partition: 0 },
	{ label: "VERTU.E", value: 144662, partition: 0 },
	{ label: "TRICG.V", value: 668642, partition: 0 },
	{ label: "IYIWN.V", value: 2373011, partition: 0 },
	{ label: "IYACU.V", value: 2373012, partition: 0 },
	{ label: "IZBIE.V", value: 2373014, partition: 0 },
	{ label: "TRBCY.V", value: 668648, partition: 0 },
	{ label: "AXSDN.V", value: 2373023, partition: 0 },
	{ label: "IZBWZ.V", value: 14562710, partition: 0 },
	{ label: "UJBQC.V", value: 2897008, partition: 0 },
	{ label: "EJBQC.V", value: 2897009, partition: 0 },
	{ label: "ECBQC.V", value: 2897010, partition: 0 },
	{ label: "TYIRL.V", value: 2568806, partition: 0 },
	{ label: "SPBDE.V", value: 6697295, partition: 0 },
	{ label: "TKFEN.E", value: 78476, partition: 0 },
	{ label: "TPAHS.V", value: 3159012, partition: 0 },
	{ label: "IYADF.V", value: 4863380, partition: 0 },
	{ label: "TPSRT.V", value: 3159015, partition: 0 },
	{ label: "TWSPL.V", value: 930670, partition: 0 },
	{ label: "TP1AN.V", value: 3159017, partition: 0 },
	{ label: "TSBDE.V", value: 1454649, partition: 0 },
	{ label: "AXSQZ.V", value: 4863391, partition: 0 },
	{ label: "IZBXS.V", value: 17053078, partition: 0 },
	{ label: "AXBDF.V", value: 4863392, partition: 0 },
	{ label: "TKNSA.E", value: 78496, partition: 0 },
	{ label: "UJSPQ.V", value: 5387379, partition: 0 },
	{ label: "AXBEA.V", value: 17053088, partition: 0 },
	{ label: "TOIXA.V", value: 1192690, partition: 0 },
	{ label: "VWSQK.V", value: 2373087, partition: 0 },
	{ label: "TLMAN.E", value: 78526, partition: 0 },
	{ label: "IZBJN.V", value: 19543446, partition: 0 },
	{ label: "AXBDM.V", value: 7353760, partition: 0 },
	{ label: "TYIRI.V", value: 1454694, partition: 0 },
	{ label: "TKSGA.V", value: 1454697, partition: 0 },
	{ label: "TMPOL.E", value: 78536, partition: 0 },
	{ label: "YPIER.V", value: 2111114, partition: 0 },
	{ label: "YBAHV.V", value: 2111116, partition: 0 },
	{ label: "YPSBO.V", value: 2111118, partition: 0 },
	{ label: "TLIQV.V", value: 1716699, partition: 0 },
	{ label: "TLAGY.V", value: 1716700, partition: 0 },
	{ label: "TCSBK.V", value: 1716701, partition: 0 },
	{ label: "TSSRC.V", value: 6435384, partition: 0 },
	{ label: "TOSFG.V", value: 3683060, partition: 0 },
	{ label: "TSBRY.V", value: 6435385, partition: 0 },
	{ label: "TOATJ.V", value: 3683061, partition: 0 },
	{ label: "TOBDC.V", value: 3683062, partition: 0 },
	{ label: "TO1PM.V", value: 3683063, partition: 0 },
	{ label: "TMSN.E", value: 78556, partition: 0 },
	{ label: "TPYGI.F2", value: 78559, partition: 0 },
	{ label: "IZBYA.V", value: 22033814, partition: 0 },
	{ label: "TPYTI.F2", value: 78561, partition: 0 },
	{ label: "SPIVG.V", value: 1978702, partition: 0 },
	{ label: "SPBRW.V", value: 1978703, partition: 0 },
	{ label: "TKAWI.V", value: 3945064, partition: 0 },
	{ label: "TKSGL.V", value: 3945065, partition: 0 },
	{ label: "TKBDC.V", value: 3945066, partition: 0 },
	{ label: "TK1AQ.V", value: 3945067, partition: 0 },
	{ label: "TOASO.E", value: 78576, partition: 0 },
	{ label: "YPBDI.V", value: 4601485, partition: 0 },
	{ label: "YK1PP.V", value: 4601487, partition: 0 },
	{ label: "TLBDI.V", value: 4207070, partition: 0 },
	{ label: "TL1PN.V", value: 4207071, partition: 0 },
	{ label: "TSBSH.V", value: 8925753, partition: 0 },
	{ label: "TPKGY.F1", value: 78586, partition: 0 },
	{ label: "UJSBA.V", value: 668787, partition: 0 },
	{ label: "IZBYT.V", value: 24524182, partition: 0 },
	{ label: "UDGPB.V", value: 668790, partition: 0 },
	{ label: "TRCAS.E", value: 78596, partition: 0 },
	{ label: "TRIRD.V", value: 930786, partition: 0 },
	{ label: "IYIWP.V", value: 2635155, partition: 0 },
	{ label: "IYACW.V", value: 2635156, partition: 0 },
	{ label: "IZSGY.V", value: 2635157, partition: 0 },
	{ label: "IZBIG.V", value: 2635158, partition: 0 },
	{ label: "TPSFO.V", value: 930791, partition: 0 },
	{ label: "TRBDA.V", value: 930792, partition: 0 },
	{ label: "AXAUM.V", value: 2635166, partition: 0 },
	{ label: "IZBXB.V", value: 14824854, partition: 0 },
	{ label: "TRGYO.E", value: 78616, partition: 0 },
	{ label: "UJBQE.V", value: 3159152, partition: 0 },
	{ label: "EJBQE.V", value: 3159153, partition: 0 },
	{ label: "ECBQE.V", value: 3159154, partition: 0 },
	{ label: "TYIRN.V", value: 2830950, partition: 0 },
	{ label: "TRILC.E", value: 78626, partition: 0 },
	{ label: "SPBDG.V", value: 6959439, partition: 0 },
	{ label: "TPAHU.V", value: 3421156, partition: 0 },
	{ label: "IYADH.V", value: 5125524, partition: 0 },
	{ label: "TPSGA.V", value: 3421159, partition: 0 },
	{ label: "TSSEV.V", value: 1716792, partition: 0 },
	{ label: "TRBDE.V", value: 3421160, partition: 0 },
	{ label: "TWSAX.V", value: 1192814, partition: 0 },
	{ label: "TSBRS.V", value: 1716793, partition: 0 },
	{ label: "TP1PK.V", value: 3421161, partition: 0 },
	{ label: "AXSRB.V", value: 5125535, partition: 0 },
	{ label: "IZBXU.V", value: 17315222, partition: 0 },
	{ label: "AXBRV.V", value: 5125536, partition: 0 },
	{ label: "AXBEC.V", value: 17315232, partition: 0 },
	{ label: "TOIXC.V", value: 1454834, partition: 0 },
	{ label: "TSGYO.E", value: 78676, partition: 0 },
	{ label: "TO1AF.V", value: 1454839, partition: 0 },
	{ label: "IZBJP.V", value: 19805590, partition: 0 },
	{ label: "AXBDO.V", value: 7615904, partition: 0 },
	{ label: "TKAHB.V", value: 1716840, partition: 0 },
	{ label: "TKSGC.V", value: 1716841, partition: 0 },
	{ label: "YPITP.V", value: 2373258, partition: 0 },
	{ label: "TSKB.E", value: 78696, partition: 0 },
	{ label: "YBAWS.V", value: 2373260, partition: 0 },
	{ label: "YPSBQ.V", value: 2373262, partition: 0 },
	{ label: "YK1AI.V", value: 2373263, partition: 0 },
	{ label: "TLIQZ.V", value: 1978843, partition: 0 },
	{ label: "TLAHA.V", value: 1978844, partition: 0 },
	{ label: "TOSRE.V", value: 3945204, partition: 0 },
	{ label: "TSBSA.V", value: 6697529, partition: 0 },
	{ label: "TOATL.V", value: 3945205, partition: 0 },
	{ label: "TOBDE.V", value: 3945206, partition: 0 },
	{ label: "TO1AP.V", value: 3945207, partition: 0 },
	{ label: "IZBYC.V", value: 22295958, partition: 0 },
	{ label: "TSPOR.E", value: 78716, partition: 0 },
	{ label: "SPIVI.V", value: 2240846, partition: 0 },
	{ label: "TKSSJ.V", value: 4207209, partition: 0 },
	{ label: "TKBDE.V", value: 4207210, partition: 0 },
	{ label: "TK1PN.V", value: 4207211, partition: 0 },
	{ label: "IYIAT.V", value: 406931, partition: 0 },
	{ label: "YPBDK.V", value: 4863629, partition: 0 },
	{ label: "YK1AS.V", value: 4863631, partition: 0 },
	{ label: "TTKOM.E", value: 78736, partition: 0 },
	{ label: "TLBDK.V", value: 4469214, partition: 0 },
	{ label: "TL1PP.V", value: 4469215, partition: 0 },
	{ label: "TSBSJ.V", value: 9187897, partition: 0 },
	{ label: "UJSBC.V", value: 930931, partition: 0 },
	{ label: "IZBYV.V", value: 24786326, partition: 0 },
	{ label: "TKSSG.V", value: 6697577, partition: 0 },
	{ label: "TTRAK.E", value: 78756, partition: 0 },
	{ label: "TRIRF.V", value: 1192930, partition: 0 },
	{ label: "IYIWV.V", value: 2897299, partition: 0 },
	{ label: "IYACY.V", value: 2897300, partition: 0 },
	{ label: "IZSHA.V", value: 2897301, partition: 0 },
	{ label: "IZBII.V", value: 2897302, partition: 0 },
	{ label: "TPSFQ.V", value: 1192935, partition: 0 },
	{ label: "TRBDC.V", value: 1192936, partition: 0 },
	{ label: "TUCLK.E", value: 78766, partition: 0 },
	{ label: "AXAFP.V", value: 2897310, partition: 0 },
	{ label: "IZBXD.V", value: 15086998, partition: 0 },
	{ label: "TLBDO.V", value: 6959582, partition: 0 },
	{ label: "UJBBG.V", value: 3421296, partition: 0 },
	{ label: "EJBBG.V", value: 3421297, partition: 0 },
	{ label: "ECBBG.V", value: 3421298, partition: 0 },
	{ label: "SPBDI.V", value: 7221583, partition: 0 },
	{ label: "IYADJ.V", value: 5387668, partition: 0 },
	{ label: "TPAWM.V", value: 3683300, partition: 0 },
	{ label: "TPSRV.V", value: 3683303, partition: 0 },
	{ label: "TWSAZ.V", value: 1454958, partition: 0 },
	{ label: "TSSEX.V", value: 1978936, partition: 0 },
	{ label: "TRBDG.V", value: 3683304, partition: 0 },
	{ label: "TUKAS.E", value: 78796, partition: 0 },
	{ label: "TP1PM.V", value: 3683305, partition: 0 },
	{ label: "AXSRD.V", value: 5387679, partition: 0 },
	{ label: "IZBIY.V", value: 17577366, partition: 0 },
	{ label: "AXBRX.V", value: 5387680, partition: 0 },
	{ label: "AXBSS.V", value: 17577376, partition: 0 },
	{ label: "TUPRS.E", value: 78816, partition: 0 },
	{ label: "TURGG.E", value: 78826, partition: 0 },
	{ label: "TOIAR.V", value: 1716978, partition: 0 },
	{ label: "TOSEV.V", value: 1716980, partition: 0 },
	{ label: "TWSPM.V", value: 3945326, partition: 0 },
	{ label: "TSSQZ.V", value: 4469304, partition: 0 },
	{ label: "TOAEC.V", value: 1716981, partition: 0 },
	{ label: "TO1AH.V", value: 1716983, partition: 0 },
	{ label: "VWSQL.V", value: 2897375, partition: 0 },
	{ label: "TURSG.E", value: 78836, partition: 0 },
	{ label: "IZBJR.V", value: 20067734, partition: 0 },
	{ label: "AXBDQ.V", value: 7878048, partition: 0 },
	{ label: "TKAHD.V", value: 1978984, partition: 0 },
	{ label: "TKSGG.V", value: 1978985, partition: 0 },
	{ label: "UFUK.E", value: 78846, partition: 0 },
	{ label: "YPITR.V", value: 2635402, partition: 0 },
	{ label: "YBAWU.V", value: 2635404, partition: 0 },
	{ label: "YPSPR.V", value: 2635406, partition: 0 },
	{ label: "YK1AK.V", value: 2635407, partition: 0 },
	{ label: "ULAS.E", value: 78856, partition: 0 },
	{ label: "TLIWA.V", value: 2240987, partition: 0 },
	{ label: "TLAVX.V", value: 2240988, partition: 0 },
	{ label: "TOSRG.V", value: 4207348, partition: 0 },
	{ label: "TSBDG.V", value: 6959673, partition: 0 },
	{ label: "TOBDG.V", value: 4207350, partition: 0 },
	{ label: "TO1AR.V", value: 4207351, partition: 0 },
	{ label: "IZBYE.V", value: 22558102, partition: 0 },
	{ label: "ULKER.E", value: 78876, partition: 0 },
	{ label: "TKSSL.V", value: 4469353, partition: 0 },
	{ label: "ULUFA.E", value: 78879, partition: 0 },
	{ label: "TKBDG.V", value: 4469354, partition: 0 },
	{ label: "TK1PP.V", value: 4469355, partition: 0 },
	{ label: "IYIAV.V", value: 669075, partition: 0 },
	{ label: "ULUSE.E", value: 78881, partition: 0 },
	{ label: "ULUUN.E", value: 78884, partition: 0 },
	{ label: "YPBDM.V", value: 5125773, partition: 0 },
	{ label: "UMPAS.E", value: 78886, partition: 0 },
	{ label: "YK1AU.V", value: 5125775, partition: 0 },
	{ label: "IZBIS.V", value: 12858774, partition: 0 },
	{ label: "TLBDM.V", value: 4731358, partition: 0 },
	{ label: "TL1AR.V", value: 4731359, partition: 0 },
	{ label: "UJSBE.V", value: 1193075, partition: 0 },
	{ label: "IZBYX.V", value: 25048470, partition: 0 },
	{ label: "UNLU.E", value: 78906, partition: 0 },
	{ label: "TKSSI.V", value: 6959721, partition: 0 },
	{ label: "TRIRH.V", value: 1455074, partition: 0 },
	{ label: "TKBDK.V", value: 6959722, partition: 0 },
	{ label: "IYIWX.V", value: 3159443, partition: 0 },
	{ label: "IYADA.V", value: 3159444, partition: 0 },
	{ label: "IZSHC.V", value: 3159445, partition: 0 },
	{ label: "IZBIK.V", value: 3159446, partition: 0 },
	{ label: "USAK.E", value: 78916, partition: 0 },
	{ label: "TPSFS.V", value: 1455079, partition: 0 },
	{ label: "TP1AF.V", value: 1455081, partition: 0 },
	{ label: "AXAFR.V", value: 3159454, partition: 0 },
	{ label: "IZBXF.V", value: 15349142, partition: 0 },
	{ label: "TLBPD.V", value: 7221726, partition: 0 },
	{ label: "UJBBI.V", value: 3683440, partition: 0 },
	{ label: "EJBBI.V", value: 3683441, partition: 0 },
	{ label: "ECBBI.V", value: 3683442, partition: 0 },
	{ label: "SPBDK.V", value: 7483727, partition: 0 },
	{ label: "IYASD.V", value: 5649812, partition: 0 },
	{ label: "TPAWO.V", value: 3945444, partition: 0 },
	{ label: "TPSRX.V", value: 3945447, partition: 0 },
	{ label: "TWSPO.V", value: 1717102, partition: 0 },
	{ label: "TSSEZ.V", value: 2241080, partition: 0 },
	{ label: "TRBDI.V", value: 3945448, partition: 0 },
	{ label: "TP1AP.V", value: 3945449, partition: 0 },
	{ label: "IZBJA.V", value: 17839510, partition: 0 },
	{ label: "AXBRZ.V", value: 5649824, partition: 0 },
	{ label: "AXBSU.V", value: 17839520, partition: 0 },
	{ label: "YPIEJ.V", value: 407178, partition: 0 },
	{ label: "YPBCW.V", value: 407181, partition: 0 },
	{ label: "USDTR.F", value: 78976, partition: 0 },
	{ label: "TOIAT.V", value: 1979122, partition: 0 },
	{ label: "TSSRD.V", value: 4731448, partition: 0 },
	{ label: "TOSEX.V", value: 1979124, partition: 0 },
	{ label: "TWSPQ.V", value: 4207470, partition: 0 },
	{ label: "TOAEE.V", value: 1979125, partition: 0 },
	{ label: "TO1PE.V", value: 1979127, partition: 0 },
	{ label: "IZBJT.V", value: 20329878, partition: 0 },
	{ label: "AXBSG.V", value: 8140192, partition: 0 },
	{ label: "SPIJF.V", value: 274766, partition: 0 },
	{ label: "SPBCT.V", value: 274767, partition: 0 },
	{ label: "TKAWA.V", value: 2241128, partition: 0 },
	{ label: "TKSGI.V", value: 2241129, partition: 0 },
	{ label: "YBAHW.V", value: 2897548, partition: 0 },
	{ label: "YPSBS.V", value: 2897550, partition: 0 },
	{ label: "YK1AM.V", value: 2897551, partition: 0 },
	{ label: "TLIWC.V", value: 2503131, partition: 0 },
	{ label: "TLAVZ.V", value: 2503132, partition: 0 },
	{ label: "TSBDI.V", value: 7221817, partition: 0 },
	{ label: "TOBDI.V", value: 4469494, partition: 0 },
	{ label: "TO1PN.V", value: 4469495, partition: 0 },
	{ label: "IZBYG.V", value: 22820246, partition: 0 },
	{ label: "TKBDI.V", value: 4731498, partition: 0 },
	{ label: "TK1AR.V", value: 4731499, partition: 0 },
	{ label: "IYIAX.V", value: 931219, partition: 0 },
	{ label: "UZERB.E", value: 79036, partition: 0 },
	{ label: "YPBDO.V", value: 5387917, partition: 0 },
	{ label: "YK1AW.V", value: 5387919, partition: 0 },
	{ label: "IZBIU.V", value: 13120918, partition: 0 },
	{ label: "TCSPN.V", value: 4993501, partition: 0 },
	{ label: "TL1AT.V", value: 4993503, partition: 0 },
	{ label: "UJBAZ.V", value: 1455216, partition: 0 },
	{ label: "EJBAZ.V", value: 1455217, partition: 0 },
	{ label: "ECBAZ.V", value: 1455218, partition: 0 },
	{ label: "VAKBN.E", value: 79056, partition: 0 },
	{ label: "UJSBG.V", value: 1455219, partition: 0 },
	{ label: "IZBYZ.V", value: 25310614, partition: 0 },
	{ label: "TKSSO.V", value: 7221865, partition: 0 },
	{ label: "TKBPD.V", value: 7221866, partition: 0 },
	{ label: "TRICJ.V", value: 1717218, partition: 0 },
	{ label: "IYIWR.V", value: 3421587, partition: 0 },
	{ label: "TPAHM.V", value: 1717220, partition: 0 },
	{ label: "IYARU.V", value: 3421588, partition: 0 },
	{ label: "IZSGW.V", value: 3421589, partition: 0 },
	{ label: "IZBIM.V", value: 3421590, partition: 0 },
	{ label: "TPSFU.V", value: 1717223, partition: 0 },
	{ label: "TP1AH.V", value: 1717225, partition: 0 },
	{ label: "VAKFN.E", value: 79076, partition: 0 },
	{ label: "AXAFT.V", value: 3421598, partition: 0 },
	{ label: "AXSDH.V", value: 3421599, partition: 0 },
	{ label: "IZBXH.V", value: 15611286, partition: 0 },
	{ label: "AXBCU.V", value: 3421600, partition: 0 },
	{ label: "UJBQG.V", value: 3945584, partition: 0 },
	{ label: "EJBQG.V", value: 3945585, partition: 0 },
	{ label: "ECBQG.V", value: 3945586, partition: 0 },
	{ label: "SPBDM.V", value: 7745871, partition: 0 },
	{ label: "VAKKO.E", value: 79096, partition: 0 },
	{ label: "IYASF.V", value: 5911956, partition: 0 },
	{ label: "TSSFB.V", value: 2503224, partition: 0 },
	{ label: "TRBDK.V", value: 4207592, partition: 0 },
	{ label: "TP1AR.V", value: 4207593, partition: 0 },
	{ label: "IZBJC.V", value: 18101654, partition: 0 },
	{ label: "AXBSB.V", value: 5911968, partition: 0 },
	{ label: "VANGD.E", value: 79116, partition: 0 },
	{ label: "AXBSW.V", value: 18101664, partition: 0 },
	{ label: "YPIEL.V", value: 669322, partition: 0 },
	{ label: "YPBCY.V", value: 669325, partition: 0 },
	{ label: "VERUS.E", value: 79136, partition: 0 },
	{ label: "TCSAY.V", value: 274909, partition: 0 },
	{ label: "TOIWT.V", value: 2241266, partition: 0 },
	{ label: "TLBCV.V", value: 274910, partition: 0 },
	{ label: "TL1AW.V", value: 274911, partition: 0 },
	{ label: "TSSRF.V", value: 4993592, partition: 0 },
	{ label: "TOSFB.V", value: 2241268, partition: 0 },
	{ label: "TOATD.V", value: 2241269, partition: 0 },
	{ label: "TO1PG.V", value: 2241271, partition: 0 },
	{ label: "IZBJV.V", value: 20592022, partition: 0 },
	{ label: "AXBSI.V", value: 8402336, partition: 0 },
	{ label: "SPIJH.V", value: 536910, partition: 0 },
	{ label: "SPBCV.V", value: 536911, partition: 0 },
	{ label: "TKAWC.V", value: 2503272, partition: 0 },
	{ label: "VESBE.E", value: 79156, partition: 0 },
	{ label: "YBAHY.V", value: 3159692, partition: 0 },
	{ label: "YPSBU.V", value: 3159694, partition: 0 },
	{ label: "YK1PJ.V", value: 3159695, partition: 0 },
	{ label: "TLIWE.V", value: 2765275, partition: 0 },
	{ label: "TLAWB.V", value: 2765276, partition: 0 },
	{ label: "TSBDK.V", value: 7483961, partition: 0 },
	{ label: "TOBDK.V", value: 4731638, partition: 0 },
	{ label: "TO1PP.V", value: 4731639, partition: 0 },
	{ label: "VESTL.E", value: 79176, partition: 0 },
	{ label: "IZBYI.V", value: 23082390, partition: 0 },
	{ label: "TK1AT.V", value: 4993643, partition: 0 },
	{ label: "IYIAZ.V", value: 1193363, partition: 0 },
	{ label: "IZSGR.V", value: 1193365, partition: 0 },
	{ label: "IZBHV.V", value: 1193366, partition: 0 },
	{ label: "VKFYO.E", value: 79196, partition: 0 },
	{ label: "YPBQF.V", value: 5650061, partition: 0 },
	{ label: "YK1PS.V", value: 5650063, partition: 0 },
	{ label: "AXSDC.V", value: 1193375, partition: 0 },
	{ label: "IZBIW.V", value: 13383062, partition: 0 },
	{ label: "TCSPP.V", value: 5255645, partition: 0 },
	{ label: "TL1AV.V", value: 5255647, partition: 0 },
	{ label: "UJBBB.V", value: 1717360, partition: 0 },
	{ label: "EJBBB.V", value: 1717361, partition: 0 },
	{ label: "ECBBB.V", value: 1717362, partition: 0 },
	{ label: "UJSBI.V", value: 1717363, partition: 0 },
	{ label: "VKGYO.E", value: 79216, partition: 0 },
	{ label: "VRGYO.E", value: 79221, partition: 0 },
	{ label: "TRICL.V", value: 1979362, partition: 0 },
	{ label: "IYIWT.V", value: 3683731, partition: 0 },
	{ label: "TPAHO.V", value: 1979364, partition: 0 },
	{ label: "IYARW.V", value: 3683732, partition: 0 },
	{ label: "IZSSO.V", value: 3683733, partition: 0 },
	{ label: "IZBIO.V", value: 3683734, partition: 0 },
	{ label: "TPSFW.V", value: 1979367, partition: 0 },
	{ label: "TSBCV.V", value: 275001, partition: 0 },
	{ label: "TP1PE.V", value: 1979369, partition: 0 },
	{ label: "AXAUP.V", value: 3683742, partition: 0 },
	{ label: "AXSQS.V", value: 3683743, partition: 0 },
	{ label: "IZBXJ.V", value: 15873430, partition: 0 },
	{ label: "VKING.E", value: 79236, partition: 0 },
	{ label: "AXBCW.V", value: 3683744, partition: 0 },
	{ label: "UJBQI.V", value: 4207728, partition: 0 },
	{ label: "EJBQI.V", value: 4207729, partition: 0 },
	{ label: "ECBQI.V", value: 4207730, partition: 0 },
	{ label: "SPBSE.V", value: 8008015, partition: 0 },
	{ label: "IYASH.V", value: 6174100, partition: 0 },
	{ label: "TRBDM.V", value: 4469736, partition: 0 },
	{ label: "TSSFD.V", value: 2765368, partition: 0 },
	{ label: "TP1PN.V", value: 4469737, partition: 0 },
	{ label: "VWSCS.V", value: 1193439, partition: 0 },
	{ label: "IZBJE.V", value: 18363798, partition: 0 },
	{ label: "AXBSD.V", value: 6174112, partition: 0 },
	{ label: "TKBCR.V", value: 275050, partition: 0 },
	{ label: "AXBSY.V", value: 18363808, partition: 0 },
	{ label: "TK1AW.V", value: 275051, partition: 0 },
	{ label: "YPITJ.V", value: 931466, partition: 0 },
	{ label: "YPBDA.V", value: 931469, partition: 0 },
	{ label: "TLIOF.V", value: 537051, partition: 0 },
	{ label: "TCSBA.V", value: 537053, partition: 0 },
	{ label: "TOIWV.V", value: 2503410, partition: 0 },
	{ label: "TLBCX.V", value: 537054, partition: 0 },
	{ label: "TL1AY.V", value: 537055, partition: 0 },
	{ label: "TOSFD.V", value: 2503412, partition: 0 },
	{ label: "TOATF.V", value: 2503413, partition: 0 },
	{ label: "TO1PI.V", value: 2503415, partition: 0 },
	{ label: "VWSCW.V", value: 3683807, partition: 0 },
	{ label: "IZBJX.V", value: 20854166, partition: 0 },
	{ label: "AXBSK.V", value: 8664480, partition: 0 },
	{ label: "SPIVB.V", value: 799054, partition: 0 },
	{ label: "SPBCX.V", value: 799055, partition: 0 },
	{ label: "TKAWE.V", value: 2765416, partition: 0 },
	{ label: "TKBCV.V", value: 2765418, partition: 0 },
	{ label: "YBAIA.V", value: 3421836, partition: 0 },
	{ label: "YPBDE.V", value: 3421837, partition: 0 },
	{ label: "YPSBW.V", value: 3421838, partition: 0 },
	{ label: "YK1PL.V", value: 3421839, partition: 0 },
	{ label: "TLAHD.V", value: 3027420, partition: 0 },
	{ label: "TSBDM.V", value: 7746105, partition: 0 },
	{ label: "IZBYK.V", value: 23344534, partition: 0 },
	{ label: "YAPRK.E", value: 79336, partition: 0 },
	{ label: "TK1AV.V", value: 5255787, partition: 0 },
	{ label: "IYIOL.V", value: 1455507, partition: 0 },
	{ label: "IZSGT.V", value: 1455509, partition: 0 },
	{ label: "IZBHX.V", value: 1455510, partition: 0 },
	{ label: "YPBQH.V", value: 5912205, partition: 0 },
	{ label: "YK1PU.V", value: 5912207, partition: 0 },
	{ label: "YATAS.E", value: 79356, partition: 0 },
	{ label: "AXAFL.V", value: 1455518, partition: 0 },
	{ label: "AXSDE.V", value: 1455519, partition: 0 },
	{ label: "IZBWS.V", value: 13645206, partition: 0 },
	{ label: "TCSPR.V", value: 5517789, partition: 0 },
	{ label: "TL1PS.V", value: 5517791, partition: 0 },
	{ label: "UJBPZ.V", value: 1979504, partition: 0 },
	{ label: "EJBPZ.V", value: 1979505, partition: 0 },
	{ label: "ECBPZ.V", value: 1979506, partition: 0 },
	{ label: "UJSBK.V", value: 1979507, partition: 0 },
	{ label: "SPBRX.V", value: 5779791, partition: 0 },
	{ label: "YAYLA.E", value: 79376, partition: 0 },
	{ label: "TRIRI.V", value: 2241506, partition: 0 },
	{ label: "IYIWZ.V", value: 3945875, partition: 0 },
	{ label: "IYARY.V", value: 3945876, partition: 0 },
	{ label: "TPAWG.V", value: 2241508, partition: 0 },
	{ label: "IZSHE.V", value: 3945877, partition: 0 },
	{ label: "IZBIQ.V", value: 3945878, partition: 0 },
	{ label: "TPSFY.V", value: 2241511, partition: 0 },
	{ label: "TSBCX.V", value: 537145, partition: 0 },
	{ label: "TP1PG.V", value: 2241513, partition: 0 },
	{ label: "AXAUR.V", value: 3945886, partition: 0 },
	{ label: "AXSQU.V", value: 3945887, partition: 0 },
	{ label: "IZBXL.V", value: 16135574, partition: 0 },
	{ label: "AXBCY.V", value: 3945888, partition: 0 },
	{ label: "AXBDT.V", value: 16135584, partition: 0 },
	{ label: "SPBSG.V", value: 8270159, partition: 0 },
	{ label: "IYASJ.V", value: 6436244, partition: 0 },
	{ label: "IZSSR.V", value: 6436245, partition: 0 },
	{ label: "TOIAV.V", value: 275186, partition: 0 },
	{ label: "YBTAS.E", value: 79416, partition: 0 },
	{ label: "TRBDO.V", value: 4731880, partition: 0 },
	{ label: "TSSFF.V", value: 3027512, partition: 0 },
	{ label: "TP1PP.V", value: 4731881, partition: 0 },
	{ label: "TOBCR.V", value: 275190, partition: 0 },
	{ label: "VWSCU.V", value: 1455583, partition: 0 },
	{ label: "IZBJG.V", value: 18625942, partition: 0 },
	{ label: "AXBSF.V", value: 6436256, partition: 0 },
	{ label: "TYICF.V", value: 537190, partition: 0 },
	{ label: "TKBCT.V", value: 537194, partition: 0 },
	{ label: "AXBTA.V", value: 18625952, partition: 0 },
	{ label: "TK1AY.V", value: 537195, partition: 0 },
	{ label: "YESIL.E", value: 79436, partition: 0 },
	{ label: "YPITL.V", value: 1193610, partition: 0 },
	{ label: "YPBDC.V", value: 1193613, partition: 0 },
	{ label: "TLIOI.V", value: 799195, partition: 0 },
	{ label: "TCSBC.V", value: 799197, partition: 0 },
	{ label: "TOIWX.V", value: 2765554, partition: 0 },
	{ label: "TLBCZ.V", value: 799198, partition: 0 },
	{ label: "TL1BA.V", value: 799199, partition: 0 },
	{ label: "TOSFF.V", value: 2765556, partition: 0 },
	{ label: "TOATH.V", value: 2765557, partition: 0 },
	{ label: "TOBCV.V", value: 2765558, partition: 0 },
	{ label: "TO1AK.V", value: 2765559, partition: 0 },
	{ label: "VWSQI.V", value: 3945951, partition: 0 },
	{ label: "YGGYO.E", value: 79456, partition: 0 },
	{ label: "IZBJZ.V", value: 21116310, partition: 0 },
	{ label: "AXBSM.V", value: 8926624, partition: 0 },
	{ label: "SPIVD.V", value: 1061198, partition: 0 },
	{ label: "SPBCZ.V", value: 1061199, partition: 0 },
	{ label: "TKAHG.V", value: 3027560, partition: 0 },
	{ label: "TKBCX.V", value: 3027562, partition: 0 },
	{ label: "YBAWX.V", value: 3683980, partition: 0 },
	{ label: "YPBQA.V", value: 3683981, partition: 0 },
	{ label: "YPSPT.V", value: 3683982, partition: 0 },
	{ label: "YK1AN.V", value: 3683983, partition: 0 },
	{ label: "YGYO.E", value: 79476, partition: 0 },
	{ label: "TLAHF.V", value: 3289564, partition: 0 },
	{ label: "TLBDD.V", value: 3289566, partition: 0 },
	{ label: "TSBDO.V", value: 8008249, partition: 0 },
	{ label: "YIGIT.E", value: 79486, partition: 0 },
	{ label: "IZBYM.V", value: 23606678, partition: 0 },
	{ label: "YKBNK.E", value: 79496, partition: 0 },
	{ label: "TK1PS.V", value: 5517931, partition: 0 },
	{ label: "IYION.V", value: 1717651, partition: 0 },
	{ label: "IZSGV.V", value: 1717653, partition: 0 },
	{ label: "IZBHZ.V", value: 1717654, partition: 0 },
	{ label: "AXAFN.V", value: 1717662, partition: 0 },
	{ label: "AXSDG.V", value: 1717663, partition: 0 },
	{ label: "IZBWU.V", value: 13907350, partition: 0 },
	{ label: "TL1PU.V", value: 5779935, partition: 0 },
	{ label: "UJBQB.V", value: 2241648, partition: 0 },
	{ label: "EJBQB.V", value: 2241649, partition: 0 },
	{ label: "ECBQB.V", value: 2241650, partition: 0 },
	{ label: "TYICK.V", value: 1913446, partition: 0 },
	{ label: "YKSLN.E", value: 79526, partition: 0 },
	{ label: "SPBRZ.V", value: 6041935, partition: 0 },
	{ label: "TRIRK.V", value: 2503650, partition: 0 },
	{ label: "IYIXB.V", value: 4208019, partition: 0 },
	{ label: "IYASA.V", value: 4208020, partition: 0 },
	{ label: "TPAWI.V", value: 2503652, partition: 0 },
	{ label: "IZSSS.V", value: 4208021, partition: 0 },
	{ label: "YONGA.E", value: 79536, partition: 0 },
	{ label: "TWSAR.V", value: 275310, partition: 0 },
	{ label: "TSBCZ.V", value: 799289, partition: 0 },
	{ label: "TP1PI.V", value: 2503657, partition: 0 },
	{ label: "AXSQW.V", value: 4208031, partition: 0 },
	{ label: "IZBXN.V", value: 16397718, partition: 0 },
	{ label: "AXBDA.V", value: 4208032, partition: 0 },
	{ label: "UJSPL.V", value: 4732019, partition: 0 },
	{ label: "AXBDV.V", value: 16397728, partition: 0 },
	{ label: "YUNSA.E", value: 79556, partition: 0 },
	{ label: "SMART.E", value: 209111, partition: 0 },
	{ label: "SPBSI.V", value: 8532303, partition: 0 },
	{ label: "TOIAX.V", value: 537330, partition: 0 },
	{ label: "TSSFH.V", value: 3289656, partition: 0 },
	{ label: "TOBCT.V", value: 537334, partition: 0 },
	{ label: "YYAPI.E", value: 79576, partition: 0 },
	{ label: "VWSCX.V", value: 1717727, partition: 0 },
	{ label: "IZBJI.V", value: 18888086, partition: 0 },
	{ label: "AXBDH.V", value: 6698400, partition: 0 },
	{ label: "YYLGD.E", value: 79581, partition: 0 },
	{ label: "TYICH.V", value: 799334, partition: 0 },
	{ label: "ZEDUR.E", value: 79586, partition: 0 },
	{ label: "AXBTC.V", value: 18888096, partition: 0 },
	{ label: "TK1BA.V", value: 799339, partition: 0 },
	{ label: "YPITN.V", value: 1455754, partition: 0 },
	{ label: "ZOREN.E", value: 79596, partition: 0 },
	{ label: "TLIOK.V", value: 1061339, partition: 0 },
	{ label: "ZRGYO.E", value: 79601, partition: 0 },
	{ label: "TCSBF.V", value: 1061341, partition: 0 },
	{ label: "TLBDB.V", value: 1061342, partition: 0 },
	{ label: "TL1PW.V", value: 1061343, partition: 0 },
	{ label: "TOAEH.V", value: 3027701, partition: 0 },
	{ label: "TSBRT.V", value: 5780025, partition: 0 },
	{ label: "TOBCX.V", value: 3027702, partition: 0 },
	{ label: "ZPX30.F", value: 79606, partition: 0 },
	{ label: "TO1AM.V", value: 3027703, partition: 0 },
	{ label: "ZPT10.F", value: 79607, partition: 0 },
	{ label: "VWSQM.V", value: 4208095, partition: 0 },
	{ label: "ZELOT.F", value: 79611, partition: 0 },
	{ label: "IZBKB.V", value: 21378454, partition: 0 },
	{ label: "AXBSO.V", value: 9188768, partition: 0 },
	{ label: "SPIJJ.V", value: 1323342, partition: 0 },
	{ label: "SPBDB.V", value: 1323343, partition: 0 },
	{ label: "ZPLIB.F", value: 79616, partition: 0 },
	{ label: "TKAHI.V", value: 3289704, partition: 0 },
	{ label: "TKSGE.V", value: 3289705, partition: 0 },
	{ label: "TKBCZ.V", value: 3289706, partition: 0 },
	{ label: "ZPBDL.F", value: 79626, partition: 0 },
	{ label: "YBAWZ.V", value: 3946124, partition: 0 },
	{ label: "YPBQC.V", value: 3946125, partition: 0 },
	{ label: "YPSPV.V", value: 3946126, partition: 0 },
	{ label: "YK1AP.V", value: 3946127, partition: 0 },
	{ label: "TLAWC.V", value: 3551708, partition: 0 },
	{ label: "TLBPA.V", value: 3551710, partition: 0 },
	{ label: "ZGOLD.F", value: 79636, partition: 0 },
	{ label: "TL1AN.V", value: 3551711, partition: 0 },
	{ label: "TSBSC.V", value: 8270393, partition: 0 },
	{ label: "IZBYO.V", value: 23868822, partition: 0 },
	{ label: "Z30EA.F", value: 79646, partition: 0 },
	{ label: "Z30KE.F", value: 79648, partition: 0 },
	{ label: "Z30KP.F", value: 79650, partition: 0 },
	{ label: "TK1PU.V", value: 5780075, partition: 0 },
	{ label: "IYIAP.V", value: 1979795, partition: 0 },
	{ label: "ZRE20.F", value: 79656, partition: 0 },
	{ label: "IZBIB.V", value: 1979798, partition: 0 },
	{ label: "TRBCV.V", value: 275432, partition: 0 },
	{ label: "ZSR25.F", value: 79661, partition: 0 },
	{ label: "ZTM15.F", value: 79666, partition: 0 },
	{ label: "AXAUJ.V", value: 1979806, partition: 0 },
	{ label: "AXSDK.V", value: 1979807, partition: 0 },
	{ label: "IZBWW.V", value: 14169494, partition: 0 },
	{ label: "UJBBD.V", value: 2503792, partition: 0 },
	{ label: "EJBBD.V", value: 2503793, partition: 0 },
	{ label: "ECBBD.V", value: 2503794, partition: 0 },
	{ label: "TYICM.V", value: 2175590, partition: 0 },
	{ label: "SPBSB.V", value: 6304079, partition: 0 },
	{ label: "TRIRM.V", value: 2765794, partition: 0 },
	{ label: "TPAWK.V", value: 2765796, partition: 0 },
	{ label: "IYADC.V", value: 4470164, partition: 0 },
	{ label: "IZSSU.V", value: 4470165, partition: 0 },
	{ label: "TWSAT.V", value: 537454, partition: 0 },
	{ label: "TP1AK.V", value: 2765801, partition: 0 },
	{ label: "TSBDB.V", value: 1061433, partition: 0 },
	{ label: "AXSDP.V", value: 4470175, partition: 0 },
	{ label: "IZBXP.V", value: 16659862, partition: 0 },
	{ label: "AXBDC.V", value: 4470176, partition: 0 },
	{ label: "UJSPN.V", value: 4994163, partition: 0 },
	{ label: "AXBDX.V", value: 16659872, partition: 0 },
	{ label: "SPBSK.V", value: 8794447, partition: 0 },
	{ label: "TNZTP.E", value: 209278, partition: 0 },
	{ label: "TOIAZ.V", value: 799474, partition: 0 },
	{ label: "TSSFJ.V", value: 3551800, partition: 0 },
	{ label: "VWSCZ.V", value: 1979871, partition: 0 },
	{ label: "IZBJK.V", value: 19150230, partition: 0 },
	{ label: "AXBDJ.V", value: 6960544, partition: 0 },
	{ label: "TARKM.E", value: 209293, partition: 0 },
	{ label: "TYIRF.V", value: 1061478, partition: 0 },
	{ label: "TKSFX.V", value: 1061481, partition: 0 },
	{ label: "TK1PW.V", value: 1061483, partition: 0 },
	{ label: "YPIEO.V", value: 1717898, partition: 0 },
	{ label: "YBAHS.V", value: 1717900, partition: 0 },
	{ label: "YPSBL.V", value: 1717902, partition: 0 },
	{ label: "TLIOH.V", value: 1323483, partition: 0 },
	{ label: "TCSBH.V", value: 1323485, partition: 0 },
	{ label: "TOSFA.V", value: 3289844, partition: 0 },
	{ label: "TOAEJ.V", value: 3289845, partition: 0 },
	{ label: "TSBRV.V", value: 6042169, partition: 0 },
	{ label: "TOBCZ.V", value: 3289846, partition: 0 },
	{ label: "TO1PJ.V", value: 3289847, partition: 0 },
	{ label: "IZBXX.V", value: 21640598, partition: 0 },
	{ label: "AXBSQ.V", value: 9450912, partition: 0 },
	{ label: "SPIJL.V", value: 1585486, partition: 0 },
	{ label: "SPBRT.V", value: 1585487, partition: 0 },
	{ label: "TKAWF.V", value: 3551848, partition: 0 },
	{ label: "TKSGK.V", value: 3551849, partition: 0 },
	{ label: "TKBPA.V", value: 3551850, partition: 0 },
	{ label: "TK1AN.V", value: 3551851, partition: 0 },
	{ label: "YPBDF.V", value: 4208269, partition: 0 },
	{ label: "YK1AR.V", value: 4208271, partition: 0 },
	{ label: "TLAWE.V", value: 3813852, partition: 0 },
	{ label: "TLBDF.V", value: 3813854, partition: 0 },
	{ label: "TL1AP.V", value: 3813855, partition: 0 },
	{ label: "TOSRD.V", value: 5780212, partition: 0 },
	{ label: "TSBSE.V", value: 8532537, partition: 0 },
	{ label: "UJSAX.V", value: 275571, partition: 0 },
	{ label: "IZBYQ.V", value: 24130966, partition: 0 },
	{ label: "UDGAA.V", value: 275574, partition: 0 },
	{ label: "TRICF.V", value: 537570, partition: 0 },
	{ label: "IYIAR.V", value: 2241939, partition: 0 },
	{ label: "IZBID.V", value: 2241942, partition: 0 },
	{ label: "TRBCX.V", value: 537576, partition: 0 },
	{ label: "TLIOD.V", value: 209371, partition: 0 },
	{ label: "AXAUL.V", value: 2241950, partition: 0 },
	{ label: "AXSDM.V", value: 2241951, partition: 0 },
	{ label: "IZBWY.V", value: 14431638, partition: 0 },
	{ label: "UJBBF.V", value: 2765936, partition: 0 },
	{ label: "EJBBF.V", value: 2765937, partition: 0 },
	{ label: "ECBBF.V", value: 2765938, partition: 0 },
	{ label: "TYIRK.V", value: 2437734, partition: 0 },
	{ label: "SPBDD.V", value: 6566223, partition: 0 },
	{ label: "TDGYO.E", value: 209398, partition: 0 },
	{ label: "TPAHR.V", value: 3027940, partition: 0 },
	{ label: "IYADE.V", value: 4732308, partition: 0 },
	{ label: "TPSRS.V", value: 3027943, partition: 0 },
	{ label: "TWSAV.V", value: 799598, partition: 0 },
	{ label: "TP1AM.V", value: 3027945, partition: 0 },
	{ label: "TSBDD.V", value: 1323577, partition: 0 },
	{ label: "AXSQY.V", value: 4732319, partition: 0 },
	{ label: "IZBXR.V", value: 16922006, partition: 0 },
	{ label: "AXBDE.V", value: 4732320, partition: 0 },
	{ label: "UJSPP.V", value: 5256307, partition: 0 },
	{ label: "AXBDZ.V", value: 16922016, partition: 0 },
	{ label: "SPBSM.V", value: 9056591, partition: 0 },
	{ label: "TOIWZ.V", value: 1061618, partition: 0 },
	{ label: "VWSDB.V", value: 2242015, partition: 0 },
	{ label: "IZBJM.V", value: 19412374, partition: 0 },
	{ label: "AXBDL.V", value: 7222688, partition: 0 },
	{ label: "TYIRH.V", value: 1323622, partition: 0 },
	{ label: "TKSFZ.V", value: 1323625, partition: 0 },
	{ label: "YPIEQ.V", value: 1980042, partition: 0 },
	{ label: "YBAHU.V", value: 1980044, partition: 0 },
	{ label: "YPSBN.V", value: 1980046, partition: 0 },
	{ label: "TLIQU.V", value: 1585627, partition: 0 },
	{ label: "TLAGX.V", value: 1585628, partition: 0 },
	{ label: "TCSBJ.V", value: 1585629, partition: 0 },
	{ label: "TSSRB.V", value: 6304312, partition: 0 },
	{ label: "TOSRC.V", value: 3551988, partition: 0 },
	{ label: "TSBRX.V", value: 6304313, partition: 0 },
	{ label: "TOATI.V", value: 3551989, partition: 0 },
	{ label: "TOBDB.V", value: 3551990, partition: 0 },
	{ label: "TO1PL.V", value: 3551991, partition: 0 },
	{ label: "IZBXZ.V", value: 21902742, partition: 0 },
	{ label: "SPIVF.V", value: 1847630, partition: 0 },
	{ label: "SPBRV.V", value: 1847631, partition: 0 },
	{ label: "TKAWH.V", value: 3813992, partition: 0 },
	{ label: "TKSSE.V", value: 3813993, partition: 0 },
	{ label: "TKBDB.V", value: 3813994, partition: 0 },
	{ label: "TK1AP.V", value: 3813995, partition: 0 },
	{ label: "YPBDH.V", value: 4470413, partition: 0 },
	{ label: "YK1PO.V", value: 4470415, partition: 0 },
	{ label: "TLAWG.V", value: 4075996, partition: 0 },
	{ label: "TLBDH.V", value: 4075998, partition: 0 },
	{ label: "TL1PM.V", value: 4075999, partition: 0 },
	{ label: "TSBSG.V", value: 8794681, partition: 0 },
	{ label: "UJSAZ.V", value: 537715, partition: 0 },
	{ label: "IZBYS.V", value: 24393110, partition: 0 },
	{ label: "TYICD.V", value: 209510, partition: 0 },
	{ label: "UDGPA.V", value: 537718, partition: 0 },
	{ label: "TRICH.V", value: 799714, partition: 0 },
	{ label: "IYIWO.V", value: 2504083, partition: 0 },
	{ label: "IYACV.V", value: 2504084, partition: 0 },
	{ label: "IZBIF.V", value: 2504086, partition: 0 },
	{ label: "TRBCZ.V", value: 799720, partition: 0 },
	{ label: "AXSDO.V", value: 2504095, partition: 0 },
	{ label: "IZBXA.V", value: 14693782, partition: 0 },
	{ label: "UJBQD.V", value: 3028080, partition: 0 },
	{ label: "EJBQD.V", value: 3028081, partition: 0 },
	{ label: "ECBQD.V", value: 3028082, partition: 0 },
	{ label: "TYIRM.V", value: 2699878, partition: 0 },
	{ label: "SPBDF.V", value: 6828367, partition: 0 },
	{ label: "TPAHT.V", value: 3290084, partition: 0 },
	{ label: "IYADG.V", value: 4994452, partition: 0 },
	{ label: "TPSFZ.V", value: 3290087, partition: 0 },
	{ label: "TRBDD.V", value: 3290088, partition: 0 },
	{ label: "TWSAW.V", value: 1061742, partition: 0 },
	{ label: "TSSEU.V", value: 1585720, partition: 0 },
	{ label: "TP1PJ.V", value: 3290089, partition: 0 },
	{ label: "TSBRR.V", value: 1585721, partition: 0 },
	{ label: "AXSRA.V", value: 4994463, partition: 0 },
	{ label: "IZBXT.V", value: 17184150, partition: 0 },
	{ label: "AXBRU.V", value: 4994464, partition: 0 },
	{ label: "AXBEB.V", value: 17184160, partition: 0 },
	{ label: "TOIXB.V", value: 1323762, partition: 0 },
	{ label: "TPSRU.V", value: 5780455, partition: 0 },
	{ label: "TO1AE.V", value: 1323767, partition: 0 },
	{ label: "IZBJO.V", value: 19674518, partition: 0 },
	{ label: "AXBDN.V", value: 7484832, partition: 0 },
	{ label: "TYICI.V", value: 1585766, partition: 0 },
	{ label: "TKAHA.V", value: 1585768, partition: 0 },
	{ label: "TKSGB.V", value: 1585769, partition: 0 },
	{ label: "YPITO.V", value: 2242186, partition: 0 },
	{ label: "YBAWR.V", value: 2242188, partition: 0 },
	{ label: "YPSBP.V", value: 2242190, partition: 0 },
	{ label: "TLIQY.V", value: 1847771, partition: 0 },
	{ label: "TLAGZ.V", value: 1847772, partition: 0 },
	{ label: "TCSBL.V", value: 1847773, partition: 0 },
	{ label: "TOSFH.V", value: 3814132, partition: 0 },
	{ label: "TSBRZ.V", value: 6566457, partition: 0 },
	{ label: "TOATK.V", value: 3814133, partition: 0 },
	{ label: "TOBDD.V", value: 3814134, partition: 0 },
	{ label: "TO1AO.V", value: 3814135, partition: 0 },
	{ label: "IZBYB.V", value: 22164886, partition: 0 },
	{ label: "SPIVH.V", value: 2109774, partition: 0 },
	{ label: "TKAWJ.V", value: 4076136, partition: 0 },
	{ label: "TKSGM.V", value: 4076137, partition: 0 },
	{ label: "TKBDD.V", value: 4076138, partition: 0 },
	{ label: "TK1PM.V", value: 4076139, partition: 0 },
	{ label: "IYIAS.V", value: 275859, partition: 0 },
	{ label: "YPBDJ.V", value: 4732557, partition: 0 },
	{ label: "YK1PQ.V", value: 4732559, partition: 0 },
	{ label: "TLBDJ.V", value: 4338142, partition: 0 },
	{ label: "TL1PO.V", value: 4338143, partition: 0 },
	{ label: "TSBSI.V", value: 9056825, partition: 0 },
	{ label: "UJSBB.V", value: 799859, partition: 0 },
	{ label: "IZBYU.V", value: 24655254, partition: 0 },
	{ label: "TKSSF.V", value: 6566505, partition: 0 },
	{ label: "TRIRE.V", value: 1061858, partition: 0 },
	{ label: "IYIWQ.V", value: 2766227, partition: 0 },
	{ label: "IYACX.V", value: 2766228, partition: 0 },
	{ label: "IZSGZ.V", value: 2766229, partition: 0 },
	{ label: "IZBIH.V", value: 2766230, partition: 0 },
	{ label: "TPSFP.V", value: 1061863, partition: 0 },
	{ label: "TRBDB.V", value: 1061864, partition: 0 },
	{ label: "AXAUN.V", value: 2766238, partition: 0 },
	{ label: "IZBXC.V", value: 14955926, partition: 0 },
	{ label: "TLBDN.V", value: 6828510, partition: 0 },
	{ label: "UJBQF.V", value: 3290224, partition: 0 },
	{ label: "EJBQF.V", value: 3290225, partition: 0 },
	{ label: "ECBQF.V", value: 3290226, partition: 0 },
	{ label: "SPBDH.V", value: 7090511, partition: 0 },
	{ label: "TPAWL.V", value: 3552228, partition: 0 },
	{ label: "IYADI.V", value: 5256596, partition: 0 },
	{ label: "TPSGB.V", value: 3552231, partition: 0 },
	{ label: "TWSAY.V", value: 1323886, partition: 0 },
	{ label: "TSSEW.V", value: 1847864, partition: 0 },
	{ label: "TRBDF.V", value: 3552232, partition: 0 },
	{ label: "TP1PL.V", value: 3552233, partition: 0 },
	{ label: "AXSRC.V", value: 5256607, partition: 0 },
	{ label: "IZBXV.V", value: 17446294, partition: 0 },
	{ label: "AXBRW.V", value: 5256608, partition: 0 },
	{ label: "AXBED.V", value: 17446304, partition: 0 },
	{ label: "TOIAQ.V", value: 1585906, partition: 0 },
	{ label: "TOSEU.V", value: 1585908, partition: 0 },
	{ label: "TOAEB.V", value: 1585909, partition: 0 },
	{ label: "TO1AG.V", value: 1585911, partition: 0 },
	{ label: "IZBJQ.V", value: 19936662, partition: 0 },
	{ label: "AXBDP.V", value: 7746976, partition: 0 },
	{ label: "TKAHC.V", value: 1847912, partition: 0 },
	{ label: "TKSGF.V", value: 1847913, partition: 0 },
	{ label: "YPITQ.V", value: 2504330, partition: 0 },
	{ label: "YBAWT.V", value: 2504332, partition: 0 },
	{ label: "YPSPQ.V", value: 2504334, partition: 0 },
	{ label: "YK1AJ.V", value: 2504335, partition: 0 },
	{ label: "TLIOM.V", value: 2109915, partition: 0 },
	{ label: "TLAHB.V", value: 2109916, partition: 0 },
	{ label: "TOSRF.V", value: 4076276, partition: 0 },
	{ label: "TSBDF.V", value: 6828601, partition: 0 },
	{ label: "TOATM.V", value: 4076277, partition: 0 },
	{ label: "TOBDF.V", value: 4076278, partition: 0 },
	{ label: "TO1AQ.V", value: 4076279, partition: 0 },
	{ label: "IZBYD.V", value: 22427030, partition: 0 },
	{ label: "TKSSK.V", value: 4338281, partition: 0 },
	{ label: "TKBDF.V", value: 4338282, partition: 0 },
	{ label: "TK1PO.V", value: 4338283, partition: 0 },
	{ label: "IYIAU.V", value: 538003, partition: 0 },
	{ label: "YPBDL.V", value: 4994701, partition: 0 },
	{ label: "YK1AT.V", value: 4994703, partition: 0 },
	{ label: "TLBDL.V", value: 4600286, partition: 0 },
	{ label: "TL1PQ.V", value: 4600287, partition: 0 },
	{ label: "TSBSK.V", value: 9318969, partition: 0 },
	{ label: "UJSBD.V", value: 1062003, partition: 0 },
	{ label: "IZBYW.V", value: 24917398, partition: 0 },
	{ label: "TKSSH.V", value: 6828649, partition: 0 },
	{ label: "TRIRG.V", value: 1324002, partition: 0 },
	{ label: "TKBDJ.V", value: 6828650, partition: 0 },
	{ label: "IYIWW.V", value: 3028371, partition: 0 },
	{ label: "IYACZ.V", value: 3028372, partition: 0 },
	{ label: "IZSHB.V", value: 3028373, partition: 0 },
	{ label: "IZBIJ.V", value: 3028374, partition: 0 },
	{ label: "TPSFR.V", value: 1324007, partition: 0 },
	{ label: "TP1AE.V", value: 1324009, partition: 0 },
	{ label: "AXAFQ.V", value: 3028382, partition: 0 },
	{ label: "IZBXE.V", value: 15218070, partition: 0 },
	{ label: "TLBPC.V", value: 7090654, partition: 0 },
	{ label: "UJBBH.V", value: 3552368, partition: 0 },
	{ label: "EJBBH.V", value: 3552369, partition: 0 },
	{ label: "ECBBH.V", value: 3552370, partition: 0 },
	{ label: "SPBDJ.V", value: 7352655, partition: 0 },
	{ label: "IYASC.V", value: 5518740, partition: 0 },
	{ label: "TPAWN.V", value: 3814372, partition: 0 },
	{ label: "TPSRW.V", value: 3814375, partition: 0 },
	{ label: "TWSBA.V", value: 1586030, partition: 0 },
	{ label: "TSSEY.V", value: 2110008, partition: 0 },
	{ label: "TRBDH.V", value: 3814376, partition: 0 },
	{ label: "TP1AO.V", value: 3814377, partition: 0 },
	{ label: "IZBIZ.V", value: 17708438, partition: 0 },
	{ label: "AXBRY.V", value: 5518752, partition: 0 },
	{ label: "AXBST.V", value: 17708448, partition: 0 },
	{ label: "TRICD.V", value: 209890, partition: 0 },
	{ label: "YPBCV.V", value: 276109, partition: 0 },
	{ label: "TUREX.E", value: 209893, partition: 0 },
	{ label: "TOIAS.V", value: 1848050, partition: 0 },
	{ label: "TOSEW.V", value: 1848052, partition: 0 },
	{ label: "TWSPN.V", value: 4076398, partition: 0 },
	{ label: "TSSRA.V", value: 4600376, partition: 0 },
	{ label: "TOAED.V", value: 1848053, partition: 0 },
	{ label: "TO1AI.V", value: 1848055, partition: 0 },
	{ label: "IZBJS.V", value: 20198806, partition: 0 },
	{ label: "AXBDR.V", value: 8009120, partition: 0 },
	{ label: "TKAHE.V", value: 2110056, partition: 0 },
	{ label: "TKSGH.V", value: 2110057, partition: 0 },
	{ label: "YPITS.V", value: 2766474, partition: 0 },
	{ label: "YBAWV.V", value: 2766476, partition: 0 },
	{ label: "YPSBR.V", value: 2766478, partition: 0 },
	{ label: "YK1AL.V", value: 2766479, partition: 0 },
	{ label: "TLIWB.V", value: 2372059, partition: 0 },
	{ label: "TLAVY.V", value: 2372060, partition: 0 },
	{ label: "TSBDH.V", value: 7090745, partition: 0 },
	{ label: "TOBDH.V", value: 4338422, partition: 0 },
	{ label: "TO1AS.V", value: 4338423, partition: 0 },
	{ label: "IZBYF.V", value: 22689174, partition: 0 },
	{ label: "TKSSM.V", value: 4600425, partition: 0 },
	{ label: "TKBDH.V", value: 4600426, partition: 0 },
	{ label: "IYIAW.V", value: 800147, partition: 0 },
	{ label: "TK1PQ.V", value: 4600427, partition: 0 },
	{ label: "YPBDN.V", value: 5256845, partition: 0 },
	{ label: "YK1AV.V", value: 5256847, partition: 0 },
	{ label: "IZBIT.V", value: 12989846, partition: 0 },
	{ label: "TCSBE.V", value: 4862429, partition: 0 },
	{ label: "TL1AS.V", value: 4862431, partition: 0 },
	{ label: "UJBAY.V", value: 1324144, partition: 0 },
	{ label: "EJBAY.V", value: 1324145, partition: 0 },
	{ label: "ECBAY.V", value: 1324146, partition: 0 },
	{ label: "UJSBF.V", value: 1324147, partition: 0 },
	{ label: "IZBYY.V", value: 25179542, partition: 0 },
	{ label: "TKSSN.V", value: 7090793, partition: 0 },
	{ label: "TRICI.V", value: 1586146, partition: 0 },
	{ label: "TKBPC.V", value: 7090794, partition: 0 },
	{ label: "IYIWY.V", value: 3290515, partition: 0 },
	{ label: "TPAHL.V", value: 1586148, partition: 0 },
	{ label: "IYADB.V", value: 3290516, partition: 0 },
	{ label: "IZSHD.V", value: 3290517, partition: 0 },
	{ label: "IZBIL.V", value: 3290518, partition: 0 },
	{ label: "TPSFT.V", value: 1586151, partition: 0 },
	{ label: "TP1AG.V", value: 1586153, partition: 0 },
	{ label: "AXAFS.V", value: 3290526, partition: 0 },
	{ label: "IZBXG.V", value: 15480214, partition: 0 },
	{ label: "UJBBJ.V", value: 3814512, partition: 0 },
	{ label: "EJBBJ.V", value: 3814513, partition: 0 },
	{ label: "ECBBJ.V", value: 3814514, partition: 0 },
	{ label: "SPBDL.V", value: 7614799, partition: 0 },
	{ label: "IYASE.V", value: 5780884, partition: 0 },
	{ label: "TPAWP.V", value: 4076516, partition: 0 },
	{ label: "TWSPP.V", value: 1848174, partition: 0 },
	{ label: "TSSFA.V", value: 2372152, partition: 0 },
	{ label: "TRBDJ.V", value: 4076520, partition: 0 },
	{ label: "TP1AQ.V", value: 4076521, partition: 0 },
	{ label: "IZBJB.V", value: 17970582, partition: 0 },
	{ label: "AXBSA.V", value: 5780896, partition: 0 },
	{ label: "AXBSV.V", value: 17970592, partition: 0 },
	{ label: "YPIEK.V", value: 538250, partition: 0 },
	{ label: "YPBCX.V", value: 538253, partition: 0 },
	{ label: "TOIAU.V", value: 2110194, partition: 0 },
	{ label: "TSSRE.V", value: 4862520, partition: 0 },
	{ label: "TOSEY.V", value: 2110196, partition: 0 },
	{ label: "TOAEF.V", value: 2110197, partition: 0 },
	{ label: "TO1PF.V", value: 2110199, partition: 0 },
	{ label: "IZBJU.V", value: 20460950, partition: 0 },
	{ label: "AXBSH.V", value: 8271264, partition: 0 },
	{ label: "SPIJG.V", value: 405838, partition: 0 },
	{ label: "SPBCU.V", value: 405839, partition: 0 },
	{ label: "TKAWB.V", value: 2372200, partition: 0 },
	{ label: "YBAHX.V", value: 3028620, partition: 0 },
	{ label: "YPSBT.V", value: 3028622, partition: 0 },
	{ label: "YK1PI.V", value: 3028623, partition: 0 },
	{ label: "TLIWD.V", value: 2634203, partition: 0 },
	{ label: "TLAWA.V", value: 2634204, partition: 0 },
	{ label: "TSBDJ.V", value: 7352889, partition: 0 },
	{ label: "TOBDJ.V", value: 4600566, partition: 0 },
	{ label: "TO1PO.V", value: 4600567, partition: 0 },
	{ label: "IZBYH.V", value: 22951318, partition: 0 },
	{ label: "TK1AS.V", value: 4862571, partition: 0 },
	{ label: "IYIAY.V", value: 1062291, partition: 0 },
	{ label: "IZSGQ.V", value: 1062293, partition: 0 },
	{ label: "IZBHU.V", value: 1062294, partition: 0 },
	{ label: "YPBQE.V", value: 5518989, partition: 0 },
	{ label: "YK1PR.V", value: 5518991, partition: 0 },
	{ label: "AXSDB.V", value: 1062303, partition: 0 },
	{ label: "IZBIV.V", value: 13251990, partition: 0 },
	{ label: "TCSPO.V", value: 5124573, partition: 0 },
	{ label: "TL1AU.V", value: 5124575, partition: 0 },
	{ label: "UJBBA.V", value: 1586288, partition: 0 },
	{ label: "EJBBA.V", value: 1586289, partition: 0 },
	{ label: "ECBBA.V", value: 1586290, partition: 0 },
	{ label: "UJSBH.V", value: 1586291, partition: 0 },
	{ label: "TRICK.V", value: 1848290, partition: 0 },
	{ label: "IYIWS.V", value: 3552659, partition: 0 },
	{ label: "TPAHN.V", value: 1848292, partition: 0 },
	{ label: "IYARV.V", value: 3552660, partition: 0 },
	{ label: "IZSGX.V", value: 3552661, partition: 0 },
	{ label: "IZBIN.V", value: 3552662, partition: 0 },
	{ label: "TPSFV.V", value: 1848295, partition: 0 },
	{ label: "TP1AI.V", value: 1848297, partition: 0 },
	{ label: "AXAUO.V", value: 3552670, partition: 0 },
	{ label: "AXSDI.V", value: 3552671, partition: 0 },
	{ label: "IZBXI.V", value: 15742358, partition: 0 },
	{ label: "AXBCV.V", value: 3552672, partition: 0 },
	{ label: "UJBQH.V", value: 4076656, partition: 0 },
	{ label: "EJBQH.V", value: 4076657, partition: 0 },
	{ label: "ECBQH.V", value: 4076658, partition: 0 },
	{ label: "SPBSD.V", value: 7876943, partition: 0 },
	{ label: "DCTTR.E", value: 203943, partition: 0 },
	{ label: "TCKRC.E", value: 78321, partition: 0 },
	{ label: "GUNDG.E", value: 205823, partition: 0 },
	{ label: "OZATD.E", value: 77091, partition: 0 },
	{ label: "CEMZY.E", value: 72611, partition: 0 },
	{ label: "DURKN.E", value: 204373, partition: 0 },
	{ label: "BINBN.E", value: 203173, partition: 0 },
	{ label: "CGCAM.E", value: 203698, partition: 0 },
	{ label: "EGEGY.E", value: 73501, partition: 0 },
	{ label: "GLRMK.E", value: 74471, partition: 0 },
	{ label: "AKFIS.E", value: 70881, partition: 0 },
	{ label: "MOPAS.E", value: 76711, partition: 0 },
	{ label: "SERNT.E", value: 77911, partition: 0 },
	{ label: "VSNMD.E", value: 79239, partition: 0 },
	{ label: "ENDAE.E", value: 73731, partition: 0 },
	{ label: "KLYPV.E", value: 75806, partition: 0 },
	{ label: "BALSU.E", value: 71966, partition: 0 },
	{ label: "BULGS.E", value: 72466, partition: 0 },
];