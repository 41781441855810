export const FIX_ORDERS_UPDATE = "FIX_ORDERS_UPDATE";

const initialState = [];

export const updateFixOrders = (data) => ({
	type: FIX_ORDERS_UPDATE,
	payload: data,
});

export default function fixOrdersReducer(state = initialState, action) {
	switch (action.type) {
		case FIX_ORDERS_UPDATE:
			return action.payload ?? [];
		default:
			return state;
	}
}