import React, { useState } from "react";
import { useDispatch } from "react-redux";
import TimerIcon from "@material-ui/icons/Timer";
import CancelPresentation from "@material-ui/icons/CancelPresentation";
import SunIcon from "@material-ui/icons/WbSunny";
import ExecutorForm from "../../components/Executor/Form";
import Rahsans from "../../components/Rahsans";
import MorningOrderForm from "../../components/MorningOrderForm";
import FixOrderForm from "../../components/FixOrderForm";
import { updatePlan } from "../../modules/executor";
import Button from "./Button";
import Modal, { ModalHeader } from "../Modal";
import ManualOrderForm from "../ManualOrderForm";
import { getAccountSuggestion } from "../SentOrders/AutoExitStatus";
import { submitManualOrder } from "../../modules/manualOrderForm";
import MorningCancel from "../MorningCancel";
import { activeProfileId } from "../../modules/profiles";

const Menu = () => {
	const [showManualOrderForm, setShowManualOrderForm] = useState(false);
	const [showMorningCancel, setShowMorningCancel] = useState(false);
	const [showExecutorForm, setShowExecutorForm] = useState(false);
	const [showMorningOrderForm, setShowMorningOrderForm] = useState(false);
	const [showFixOrderForm, setShowFixOrderForm] = useState(false);
	const [showRahsans, setShowRahsans] = useState(false);
	const dispatch = useDispatch();

	const handleManualOrderFormClose = () => setShowManualOrderForm(false);
	const handleMorningCancelClose = () => setShowMorningCancel(false);

	const handleExecutorSubmit = (values) => {
		dispatch(
			updatePlan(
				values.order_book_id,
				values.side,
				values.price,
				values.total_duration * 60 * 1000,
				values.total_lot,
				values.steps,
				values.different_digits
			)
		);
		setShowExecutorForm(false);
	};

	return (
		<>
			<Button onClick={() => setShowMorningCancel(true)}>
				<CancelPresentation fontSize="inherit" />
			</Button>

			<Button
				onClick={() => setShowMorningOrderForm(!showMorningOrderForm)}
			>
				<SunIcon fontSize="inherit" />
			</Button>

			{activeProfileId === "yigit" && (
				<Button
					onClick={() => setShowFixOrderForm(!showFixOrderForm)}
				>
					FIX
				</Button>
			)}

			<Button onClick={() => setShowExecutorForm(!showExecutorForm)}>
				<TimerIcon fontSize="inherit" />
			</Button>

			<Button onClick={() => setShowRahsans(true)}>
				<span className="text-lg font-bold tracking-widest mt-1 ml-1 block">
					R
				</span>
			</Button>

			<Button
				onClick={() => {
					// Open popup for /order
					window.open(
						`/order?user=${activeProfileId}&side=Buy`,
						`order-${+new Date()}`,
						"width=250,height=445"
					);
				}}
				color="green"
			>
				<span className="text-lg font-bold tracking-widest mt-1 ml-1 block">
					A
				</span>
			</Button>

			<Button
				onClick={() => {
					// Open popup for /order
					window.open(
						`/order?user=${activeProfileId}&side=Sell`,
						`order-${+new Date()}`,
						"width=250,height=445"
					);
				}}
				color="red"
			>
				<span className="text-lg font-bold tracking-widest mt-1 ml-1 block">
					S
				</span>
			</Button>

			<Modal
				show={!!showManualOrderForm}
				onClose={handleManualOrderFormClose}
			>
				<ModalHeader
					onClose={handleManualOrderFormClose}
					title="Yeni Emir"
				/>
				<ManualOrderForm
					onSubmit={(values) => {
						submitManualOrder(values);
						setShowManualOrderForm(null);
					}}
					initialValues={{
						time_in_force: "0",
						side: showManualOrderForm,
						account: getAccountSuggestion(70616),
					}}
				/>
			</Modal>

			{showMorningOrderForm && (
				<MorningOrderForm
					onClose={() => setShowMorningOrderForm(false)}
				/>
			)}

			{showFixOrderForm && (
				<FixOrderForm
					onClose={() => setShowFixOrderForm(false)}
				/>
			)}

			{showExecutorForm && (
				<ExecutorForm
					onSubmit={handleExecutorSubmit}
					onClose={() => setShowExecutorForm(false)}
				/>
			)}

			{showRahsans && <Rahsans onClose={() => setShowRahsans(false)} />}

			<Modal
				show={!!showMorningCancel}
				onClose={handleMorningCancelClose}
			>
				<ModalHeader
					onClose={handleMorningCancelClose}
					title="Emir Durumu"
				/>
				{showMorningCancel && (
					<MorningCancel onClose={handleMorningCancelClose} />
				)}
			</Modal>

			<div className="absolute left-0 right-0 bottom-4 text-white text-center">
				v3.0.1
			</div>
		</>
	);
};

export default Menu;